@import "./base";

.body.naminTravel-theme{
  background-color: #f9f9f9;

  .search-route-item .date-block{
    flex: 0 0 149px;
  }
  .home-tabs-holder .home-tab {
    min-width: 130px;
  }
  .home-search-section{
    @media screen and (min-width: $medium ){
      background-position: center 70%;
    }
  }
.sort-next-prev-block {
  @media screen and (min-width: $medium ){
    margin-top: -55px;
  }
}
.namin-banner-subslogan{
  font-size: 18px;
  margin-bottom: 25px;
}
  .namin-banner-slogan {
    line-height: 20px;
    font-size: 34px;
    margin-top: 42px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .namin-banner-slogan,.namin-banner-subslogan {
    color: #fff;
    text-shadow: 0 0 5px #00000061;
  }

  .mobile-tab-hotel-flight{
    @media (max-width:$medium_){
      display: none;
    }
    .home-tab{
      background-color: rgba(0, 0, 0, 0.6);
      color: $naminTravelYellow;
      font-weight: 600;
      &.home-active-tab{
        color: $naminTravelYellow;
        color: $naminTravelDarkBlue;
        background: #f9f9f9;
        @media (min-width:$medium){
          background: $naminTravelYellow;
          color: #01357e;
        }
      }
    }
  }
  .account-links{
    .link-style-btn{
      @media (max-width:$small_){
        display: block;
      }
    }
  }
  .ticket-style-section:first-child:after, .ticket-style-section:first-child:before{
    background-color: #f9f9f9;
  }
  .newletter-section {
    .has-error .ant-form-explain, .has-error .ant-form-split {
      color: red;
    }
  }
  .newletter-section {
    background: $naminTravelYellow;
    .newsleter-inner{
      color: #06336b;
      @media screen and (min-width: $medium ) {
        .normal-title{
          font-size: 22px;
          font-weight: bold;
        }
        p{
          font-weight: 600;
          font-size: 18px;
        }
      }
    }
  }  
  .itours-modal .modal-overlay,.overlay,.full-container,.accountWindow,.loading-full-page {
    background: #101328cc;
    backdrop-filter: blur(1px);
  }
  // .loading-logo {
  //   width: 200px;
  // }
  main.main-content {
    min-height: calc(100vh - 347px);
    background: #fff;
  }
  @media (min-width:$medium){
    .home-search-section.has-dark-layer::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(90deg, #0000006b, transparent);
    }  
  }
  .naminTravel-search-block {
    .search-form-wrapper, .mobile-tab-hotel-flight {
      position: relative;
      z-index: 1;
      @media (max-width:$medium_){
        margin-bottom: 15px;
      }
    }
    // .search-form-wrapper{      
    //   @media screen and (max-height: 920px) {
    //     padding: 35px 0 20px;
    //   }
    // }

    // .search-form-wrapper {
    //   background-color: transparent;
    //   padding: 0;
    //   .search-form-inner-wrapper{
    //     @media (min-width:$medium ){
    //       background-color: #01357e;
    //       padding: 15px 15px 0;
    //       @include border-radius(4px);
    //     }
    //   }
    // }
  }
  .slider-text-inner-wrapper {
    @media (max-width:$small_){
      display: none;
    }
    @media (max-width:$medium_){
      .slider-subtitle,.slider-title-text{
        color: #555;
        text-shadow: none;
      }
    }
    padding: 100px 0 30px;
    width: 650px;
    text-align: center;
    margin: 0 auto;
    .slider-subtitle,.slider-title-text{
      color: #fff;
      text-shadow: 1px 1px 5px #000000a8;
      transition: 0;
    }
    max-width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    .slider-title {
      font-size: 45px;
      font-weight: bold;
      .slider-title-text{
        position: absolute;
        bottom: 0;
        transition: all .2s linear .2s;
        left: 0;
        right: 0;
        &:not(.active) {
          visibility: hidden;
          opacity: 0;
          bottom: 5px;
          transition: all .1s linear;
        }
      }      
    }
    .slider-subtitle {
      font-size: 25px;
      font-weight: 600;
      width: 100%;
      .slider-subtitle-text{
        position: absolute;
        top: 50%;
        transition: all 0.2s linear 0.4s;
        left: 30px;
        right: 30px;
        transform: translate(0, -50%);
        line-height: 1;
        &:not(.active) {
          visibility: hidden;
          opacity: 0;
          top: 40%;
          transition: all .1s linear;
        }
      }      
    }
  }
  .adv-item {
    margin: 0 10px;
    height: 200px;
    background-size: cover;
    background-position: center;
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    .adv-title {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(0deg, #000000db, transparent);
      font-weight: bold;
      color: #fff;
      font-size: 24px;
      text-align: center;
      padding: 25px 15px;
      margin: 0;
      text-transform: uppercase;
    }
  }
  .namin-adv-section {
    background: #fff;
    padding: 35px 0;
    @media (max-width:$small_){
      display: none;
    }
  }
  .destination-section-title {
    color: #033479;
    text-align: center;
    font-weight: 600;
    font-size: 25px;
    @media screen and (min-width: $medium ) {
      font-weight: bold;
      font-size: 30px;
    }
  }
  .namintravel-amazing-offer-title{
    color: #717171;
    font-weight: 600;
    font-size: 40px;
    text-align: center;
    letter-spacing: 1px;
    margin-bottom: 30px;
    @media screen and (min-width: $large) {
      margin-bottom: 60px;
    }
  }
  .newsletter-form{
    @media screen and (max-width: $medium_) {
      margin-top: 25px;
      max-width: 100%;
    }
  }
  .namin-destinations-section {
    padding: 60px 0;
    @media screen and (max-width: $medium_) {
      padding: 25px 0;
      background-color: #fff;
    }
  }
  .namin-destinations {
    flex-wrap: wrap;
    @media (max-width:$medium_) {
      padding: 0;
      justify-content: center;
      .destination-block{
        flex-basis: 50% !important;
        flex-shrink: 0 !important;
        flex-grow: 0 !important;
        margin-bottom: 25px;
      }
    }
    display: flex;
    padding: 0 25px; 
    margin: 25px 0 0;
    @media (min-width : $medium){
      padding: 0 50px; 
      margin: 50px 0;
    }
    .destination-block {
      flex: 1;
      position: relative;
      &::after{
        content: "";
        display: block;
        position: absolute;
        right: 100%;
        top: 0;
        bottom: 0;
        background-color: #c6c6c6;
        width: 1px;
      }
      .block-title {
        padding: 0 15px;
        font-weight: bold;
        font-size: 22px;
        color: #717171;
      }
      .dest-country-title{
        font-weight: 600;
        font-size: 18px;
        color: #111;
        margin-bottom: 0;
      }
    }
    .dest-item-link {
      display: block;
      color: #888;
      line-height: 20px;
    }
    .dest-country-item {
      margin-bottom: 15px;
      padding: 0 15px ;
    }
  }
  .quick-access-block{
    border: 0 none;
  }
  .home-search-section {
    .slider-next-btn,.slider-prev-btn{
      padding: 0 ;
      flex-shrink:0;
    }
    @media (min-width:$large) {
      min-height: calc(100vh - 430px);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  @media screen and (max-height: 920px) {
    .Home.flight .home-search-section .search-form-wrapper{
      padding-bottom: 15px;
    }
    .Home.hotel .home-search-section .search-form-wrapper{
      padding: 35px 0 25px;
    }
    .Home.package .home-search-section .search-form-wrapper{
      padding: 35px 0 10px;
    }
  }


  .slider-next-btn,.slider-prev-btn,.namin-adv-section .slick-arrow {
    line-height: 30px;
      height: 30px;
      width: 30px;
    background-color: #fff;
    cursor: pointer;
    &:hover{
      background-color: #f1f1f1;
    }
    min-width: 0;
    border-radius: 50%;
    border: 0 none !important;
    padding: 5px;
    box-shadow: 0 0 7px 1px #0000004d;
    outline: none !important;
    img{
      width: 15px;
    }
    &.slick-disabled{
      opacity: 0;
      visibility: hidden;
    }
  }
  .slider-subtitle-and-arrows {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .namin-adv-section .slick-slider {
    margin-left: -10px;
    margin-right: -10px;
    position: relative;
    .slick-arrow{
      position: absolute;
      top: 50%;
      width: 25px;
      height: 25px;
      z-index: 1;
      margin-top: -13px;
      &.slick-prev{
        left: -3px;
      }
      &.slick-next{
        right: -3px;
      }
    }
  
  }

  .namin-login-currency-holder .itours-select{
    border: 0 none;
    outline: none !important;
    cursor: pointer;
    background: none;
    color: #fff;
    font-weight: 400;
    option{
      color: $naminTravelDarkBlue;
    }
  }

  @media (max-width:$small_){
    .logo +.menu-toggle-btn {
      position: absolute;
      left: 10px;
    }
    .namin-login-currency-holder {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: end;
      padding-right: 5px;
      justify-content: space-between;
      height: 100px;
      padding-bottom: 5px;
      padding-top: 5px;    
      & > div{
        line-height: 20px;
        padding: 0;
        margin: 0;
        text-align: right;
        .itours-select{
          padding: 0;
          font-size: inherit;
          height: auto;          
        }
      }
    

      // &:not(.account-links){
      //   top: 0;
      // }
      // &.account-links{
      //   bottom: 5px;
      //   right: 15px;
      //   a.link-style-btn{
      //     display: block;
      //     margin-bottom: -6px;
      //   }
      // }
    }
  }

  .main-menu .menu-item{
    text-align: left;
    .itours-icon{
      display: none;
    }
    a{
      color: #fff;
      font-weight: 400;
      @media (max-width:$medium_){
        color: #777;
      }
    }
  }
  .namintravel-header{
    background-color: $naminTravelDarkBlue;
    .profile-and-logout {
      display: flex;
      flex-direction: column;
      @media (min-width:$medium){
        flex-direction: row;
      }
    }
    .namintravel-header-sides {
       padding: 15px 0;
       .namintravel-header-1 > .menu-toggle-btn {
         position: absolute;
         left: 0;
         top: 50%;
         z-index: 1;
         margin-top: -8px;
         .menu-btn-bar{
           background-color: #fff;
         }
       }
       .logo{
         width: 150px;
         margin-bottom: 20px;
         @media (min-width:$small){
          width: 170px;
         }
       }
       .itours-select.currency-select {
         background: transparent;
         color: #fff;
         border: 1px solid #fff;
         -webkit-appearance: none;
         appearance: none;
         outline: none;
         height: 28px;
         font-size: 13px;
         padding: 0 10px;
         margin-right: 10px;
         text-align: center;
         line-height: 1;
         vertical-align: middle;
         display: inline-block;
         option{
           background-color: #fff;
           color: #222;
           padding: 0 10px;
         }
         @media (max-width:$medium_){
           //padding: 0;
           margin: 0;
           width: auto;
         }
       }
       .link,.link-style-btn{
         color: #fff;
         font-size: 14px;
         white-space: nowrap;
        //  @media (min-width:$medium){
        //    margin-left: 0;
        //    margin-right: 15px;
        //  }
       }
       @media (max-width:$medium_){
         text-align: center;
         .currency-acount {
           position: absolute;
           right: 5px;
           top: 10px;
           bottom: 0;
           z-index: 1;
           text-align: right;
           display: flex;
           flex-direction: column;
           align-items: end;
           .link-style-btn,.link{
             display: block;
             margin: 0;
             text-align: right;
           }
         }
       }
       @media (min-width:$medium){
        .languages-wrapper .languages-popup .flag{
          width: 33px;
        }
        .languages-popup{
          left: -55px;
          right: -55px;
        }
         padding: 20px 0;
         display: flex;
         justify-content: space-between;
         align-items: center;
         .logo{
           margin-bottom: 0;
         }
         .namintravel-header-1 {
           display: flex;
         }
         .namintravel-header-2 {
           display: flex;
           align-items: center;
           line-height: 1;
         }
         .currency-acount {
           display: flex;
           align-items: center;
         }
       }
       @media (min-width:$large){
        .logo{
          width: 300px;
        }
       }  
     }
  }


  .button,
  .button[type="submit"] {
    @include border-radius(2px);
    @include transition(all 0.2s);
    &.small-btn{
      line-height: 15px;
      padding: 5px 10px;
      font-size: 13px;
    }
    &.namin-yellow-btn{
      background: $naminTravelYellow;
      &:hover {
        @media (hover: hover) {
          background: #ffcc4d;
        }
      }
      &:focus{
        background: #ffcc4d;
      }
    }
    &.namin-blue-btn,
    &.color-1-btn,
    &.blue-btn{
      background: #00357d;
      color: $naminTravelYellow;
      &.text-white{
        color: #fff;
      }
      &:hover {
        @media (hover: hover) {
          background: #064fb4;
        }
      }
      &:focus{
        background: #064fb4;
      }
    }
  }

  // .home-search-section {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   margin-top: 0;
  //   @media screen and (min-width: $medium){
  //     padding-bottom: 150px;
  //     min-height: 450px;
  //   }
  // }
  // .Home.flight .naminTravel-search-block {
  //   @media screen and (min-width: $medium){
  //     margin-top: -150px;
  //   }
  // }
  // .Home.hotel .naminTravel-search-block {
  //   @media screen and (min-width: $medium){
  //     margin-top: -80px;
  //   }
  // }
  // .Home.package .naminTravel-search-block {
  //   @media screen and (min-width: $medium){
  //     margin-top: -107px;
  //   }
  // }
  .triptype-radio-label{
    padding-top: 2px;
    padding-bottom: 2px;
    font-weight: 600;
  }
  .namintravel-amazing-offer-section{
    padding: 60px 0 35px;
    @media screen and (max-width: $medium_) {
      display: none;
    }
    @media screen and (max-height: 1000px) {
      padding-top: 35px;
    }
    @media screen and (max-height: 900px) {
      padding-top: 25px;
    }
  }

  .Home {
    background: #f9f9f9;
  } 
  .remove-return-btn{
    background-color: #f03f4f;
    border-radius: 0 3px 0 0;
  }
  .itinerary-label{
    font-weight: bold;
  }
  .yellow{
    color: $naminTravelYellow;
    &.yellow.link-style-btn{
      color: $naminTravelYellow;
      &:hover,&:focus{
        color: $naminTravelYellow;
      }
    }
  }
  .multy-dest-route .itinerary-label .line{
    height: 1px;
    &.yellow{
      background-color: $naminTravelYellow;;
    }
  }
  .add-destination-holder .link-style-btn{
    color: $naminTravelYellow;
    font-weight: 600;
    &:hover,&:focus{
      color: #ffcc4d;
    }
    .itours-icon{
      background-color: $naminTravelYellow;
      border-color: $naminTravelYellow;
    }
  }
  .okr-bg{
    background-color: #fbe6ad;
  }
  .side-top-text {
    text-align: justify;
    padding: 5px 15px;
    color: $naminTravelDarkBlue;
    font-weight: 600;
    font-size: 16px;
    border-radius: 5px;
  }
  .searchShow {
    border-bottom: 0 none;
  }
  .flight.search-result-page .searchShow {
    @media (min-width:$small){
      margin: 50px 0;
    }
  }
  .filter-header{
    background-color: $naminTravelDarkBlue;
    color: $naminTravelYellow;
    padding: 10px;
  }
  .package.search-result-page .filters-holder .filter-header{
    background-color: $naminTravelDarkBlue;
    color: $naminTravelYellow;
    padding: 5px 10px;
  }
  .filters-holder .okr-bg {
    padding: 2px 7px;
    display: inline-block;
    border-radius: 4px;
    color: $naminTravelDarkBlue;
  }
  .stop-message-in-allSegments,
  .stop-message .stop-info {
    background: #f1dce1;
    text-align: left;
    color: #222;
  }
  .button.results-sort-btn{
    color: $naminTravelDarkBlue;
  }
  .included-baggage-mark{
    background-color: $naminTravelDarkBlue;
    color: $naminTravelYellow;
    border-color: $naminTravelDarkBlue;
  }
  .flight.search-result-page .flight-result-item{
    transition: 0;
    &:hover {
      outline: 1px solid #903d50;
      border-color: #903d50;
    }
  }
  @media (min-width:$small){
    .flight.search-result-page .results-sorting-block {
      margin-top: -52px;
    }
    .flight-avails-row{
      padding-top: 0;
    }
  }
  .ticket-style-section{
    border: 2px solid #964b61;
    &:first-child{
        border-bottom: 0 none;
        &::before{
        border: 2px solid #964b61;             
        }
        &::after{
        border: 2px solid #964b61;
        }
    }
    &:not(:first-child){
      border-top:1px dashed #964b61;
    }
  }
  .hint-inner {
    background-color: #f1dce1;
    color: #ae5364;
    font-weight: 400;
  }
  .summary-flight-type-row .ant-tag{
    background-color: #fbe6ad;
    color: $naminTravelDarkBlue;
    font-weight: 600;
    font-size: inherit;
    border: 0 none; 
    padding: 2px 7px;
    display: inline-block;
    border-radius: 4px;
  }
  .main-content .ant-collapse-header .passenger-type-title{
    color: $naminTravelDarkBlue;
  }
  .radio-btn-label.type-2 {
    .radio-btn-label-text::after{
      border-color: $naminTravelDarkBlue;
    }
    input[type=radio]:checked + .radio-btn-label-text::after {
      background-color: $naminTravelDarkBlue;
    }
  }
  .wheelchair-block-title{
    background-color: $naminTravelDarkBlue;
    color: $naminTravelYellow;
  }
  .wheelchair-block label.checkbox-label{
    color: $naminTravelDarkBlue;
    &::before{
      border-color: $naminTravelDarkBlue;
    }
  }
  .ant-steps-item-process .ant-steps-item-icon{
    background-color: $naminTravelYellow;
    border-color: $naminTravelYellow;
    .ant-steps-icon{
      color: $naminTravelDarkBlue;
    }
  }
  .ant-steps-item-finish .ant-steps-item-icon{
    background-position: 74.2% 20%;
  }
  .hotelResult .button.results-sort-btn:not(.disable){
    background-color: #eaeef0;
    border-color: $naminTravelDarkBlue;
    @media screen and (min-width: $medium) {
      background-color: #e8cad2;
      border-color: #e8cad2;
    }
  }
  .link-style-btn{
    color: $naminTravelDarkBlue;
    &:hover{
      color: #116ae7;
    }
  }
  .map-btn-holder {
    margin-bottom: 15px;
  }
  .blue-border-right {
    @media screen and (min-width: $small) {
      border-right: 2px solid $naminTravelDarkBlue;
    }
  }  
  .hotel-details {
    .normal-title,.title {
      color: #000;
    }    
  }
  .hotel-summary-card {
    border: 2px solid #964b61;
    .card-title{
      background-color: $naminTravelDarkBlue;
      color: #fff;
    }
  }
  .ant-tag-red {
    @media screen and (min-width: $medium) {
      color: #af1d40;
      background: #e5cbd0;
      border-color: #e5cbd0;
    }
    font-size: 14px;
  }
  .ant-tag-green {
    color: #4caf50;
    background: #e2efd3;
    border-color: #e1f1cf;
    font-size: 14px;
  }
  .radio-item {
    .radio-label .radio-mark{
      border-color: $naminTravelDarkBlue;
    }
    .custom-radio:checked + .radio-label .radio-mark:before{
      background-color: $naminTravelDarkBlue;
    }
  }
  .top-color-border.orange {
    border-top-color: #964b61;
    .colored {
      color: #964b61;
      font-weight: 600;
    }
  }
  .has-fake-magnifirer-icon:before{
    background-color: $naminTravelDarkBlue;
  }
  .Pagination .page-btn{
    &:not(.disabled):not(.clicked){
      color:  #bbb;
      &:hover{
        background-color: $naminTravelDarkBlue;
        color: #fff;
      }
    }
    &.next-btn:not(.disabled),
    &.prev-btn:not(.disabled){
      color: $naminTravelDarkBlue;
    }
  }
  .package.search-result-page .flightDetails .itours-tab-holder .itours-tab-list .itours-tab-btn{
    background-color: $naminTravelYellow;
    color: $naminTravelDarkBlue;
    &:hover{
      background-color: #ffcc4d;
    }
    &.active,&.active:hover{
      background-color: #fff;
    }
  }
  .package-summary-card .ant-btn {
    background-color: $naminTravelDarkBlue;
    border-color: $naminTravelDarkBlue;
    color: $naminTravelYellow;
  }
  .tracking {
    background: $naminTravelYellow;
    text-align: left;
    @media screen and (max-width: $medium) {
      left: 50%;
      transform: translateX(-50%);
      &::before{
        right: 50%;
      }
    }
    &::before{
      background: $naminTravelYellow;
    }
  }
  .blue, .color-1{
    color: $naminTravelDarkBlue;
  }
  .blue-border{
    border-color: $naminTravelDarkBlue;
  }
  .bg-yellow{
    background-color: $naminTravelYellow;
  }
  .accountWindow .Form .login-tabs-holder .tab-btn{
    background-color: $naminTravelYellow;
    color: $naminTravelDarkBlue;
    &.active{
      background-color: #fff;
    }
  }
  .profileContainer .tab-holder {
    .tab-s{
      flex: 0 0;
      white-space: nowrap;
      border: 0 none;
      padding: 0 15px;
      border: 1px solid #ddd;
      border-bottom: 0 none;
      &.active-tab-s{
        background: #feba01;
        color: #00347d;
      }
      @media screen and (min-width: $medium) {
        background: #feba01;
        border-radius: 10px 10px 0 0;
        color: #00347d;
        box-shadow: 2px -2px 3px 0 #00000012;
        margin-bottom: -1px;
        margin-right: 5px;
        &.active-tab-s{
          background: #fff;
        }
      }
    }
    &+.card-padding{
      border: 1px solid #ddd;
      border-radius: 0 0 7px 7px;
      @media screen and (min-width: $medium){
        box-shadow: 0 0 7px 0 #00000024;
        border-radius: 0 7px 7px 7px;
      }
    }
  }
  .profileContainer .normal-title .itours-icon {
    display: none;
  }
  @media screen and (min-width: $small) {
    .change-password-form .form-group {
      display: flex;
      .form-label{
        white-space: nowrap;
        flex: 0 0 170px;
        font-weight: 600;
        font-size: inherit;
        &+div{
          flex: auto;
        }
      }
    }
  }
  .profileContainer .itours-table {
    th {
      background: #00347d;
      color: #fff;
      font-weight: 600;
    }
    td,th{
      border: 1px solid #999;
      text-align: center;
    }
  }
  .content-link{
    color: $naminTravelDarkBlue;
    &:hover{
      color: #116ae7;
    }
  }
  .error-box{
    max-width: 400px;
    top: 50%;
    left: 50%;
    position: absolute;
    width: calc(100% - 20px);
    @include transform(translate(-50%, -50%));
    .box-header {
      background: #f03f4f;
      color: #fff;
      font-weight: bold;
      font-size: 20px;
      padding: 10px 15px;
      border-radius: 10px 10px 0 0;
    }
    .box-main {
      padding: 35px 15px;
    }
  }
  .reverse .passengerInformation.margin-bottom .table-row-group .table-row.table-head {
    background: $naminTravelYellow;
    &>div{
      font-weight: 600;
      white-space: nowrap;
      color: $naminTravelDarkBlue;
    }
  }
  .room-guests .form-group .form-select.no-border-select {
    color: #666;
    background-color: #f1f1f1;
  }  
  .search-form-wrapper .room-guests {
    padding: 0px 10px;
    background-color: #f1f1f1;
    border: 0 none;
    border-radius: 5px;
    &:not(:first-child){
      margin-top: 23px;
    }
    .remove-room-btn{
      border-radius: 50%;
      height: 21px;
      width: 21px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -10px;
      right: 3px;
    }
    .children-ages-block {
      min-height: 25px;
    }
  }
  .passengers-options-holder{
    .plus-blue-icon{
      background-color: $naminTravelYellow;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      border: 3px solid;      
    }
  }
  .loading-full-page{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(135deg, #13064e, #46108a);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;  
    padding-top: 50px;  
    
    background: url('../image/namintravel-slider-images/loading.jpg');
    background-position: center;
    background-size: cover;

    .description{
      color: #fff;
      background: #ff5001;
      display: block;
      padding: 1rem;
      border-radius: 15px;
      font-size: 16px;
    }
    .loading-3 div{
      background-color: #555;
    }
    .loading-section.text-center {
      display: flex;
      flex-direction: column-reverse;
      gap: 35px;
      align-items: center;
      background-color: transparent;
      position: relative;
      font-size: 15px;
      padding-left: 10px;
      padding-right: 10px;
      @media screen and (min-width: $medium) {
        font-size: 18px;
        max-width: 600px;
      }
      min-width:0;
    }
  }
  .bg-pink {
    background: #b25067;
  } 
  .airline-filter-with-list {
    @media screen and (min-width: $small) {
      display: flex;
      align-items: center;
    }
    @media screen and (max-width: $small_) {
      & > .airline-filter{
        margin: 15px 0 0;
      }
      .styled-list{
        text-align: center;
      }
    }
    .styled-list{
      flex: 0 0 270px;
      &+.airline-filter {
        flex: auto;
        background: #fff;
        padding: 5px 10px;
        border: 1px solid #ddd;
        overflow: auto;
        .airline-table-holder{
          border: 0 none;
          overflow: unset;
        }
      }
    }

  } 
  .airline-filter-holder .cell {
    background: #ededed;
    + .cell {
      background-color: #dcdcdc;
    }
  }
  .bg-gray2{
    background-color: #dcdcdc;
    .styled-list li::before{
      border-color: #b25067;
    }
  }

  
  
  @media screen and (min-width: $small){
    .hotel-footer .namin-travel-hotel-item-footer{      
      display: flex;
      flex-direction: column;
      align-items: end;
      .prices-holder {
        order: 1;
      }
      .price-for-night{
        margin-bottom: 0;
        order: 2;
      }
      .hotel-detail-btn-holder{
        order: 3;
      }
      .button{
        order: 4;
      }
    }
  }
  .hotel-details-modal .modal-footer .red-btn {
    background: $naminTravelDarkBlue;
  }
  .getpassenger-page,.reserve-page,.package.search-result-page,.contact-us,.reserve-details-page,.terms-page {
    background: #f9f9f9;
  }    
  @media screen and (max-width: $small_) {
    .profile-tab-and-contents-wrapper .itours-table td{
      border: 0 none;
      &:not(:last-child){
        border-bottom: 1px dotted #ccc;
      }
    }    
    .accountWindow {
      background: #f9f9f9;
      .closeBtn {
        filter: brightness(0.5);
      }
    }
    .contact-us .page-title{
      text-align: center;
      border: 0 none;
    }
    #hotel_details > .box-border{
      border: 0 none;
    }
    .flight-loading-sec{
      border: 0 none;
      border-bottom: 2px dashed #999;

    }
    .na-res-pad{
      margin-left: 10px;
      margin-right: 10px;
      padding-left: 0;
      padding-right: 0;
    }    
    .package.search-result-page .searchShow{
      display: flex;
      .originShow, .typeShow, .destinationShow{
        display: block;
        margin-left: 0;
        margin-right: 0;
      }
      .passShow, .retShow, .depShow {
        display: block;
        margin: 10px 0;
      }
      .passShow{
        text-align: right;
      }
      .retShow{
        text-align: center;
      }
      .button {
        height: 37px;
        line-height: 37px;
        padding: 5 15px;
        background-color: $naminTravelDarkBlue;
        color: #fff;
      }
    }

    .bg-white.responsive-bg-transparent{
      background-color: transparent;
    }
    
    .select-room-wrapper{
      padding: 0 15px;
      &>.card{
        margin-bottom: 35px;
      }
    }
    .select-room-title.normal-title {
      text-align: center;
      font-weight: 600;
      border-bottom: 2px dashed #999;
      margin: 0 10px 10px;
      padding-bottom: 15px;
    }
    .hotel-detail-searchholder .bg-dark-blue{
      background-color: $naminTravelDarkBlue;
    }
    .hotel-detail-searchholder .search-form-header{
      text-align: center;
      .button{
        margin: 25px auto 10px;
        background-color: $naminTravelDarkBlue;
      }
    }
    .hotel-head-info .hotel-title {
      text-align: center;
    }
    .namin-travel-hotel-item-footer {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      & > *{
        flex: 0 0 50%;
        &.show-on-map-btn{
          flex: 0 0 100%;
        }
      }
      .hotel-detail-btn-holder{
        text-align: left;
        .link-style-btn{
          color: #fff;
          background-color: $naminTravelDarkBlue;
          padding: 3px 10px;
        }
      }
      .prices-holder {
        text-align: left;
      }
      .price-for-night {
        margin-bottom: 10px;
      }
    }    
    .hotel-result-item{
      border-top: 0 none;
      margin-bottom: 35px;
      .hotel-image{
        border: 1px solid #bbb;
      }
    }
    .detail-section{
      border-bottom: 2px dashed #999;
    }
    .flight-avails-row{
      padding-top: 0;
    }
    .price-and-book {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      button.button.select-flight-btn{
        padding: 5px 15px;
        min-width: 180px;
      }
      .price {
        color: #000;
        font-size: 17px;
      }
    } 
    .flight-footer-top-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .flight-type-and-seats {
        flex: 0 0 180px;
        display: flex;
        justify-content: space-between;
      }
    }     
  }
  @media screen and (max-width: $medium_) {
    .profile-tab-and-contents-wrapper{
      box-shadow: 1px 1px 8px 0 #0000003d;
      border-radius: 4px;
    }
    .hotel-details .flight-result-item{
      border: 0 none;
      border-bottom: 2px dashed #999;
    }
    .package.search-result-page .filters-holder .filter-header + div{
      padding-top: 10px;
    }
    .namin-package-modify {
      flex: 0 0 100%;
      text-align: center;
      padding-top: 0;
      margin-bottom: 15px;
    }    
    .selected-package.box-border {
      border: 0 none;
      border-top: 2px dashed #999;
      margin-bottom: 0;
    }    
    .filters-holder{
      padding:70px 0 0;
      .filter-header {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        line-height: 50px;
        z-index: 1;
      }
    } 
    .filter-responsive-btn{
      z-index: 2;
    } 
    .flightDetails .flight-detail-main .itours-tab-holder .itours-tab-list{
      display: flex;
      justify-content: space-between;
      background: transparent;
      .itours-tab-btn{
        background-color: $naminTravelYellow;
        color: $naminTravelDarkBlue;
        margin: 0 2px;
        flex-grow: 1;
        padding: 10px 5px;
        line-height: 18px;
        border-radius: 0;
        &.active{
          background-color: #fff;
        }
      }
    }
    .hotelResult .page-title {
      text-align: center;
      padding: 0;
      border: 0 none;
    }
  }
  .image-404 {
    max-width: 700px;
    width: 100%;
    margin: 35px auto;
    display: block;
  }  
  @media screen and (min-width: $large){
    .search-submit-col{
      width: 15.2%;
    }
    .search-main-col{
      width: 62%;
    }
    .passengers-and-cabinClass{
      width: 22.8%;
    }
    .package-searchform-block1{
      width: 73.1%;
    }
    .package-searchform-block2{
      width:26.9%;
    }
    .naminTravel-search-block .float-row.half-gap{
      margin-left: -2.5px;
      margin-right: -2.5px;
      [class*=col-]{
        padding-left: 2.5px;
        padding-right: 2.5px;
      }
    }
    .search-route-item .origin-destination-block{
      margin-right: 5px;
    }
    .hotel-search-form-holder .button-holder{
      padding-left: 5px;
      flex: 0 0 178px;
    }
    .hotel-search-form-holder .hotel-search-form-main{
      flex: auto;
    }
  }
  .search-form-wrapper .fake-return-date{
    font-size: 11px;
  }
  .searchBtn,.thickSearchBtn{
    border-radius: 4px;
  }
  @media screen and (max-width: $medium_){
    .header-modules-menu {
      display: flex;
      justify-content: space-between;
      .link-item{
        margin: 0;
        padding: 0 5px 5px;
        .itours-icon{
          display: none;
        }
      }
    }
  }

  .next-previous-group{
    .button{
      font-weight: 600;
      line-height: 13px;
      @media screen and (min-width: $large) {
        width: 170px;
      }
    }  
  }
  .autocompleteText .suggestList ul li .link .autocomplete-list-icon {
    top: 11px;
  }

  .language-btn {
    background: transparent;
    padding: 3px 5px;
    min-width: 0;
    margin-right: 5px;
    color: #fff;
    border: 1px solid;
    line-height: 1;
    .flag {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .languages-popup .language-item .flag {
    width: 25px;
    margin-right: 5px;
  }  

  ul.main-menu .languages-wrapper {
    text-align: left;
    padding: 15px;
  }

  .header-modules-menu .link-item .itours-icon {
    width: 22px;
    height: 22px;
    vertical-align: text-bottom;
    margin-right: 1px;
  }
  
  .namin-sort-btn-group {
    display: inline-flex;
    gap: 3px;
    .btn{
      font-weight: 600;
      background: gainsboro;
      line-height: 13px;
      @media screen and (min-width: $large) {
        width: 170px;
      }    
      @media screen and (min-width: $small) {
        &:first-child {
          body:not(.ltr) & {
            border-radius:  0 10px 10px 0;
          }
          body.ltr & {
            border-radius: 10px 0 0 10px;
          }
        }
        &:last-child {
          body:not(.ltr) & {
            border-radius: 10px 0 0 10px;
          }
          body.ltr & {
            border-radius:  0 10px 10px 0;
          }
        }
      }
  
      &.active{
        background: $naminTravelDarkBlue;
        color: #fff;
      }
    }
  }
  
  .language-label, .currency-label {
    color: #fff;
    display: block;
    font-size: 13px;
    @media screen and (max-width: $small_){
      display: none;
    }
  }
  
  .label-wrapper  {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .currency-language-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    @media screen and (max-width: $medium_){
      position: absolute;
      top: 10px;
      left: 5px;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
    }
  }
  .currency-btn {
    background: transparent;
    color: #fff;
    padding: 0 5px;
    border: 1px solid #fff;
    min-width: 0;
    height: 28px;
    width: 50px;
  }
  .currency-popup {
    width: 80px;
    left: 50%;
    margin-left: -37px;
  }
  .currency-wrapper .currency-item{
    margin-bottom: 0;
    height: 25px;
  }

}

.next-previous-group{  
  @media screen and (min-width: $small) {
    .button{
      &:first-child {
        body.naminTravel-theme:not(.ltr) & {
          border-radius:  0 10px 10px 0;
        }
        body.naminTravel-theme.ltr & {
          border-radius: 10px 0 0 10px;
        }
      }
      &:last-child {
        body.naminTravel-theme:not(.ltr) & {
          border-radius: 10px 0 0 10px;
        }
        body.naminTravel-theme.ltr & {
          border-radius:  0 10px 10px 0;
        }
      }
    }
  }
 
}

.accountWindow {    
  .Form.naminTravel-register-form  {
    @media (min-width:$small){
      max-width: 650px;
      .form-group {
        .form-label{
          width: 150px;
          display: inline-block;
        }
        .form-input,.form-select,.select-input-pair-holder-outer{
          display: inline-block;
          width: calc(100% - 150px);
        }
      }
      button.button.full-width {
        width: calc(100% - 150px);
      }
      .validator-label{
        body:not(.ltr) & {
          margin-right: 150px;
        }
        body.ltr & {
          margin-left: 150px;
        }
      }
      
    }
  }
}

.namin-logo-link {
  @media screen and (min-width: $medium){
    body:not(.ltr) & {
      margin-left: 35px;
    }
    body.ltr & {
      margin-right: 35px;
    }
  }
}

.namintravel-amazing-offer-list {
  color: #01357e;
  font-weight: 600;
  font-size: 17px;
  margin: 0;
  list-style-type: none;
  padding: 25px 0;
  li::before{
    content: "";
    display: inline-block;
    width: 8px;
    height: 10px;
    background-color: #b45a5b;    
    body:not(.ltr) & {
      margin-left: 7px;
      clip-path: polygon(0 50%, 100% 0, 100% 100%);
    }
    body.ltr & {
      margin-right: 7px;
      clip-path: polygon(0 0%, 100% 50%, 0 100%);
    }

  }
}

.namintravel-amazing-offer-inner {
  background-color: #fde68a;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 10px;
  max-width: 850px;
  margin: auto;  

  body:not(.ltr) & {
    background-image: url("../image/namintravel/suitcase-rtl.jpg");
    background-position: center right;
  }
  body.ltr & {
    background-image: url("../image/namintravel/suitcase.jpg");
    background-position: center left;
  }

}
.namintravel-amazing-offer-inner-image {
  margin: auto;
  display: block;
  max-width: 100%;
}

.namintravel-amazing-offer-title{
  @media screen and (max-height: 920px) {
    display: none;
  }
}