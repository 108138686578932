@import "home";
@import "paging";
@import "gallery";
@import "loading";
@import "flight";
@import "icon";
@import "theme";
* {
  box-sizing: border-box;
}
p {
  margin-top: 0;
}
a {
  text-decoration: none;
}
img {
  border: 0 none;
}
.no-margin {
  margin: 0;
}
.bold {
  font-weight: bold;
}
.semi-bold {
  font-weight: 600;
}
.body .en-font {
  font-family: $fontEn;
}
.fa-font {
  font-family: $font !important;
}
$gridGap: 15px;
.float-row {
  &::after,
  &::before {
    content: "";
    clear: both;
    display: block;
  }
  margin-left: -$gridGap/2;
  margin-right: -$gridGap/2;
  &.no-gap {
    margin-left: 0;
    margin-right: 0;
    [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
  &.half-gap {
    margin-left: -$gridGap/4;
    margin-right: -$gridGap/4;
    [class*="col-"] {
      padding-right: $gridGap/4;
      padding-left: $gridGap/4;
    }
  }
  &.large-gap {
    margin-left: -$gridGap;
    margin-right: -$gridGap;
    [class*="col-"] {
      padding-right: $gridGap;
      padding-left: $gridGap;
    }
  }
  &.xlarge-gap {
    margin-left: -1.5*$gridGap;
    margin-right: -1.5*$gridGap;
    [class*="col-"] {
      padding-right: 1.5*$gridGap;
      padding-left: 1.5*$gridGap;
    }
  }

  [class*="col-"] {
    float: right;
    box-sizing: border-box;
    padding-right: $gridGap/2;
    padding-left: $gridGap/2;
  }
  &.reverse {
    & > [class*="col-"] {
      body:not(.ltr) & {
        float: left;
      }
      body.ltr & {
        float: right;
      }
    }
  }
  &.ltr {
    & > [class*="col-"] {
      float: left;
    }
  }
}
body.ltr .float-row [class*="col-"] {
  float: left;
}
@for $i from 1 through 12 {
  .col-small-#{$i} {
    width: 100% * $i / 12;
  }
  .offset-small-#{$i} {
    body:not(.ltr) & {
      margin-right: 100% * $i / 12;
    }
    body.ltr & {
      margin-left: 100% * $i / 12;
    }
  }
}
@media (min-width: $medium) {
  @for $i from 1 through 12 {
    .col-medium-#{$i} {
      width: 100% * $i / 12;
    }
    .offset-medium-#{$i} {
      body:not(.ltr) & {
        margin-right: 100% * $i / 12;
      }
      body.ltr & {
        margin-left: 100% * $i / 12;
      }
    }
  }
}
@media (min-width: $large) {
  @for $i from 1 through 12 {
    .col-large-#{$i} {
      width: 100% * $i / 12;
    }
    .offset-large-#{$i} {
      body:not(.ltr) & {
        margin-right: 100% * $i / 12;
      }
      body.ltr & {
        margin-left: 100% * $i / 12;
      }
    }
  }
  .body .hidden-large {
    display: none;
  }
}
@media (max-width: $small_) {
  @for $i from 1 through 12 {
    .col-xsmall-#{$i} {
      width: 100% * $i / 12;
    }
    .offset-xsmall-#{$i} {
      body:not(.ltr) & {
        margin-right: 100% * $i / 12;
      }
      body.ltr & {
        margin-left: 100% * $i / 12;
      }
    }
  }
  .body .hidden-xsmall,.body .container .hidden-xsmall {
    display: none;
  }
}
@for $i from 10 through 30 {
  .font-#{$i} {
    font-size: $i+px;
  }
}
@for $i from 10 through 30 {
  @media (min-width: $small){
    .sm-font-#{$i} {
      font-size: $i+px;
    }
  }
}
@media (max-width: $small_){
  .xs-font-12{
  font-size: 12px;
  }
}
.margin-x{
  margin-right: 15px;
  margin-left: 15px;
}
.margin-start {
  body:not(.ltr) & {
    margin-right: 20px;
  }
  body.ltr & {
    margin-left: 20px;
  }
}
.margin-start-half {
  body:not(.ltr) & {
    margin-right: 10px;
  }
  body.ltr & {
    margin-left: 10px;
  }
}
.margin-start-light {
  body:not(.ltr) & {
    margin-right: 5px;
  }
  body.ltr & {
    margin-left: 5px;
  }
}
.padding-bottom{
  padding-bottom: 15px;
}
.padding-bottom-large{
  padding-bottom: 35px;
}
.padding-top{
  padding-top: 15px;
}
.padding-top-half{
  padding-top: 10px;
}
@media (min-width: $large) {
  .margin-start-large {
    body:not(.ltr) & {
      margin-right: 20px;
    }
    body.ltr & {
      margin-left: 20px;
    }
  }
}
@media (min-width: $medium) {
  .margin-start-medium {
    body:not(.ltr) & {
      margin-right: 20px;
    }
    body.ltr & {
      margin-left: 20px;
    }
  }
}

@media (min-width: $small) and (max-width: $medium_) {
  .body .hidden-small {
    display: none !important;
  }
}
@media (min-width: $medium) and (max-width: $large_) {
  .body .hidden-medium {
    display: none !important;
  }
}
//end grid float
.hide {
  display: none !important;
}
.hidden-responsive {
  @media (max-width: $medium_) {
    display: none !important;
  }
}
.bg-white {
  background-color: #fff;
}
.bg-gray {
  background-color: #f1f1f1;
}
.full-width {
  width: 100%;
  box-sizing: border-box;
}
.min-height-1 {
  min-height: 1px;
}
.small {
  font-size: 85%;
}
.white {
  color: #fff;
}
.bg-dark-blue {
  background-color: rgba(0, 0, 0, 0.5);
}
.hr_padding {
  padding-left: 20px;
  padding-right: 20px;
}
.hr_padding_half {
  padding-left: 10px;
  padding-right: 10px;
}
.inline-icon {
  display: inline-block;
  vertical-align: middle;
}
.inline-itours-logo {
  width: 110px;
  margin: 0 10px;
}
.inline-block-middle {
  display: inline-block;
  vertical-align: middle;
}
.inline-block-top {
  display: inline-block;
  vertical-align: top;
}
.box-title {
  padding: 15px;
}
.purple {
  color: $purple;
}
.uppercase {
  text-transform: uppercase;
}
.margin-b-3 {
  margin-bottom: 3px;
}
hr {
  border: none;
  border-bottom: 1px solid #ddd;
  margin: 5px 0;
}
.icon {
  display: inline-block;
  vertical-align: middle;
}
.body .black {
  color: #111;
}
.green {
  color: $green;
}
.blue {
  color: $blueLink;
}
.blue2{
  color: #034fa5 ;
}
.yellow {
  color: $yellow;
}
.pointer {
  cursor: pointer;
}
.underlined {
  text-decoration: underline;
}
.page-padding {
  padding-top: 40px;
  padding-bottom: 40px;
  @media (max-width: $medium_) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  @media screen and (max-width: $small_) {
    &.no-padding-mobile {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
@media screen and (max-width: $small_) {
  .no-padding-mobile {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
.box-shadow {
  @include box-shadow($boxshadow1);
}
.box-border {
  border: 1px solid rgb(204, 204, 204);
  &.border-dashed{
    border-style: dashed;
  }
  &.border-2{
    border-width: 2px;
  }
}
.medium-box-border{
  @media screen and (min-width: $medium) {
    border: 1px solid rgb(204, 204, 204);
    &.border-dashed{
      border-style: dashed;
    }
    &.border-2{
      border-width: 2px;
    }
  }

}
.border-top {
  border-top: 1px solid #bbb;
  padding-top: 10px;
  margin-top: 10px;
}
.clearfix {
  &::after,
  &::before {
    content: "";
    display: block;
    clear: both;
  }
}

.line-40 {
  line-height: 40px;
}
.no-margin-top {
  margin-top: 0 !important;
}
.no-margin-end {
  body:not(.ltr) & {
    margin-left: 0 !important;
  }
  body.ltr & {
    margin-right: 0 !important;
  }
}
.bg-white {
  background-color: #fff;
}
.gray {
  color: $gray;
}

.blue {
  color: $blueLink;
}
.golden {
  color: #d2ab67;
}

body:not(.ltr) .mirror {
  @include transform(scaleX(-1));
}
.thumbnail-link {
  text-decoration: none;
  color: inherit;
}
.container .large-font {
  font-size: 130%;
}

// Radio button
//
// Add .radio-btn-label class to radio button label
//
// Markup:
// <label class='radio-btn-label'>
//      <input type="radio" />
// </label>
//
// Styleguide Components.Buttons.Radio

.radio-btn-label {
  display: inline-block;
  width: auto;
  margin: 0;
  body:not(.ltr) & {
    margin-left: 20px;
  }
  body.ltr & {
    margin-right: 20px;
  }
  cursor: pointer;
  [type="radio"] {
    vertical-align: middle;
    margin: 0;
    body:not(.ltr) & {
      margin-left: 6px;
    }
    body.ltr & {
      margin-right: 6px;
    }
  }
  &.type-2{
    position: relative;
    display: block;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 5px;
    body &{
      margin-right: 0;
      margin-left: 0;
    }
    // display: flex;
    // flex-direction: row-reverse;
    // justify-content: space-between;
    // align-items: center;
    // body &{
    //   margin-right: 0;
    //   margin-left: 0;
    // }
    // [type="radio"] {
    // }
    .radio-btn-label-text{
      display: flex;
      justify-content: space-between;
      align-items: center;
      &::after{
        content: "";
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        border: 1px solid #2dabc2;

      }
    }
    input[type="radio"] {
      &:disabled{
        cursor: no-drop;
        +.radio-btn-label-text{
          color: #aaa;
          &::after{
            border-color:#bbb;
          }
        }
      }
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      border: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      &:checked + .radio-btn-label-text::after{
        background-color: #2dabc2;
      }
    }
  }
}

//end Radio

.passenger-header {
  border-bottom: 1px solid #bbb;
}

// Float
//
// Add .clearfix class to floating item container
// Use .pull-right pull-left to float element
//
// Markup:
// <div className="clearfix">
//    <div className="pull-right">
//    </div>
// </div>
//
// Styleguide Components.Float
.pull-start {
  body:not(.ltr) & {
    float: right;
  }
  body.ltr & {
    float: left;
  }
}

.pull-end {
  body:not(.ltr) & {
    float: left;
  }
  body.ltr & {
    float: right;
  }
}

@media (min-width: $small) {
  .pull-start-small {
    body:not(.ltr) & {
      float: right;
    }
    body.ltr & {
      float: left;
    }
  }
  .pull-end-small {
    body:not(.ltr) & {
      float: left;
    }
    body.ltr & {
      float: right;
    }
  }
}
@media (min-width: $medium) {
  .pull-start-medium {
    body:not(.ltr) & {
      float: right;
    }
    body.ltr & {
      float: left;
    }
  }
  .pull-end-medium {
    body:not(.ltr) & {
      float: left;
    }
    body.ltr & {
      float: right;
    }
  }
}
@media (min-width: $large) {
  .pull-start-large {
    body:not(.ltr) & {
      float: right;
    }
    body.ltr & {
      float: left;
    }
  }
  .pull-end-large {
    body:not(.ltr) & {
      float: left;
    }
    body.ltr & {
      float: right;
    }
  }
}

.pull-right {
  float: right;
  /*     body.ltr &{
        float:left;
    } */
}
.pull-left {
  float: left;
  /*     body.ltr &{
        float:right;
    } */
}
//end Float

// text-align classes
//
// Use .text-left .text-right .text-center .justified  class to align element contents.
//
// Markup:
// <div class="text-center">
//      ...
// </div>
//
// Styleguide Components.alignment
.text-start {
  body:not(.ltr) & {
    text-align: right;
  }
  body.ltr & {
    text-align: left;
  }
}
.text-end {
  body:not(.ltr) & {
    text-align: left;
  }
  body.ltr & {
    text-align: right;
  }
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.justified {
  text-align: justify;
}
//end alignment

.margin-bottom {
  margin-bottom: 15px;
}
.margin-bottom-half {
  margin-bottom: 10px;
}
.margin-bottom-light {
  margin-bottom: 5px;
}
@media (min-width: $medium){
  .sm-margin-bottom {
    margin-bottom: 15px;
  }
  .sm-margin-bottom-half {
    margin-bottom: 10px;
  }
  .sm-margin-bottom-light {
    margin-bottom: 5px;
  }
}
@media (max-width: $medium_){
  .xs-margin-bottom {
    margin-bottom: 15px;
  }
  .xs-margin-bottom-half {
    margin-bottom: 10px;
  }
  .xs-margin-bottom-light {
    margin-bottom: 5px;
  }
}
.body .margin-bottom-large {
  margin-bottom: 25px;
}
.body .margin-top-large {
  margin-top: 25px;
}
.margin-bottom-xlarge {
  margin-bottom: 50px;
}
.margin-top-xlarge {
  margin-top: 50px;
}
.margin-top-large {
  margin-top: 35px;
}
.margin-top {
  margin-top: 15px;
}
.margin-top-light {
  margin-top: 5px;
}
.margin-top-half {
  margin-top: 10px;
}
.margin-left {
  margin-left: 15px;
}
.margin-right {
  margin-right: 15px;
}
.margin-left-light {
  margin-left: 5px;
}
.margin-right-light {
  margin-right: 5px;
}
.small-margin-bottom {
  margin-bottom: 5px;
}
.small-margin-top {
  margin-top: 5px;
}
.small-margin-left {
  body:not(.ltr) & {
    margin-left: 5px;
  }
  body.ltr & {
    margin-right: 5px;
  }
}
.small-margin-right {
  body:not(.ltr) & {
    margin-right: 5px;
  }
  body.ltr & {
    margin-left: 5px;
  }
}
.white-page {
  background: #fff;
  border-top: 1px solid #eaeaea;
}
.vertical-flex-ends {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.content-normal-title {
  font-size: 18px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 15px;
}
.padding-20 {
  padding: 20px;
}
.padding-v-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.padding-v-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.padding-v-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.padding-h-5 {
  padding-left: 5px;
  padding-right: 5px;
}
.padding-h-20 {
  padding-left: 20px;
  padding-right: 20px;
}
.padding-v-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.padding-h-15 {
  padding-left: 15px;
  padding-right: 15px;
}
.padding-h-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.nowrap {
  white-space: nowrap;
  &.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
  }
}
.price{
  &.line-through span {
    text-decoration: line-through;
    color: #aaa;
  }
}
.large-price {
  font-size: 25px;
  font-weight: 600;
}


.ant-col.ant-form-item-label {
  line-height: 25px;
  text-align: inherit;
}

// Form control
//
// Add .form-group class to input,select,... holder
// Add .form-control class to input. select,...
// Add .form-label class to label
// Add .required class to .form-group fore required fields
// Add .right-placeholder class to fields like email and phon number to have ltr field with rtl placeholder
//
// Markup:
// <div class="form-group required right-placeholder">
//    <label class="form-label"> ... </label>
//    <input class="form-control" type="text"/>
// </div>
//
// Styleguide Components.Counter
.form-group {
  &.no-margin-bottom {
    margin-bottom: 0;
  }
  margin-bottom: 10px;
  margin-top: 0;
  .input-icon {
    position: absolute;
    left: 5px;
    top: 7px;
  }
  .form-label {
    margin: 0 0 5px;
    font-size: 13px;
  }
  &.required {
    .form-label {
      &::before {
        content: "*";
        color: $purple;
        display: inline;
        margin-inline-end: 5px;
        font-size: 15px;
      }
    }
  }
  .form-input,.country-code-pair .ant-input-group .ant-input {
    height: 40px;
    padding: 6px 10px;
    border: 1px solid $border-color;
    border-radius: 2px;
    display: block;
    background-color: #fff;
    .search-box-holder & {
      height: 35px;
    }
    &.placeholder-right {
      body:not(.ltr) & {
        direction: ltr;
        text-align: left;
        &::placeholder {
          text-align: right;
        }

        &:-ms-input-placeholder {
          text-align: right;
        }

        &::-ms-input-placeholder {
          text-align: right;
        }
      }
      &.en-input-text {
        body:not(.ltr) & {
          font-family: opensans, sans-serif;
          &::placeholder {
            font-family: vazir, sans-serif;
          }

          &:-ms-input-placeholder {
            font-family: vazir, sans-serif;
          }

          &::-ms-input-placeholder {
            font-family: vazir, sans-serif;
          }
        }
      }
    }
    &[disabled] {
      border-color: rgb(219 219 219);
      color: #a6a6a6;
      background-color: #f1f1f1;
    }
  }
  .form-select {
    height: 40px;
    padding: 6px 10px;
    border: 1px solid rgb(204, 204, 204);
    .search-box-holder & {
      height: 35px;
    }
    background-color: #fff;
    max-width: 100%;
    border-radius: 2px;
    &::-ms-expand {
      display: none;
    }
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url("../image/down-caret.svg");
    background-repeat: no-repeat;
    background-size: 25px;
    @media screen and (max-width: $small_) {
      background-size: 17px;
    }
    &[disabled] {
      background-color: #f1f1f1;
    }
  }
  body:not(.ltr) & {
    .form-select {
      background-position: left 0 center;
      padding-left: 15px;
      padding-right: 10px;
      @media screen and (max-width: $small_) {
        padding-left: 12px;
        padding-right: 8px;
      }
      &[dir="ltr"] {
        background-position: right 0 center;
        padding-right: 15px;
        padding-left: 10px;
        @media screen and (max-width: $small_) {
          padding-left: 8px;
          padding-right: 12px;
        }
      }
    }
  }
  body.ltr & {
    .form-select {
      background-position: right 0 center;
      padding-right: 15px;
      padding-left: 10px;
      @media screen and (max-width: $small_) {
        padding-left: 8px;
        padding-right: 12px;
      }
    }
  }

  .form_control,
  .itours-select.form_control {
    height: 40px;
    padding: 6px 10px;
    border-radius: 2px;
    border: 1px solid rgb(204, 204, 204);
    color: rgb(73, 80, 87);
    outline: none;
    &:focus {
      border-color: rgb(2, 100, 122);
    }
  }
  .form-control,
  .form_control,
  .ant-calendar-picker-input {
    text-align: left;
    direction: ltr;
    @include box-shadow(inset 0 2px 4px 0 rgba(0, 0, 0, 0.1));
    width: 100%;
    border: 1px solid $border-color;
    @include border-radius(3px);
    outline: none;
    height: 40px;
    .search-box-holder & {
      height: 35px;
    }
    display: block;
    box-sizing: border-box;
    padding: 0 10px;
    font-size: 15px;
    @media screen and (max-width: $small_) {
      font-size: 12px;
    }
    background: #fff;
    color: #333;
    line-height: 35px;
    &.right-placeholder {
      body:not(.ltr) & {
        direction: ltr;
        text-align: left;
        &::placeholder {
          text-align: right;
        }

        &:-ms-input-placeholder {
          text-align: right;
        }

        &::-ms-input-placeholder {
          text-align: right;
        }
      }
      &.en-input-text {
        body:not(.ltr) & {
          font-family: opensans, sans-serif;
          &::placeholder {
            font-family: vazir, sans-serif;
          }

          &:-ms-input-placeholder {
            font-family: vazir, sans-serif;
          }

          &::-ms-input-placeholder {
            font-family: vazir, sans-serif;
          }
        }
      }
    }
  }
  input,
  select {
    font-family: inherit;
    &.form-control {
      height: 35px;
    }
  }
  textarea,
  input[type="textarea"] {
    &.form-textarea,
    &.form-input,
    &.ant-input {
      height: 100px;
      &.hotel-comment-textarea{
        height: 215px;
      }
      resize: none;
      padding: 5px 10px;
      width: 100%;
      border-radius: 2px;
      border: 1px solid $border-color;
      display: block;
      &:focus {
        border-color: $baseColor;
      }
    }
  }
  .ant-select-selection {
    border: 1px solid $border-color;
    border-radius: 0;
    height: 35px;
    line-height: 35px;
  }
  .ant-select-selection__placeholder {
    color: #999;
  }
}
//end form control

//menu:
.menu-toggle-btn {
  width: 25px;
  height: 17px;
  min-width: 0;
  border-radius: 0;
  position: relative;
  background-color: transparent !important;
  outline: none !important;
  @media screen and (min-width: $medium) {
    display: none;
  }
  .menu-btn-bar {
    height: 2px;
    position: absolute;
    left: 0;
    right: 0;
    background-color: $purple;
    display: block;
    @include transition(all 0.2s ease-in-out);
    &.icon-top-bar {
      top: 0;
    }
    &.icon-bottom-bar {
      bottom: 0;
      opacity: 1;
    }
    &.icon-middle-bar {
      top: 50%;
      margin-top: -1px;
    }
  }
  &.opened {
    .icon-middle-bar {
      left: 50%;
      right: 50%;
      opacity: 0;
    }
    .icon-bottom-bar {
      @include transform(rotate(45deg));
      top: 50%;
    }
    .icon-top-bar {
      @include transform(rotate(-45deg));
      top: 50%;
    }
  }
}
@media screen and (max-width: $medium_) {
  .main-menu-holder {
    position: fixed;
    top: 0;
    height: 100vh;
    background: #fff;
    overflow: hidden;
    z-index: 101;
    body:not(.ltr) & {
      right: 0;
      width: calc(100% - 100px);
      transform: translateX(110%);
      border-left: 1px solid $border-color;
    }
    body.ltr & {
      left: 0;
      width: calc(100% - 100px);
      transform: translateX(-110%);
      border-right: 1px solid $border-color;
    }
    @include transition(all 0.15s ease);
    &.expanded {
      body:not(.ltr) & {
        transform: translateX(0);
      }
      body.ltr & {
        transform: translateX(0);
      }
    }
    .menu-toggle-btn {
      margin: 15px;
      display: block;
      width: 25px;
      height: 25px;
      body:not(.ltr) & {
        float: left;
      }
      body.ltr & {
        float: right;
      }
      .menu-btn-bar {
        background-color: $purple;
      }
      & + .main-menu {
        clear: both;
      }
    }
    .main-menu .menu-item {
      display: block;
      body:not(.ltr) & {
        margin-left: 0;
      }
      body.ltr & {
        margin-right: 0;
      }
      &:first-child {
        margin-top: 20px;
      }
      .menu-link {
        padding: 10px 15px;
        border: 0 none;
        .itours-icon {
          width: 20px;
          height: 20px;
        }
      }
      &.active {
        .menu-link {
          background-color: #f1f1f1;
        }
      }
    }
  }
}
.main-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 15px;
  .menu-item {
    display: inline-block;
    body:not(.ltr) & {
      margin-left: 30px;
    }
    body.ltr & {
      margin-right: 30px;
    }
    a {
      @include transition(all 0.1s);
      display: block;
      background-color: transparent;
      color: #777;
      text-decoration: none;
      border-bottom: 3px solid transparent;
      cursor: pointer;
      font-weight: 100;
      &:hover {
        color: #005ccc;
      }
      &[disabled] {
        color: #afafaf;
        background: transparent;
        cursor: no-drop;
      }
    }
    &.active a {
      color: $purple;
      border-color: $purple;
    }
  }
}
.showMessage {
  .content {
    padding-bottom: 18px;
  }
  margin: 0 auto;
  min-width: 300px;
  max-width: 500px;
  border: 1px solid $yellow;
  padding: 8px;
  margin-top: 20px;
  text-align: center;
  color: white;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.1);
  a {
    text-decoration: none;
    color: white;
    &:hover {
      color: $orangeText;
    }
  }
}
.body {
  background-color: #f4f6f8;
  direction: rtl;
  line-height: 30px;
  font-family: $font;
  color: rgb(58, 58, 58);
  font-size: 15px;
  margin: 0;
  padding: 0;
  &.arabic {
    font-family: $arfont;
  }

  &.ltr {
    font-family: opensans, sans-serif;
    direction: ltr;
  }
  @media screen and (max-width: $medium_) {
    font-size: 14px;
    line-height: 25px;
  }

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: unset;
  }
}
body {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
  }
  .content-link {
    text-decoration: underline;
    color: rgb(2, 100, 122);
    font-weight: 600;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
  //general
  .row {
    width: 100%;
    display: flex;
  }
  // Button
  //
  // Add .button class to button,a,...
  // Add .red-btn class to buton for red button
  // Add .blue-btn class to buton for blue button
  // Add .link-style-btn class to buttons which style should be like links
  //
  // Markup:
  // <button class="button red-btn"> ... </button>
  // <a class="button red-btn"> ... </button>
  // <input type="button" class="button blue-btn"> ... </button>
  //
  // Styleguide Components.Button
  .button,
  .button[type="submit"] {
    text-decoration: none;
    padding: 5px 15px;
    line-height: 27px;
    display: inline-block;
    //min-width: 120px;
    text-align: center;
    -webkit-appearance: unset;
    outline: none;
    cursor: pointer;
    border: 0 none;
    font-family: inherit;
    font-weight: 600;
    font-size: 15px;
    &.large-btn{
      padding: 7px 15px;
      min-width: 160px;
      text-align: center;
    }
    @media screen and (max-width: $small_) {
      font-size: 13px;
    }
    //max-width: 220px;
    @include border-radius(3px);
    @include transition(all 0.2s);
    &.thick-btn {
      height: 50px;
    }
    &.red-btn {
      background: $purple;
      color: #fff;

      &:hover {
        background-color: $purpleLight;
      }

      &.small-btn {
        line-height: 15px;
        padding: 5px 10px;
        font-size: 13px;
      }
      
    }

    &.dark-btn {
      background: #35353f;
      color: #fff;
    }
    &.green-btn {
      background-color: rgb(29, 172, 8);
      color: #fff;
      &:hover {
        background-color: #199207;
      }
    }

    &.border-btn {
      background-color: transparent;
      .button.border-btn {
        border: 1px solid $blueLink;
        color: red;
      }
    }

    &.blue-btn {
      background: $blueLink;
      color: #fff;
      &.small-btn {
        line-height: 15px;
        padding: 5px 10px;
        font-size: 13px;
      }
      &.medium-btn {
        line-height: 17px;
        padding: 7px 12px;
        font-size: 14px;
      }      

      /*&:hover {
        background-color: $blueLight;
      } */
      &:active {
        background-color: #02647a;
      }
      &.loading-btn,&.loading-btn[disabled]{
        background-color: $blueLink;
      }
    }
    &.grey-btn {
      background: #999;
      color: #fff;

      &:hover {
        background-color: #aaa;
      }
    }
    &.yellow-btn{
      background-color: $yellow;
      color: #fff;
      &.medium-btn {
        line-height: 17px;
        padding: 7px 12px;
        font-size: 14px;
      }
    }
    &.white-btn{
      background-color: #fff;
      color: #045fc8;
    }
    &.light-grey-btn {
      background-color: #e5eaec;
      color: #607d8b;
      &:hover {
        background-color: #c6d0d4;
        color: #40606f;
      }
      &.active{
        background-color: #607d8b;
        color: #fff;
      }
    }
    &[disabled] {
      background-color: #bbb;
      &:hover {
        background-color: #bbb;
      }
      cursor: no-drop;
    }
  }
  .flight-baggage-detail .detail-flight-item:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }
  .link-style-btn {
    background-color: transparent;
    border: 0 none;
    padding: 0;
    font-family: inherit;
    min-width: 0;
    border-radius: 0;
    color: $blueLink;
    outline: none !important;
    cursor: pointer;
    width: auto !important;
    &:hover {
      color: $blueLinkHover;
      background-color: transparent;
    }
    &.red{
      color: $purple;
    }
  }

  .link-style-b-btn {
    background-color: transparent;
    border: 1px solid ;
    padding: 0 5px;
    font-family: inherit;
    min-width: 0;
    border-radius: 4px;
    color: $blueLink;
    outline: none !important;
    cursor: pointer;
    width: auto !important;
    &:hover {
      color: $blueLinkHover;
      background-color: transparent;
    }
    &.red{
      color: $purple;
    }
  }


  button {
    border: 0;
    padding: 8px;
    background-color: $yellow;
    min-width: 80px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: $orangeText;
    }
  }

  button[type="submit"] {
    border: 0;
    padding: 10px;
    min-width: 120px;
    border-radius: 6px;
    background-color: $yellow;
    font-size: 14px;
    font-family: inherit;
    margin-top: 10px;
    cursor: pointer;
    transition: all 0.2s ease-in;
  }

  label {
    color: $text;
  }
  // end button
  .page-heading {
    font-size: 24px;
    font-weight: 600;
    padding-top: 50px;
  }

  .relative {
    position: relative;
  }
  .inline-block {
    display: inline-block;
  }

  .top-align {
    vertical-align: top;
  }
  // Select
  //
  // Add .itours-select class to select
  //
  // Markup:
  // <select class="itours-select">
  //      <option> ... <option>
  //      <option> ... <option>
  //      <option> ... <option>
  // </select>
  //
  // Styleguide Components.Select
  .itours-select {
    outline: none !important;
    background-color: #fff;
    max-width: 100%;
    border-radius: 3px;
    &::-ms-expand {
      display: none;
    }
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url("../image/down-caret.svg");
    background-repeat: no-repeat;
    background-size: 15px;
  }

  &:not(.ltr) {
    .itours-select {
      background-position: left 0 center;
      padding-left: 15px;
      padding-right: 10px;
      @media screen and (max-width: $small_) {
        padding-left: 12px;
        padding-right: 8px;
      }
      &[dir="ltr"] {
        background-position: right 0 center;
        padding-right: 15px;
        padding-left: 10px;
        @media screen and (max-width: $small_) {
          padding-left: 8px;
          padding-right: 12px;
        }
      }
    }
  }
  &.ltr {
    .itours-select {
      background-position: right 0 center;
      padding-right: 15px;
      padding-left: 10px;
      @media screen and (max-width: $small_) {
        padding-left: 8px;
        padding-right: 12px;
      }
    }
  }
  //end Select
  input[type="email"],
  input[type="password"],
  input[type="number"],
  input[type="tel"],
  input[type="text"],
  select,
  .DayPickerInput > input {
    width: 100%;
    height: 35px;
    padding: 0 4px;
    border-radius: 0;
    box-shadow: none;
    border: 1px solid #bcc7c4;
    box-sizing: border-box;
    font-family: inherit;
    font-size: 14px;
    @media screen and (max-width: $small_) {
      font-size: 16px;
    }

    &:focus {
      border: 1px solid $baseColor;
    }
  }

  .page-container {
    box-sizing: border-box;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    @media screen and (max-width: $small_) {
      padding-right: 10px;
      padding-left: 10px;
      &.no-padding-mobile {
        padding-right: 0;
        padding-left: 0;
        overflow: hidden;
      }
    }
    @media screen and (max-width: $medium_) {
      &.no-padding-mobile-tablet {
        padding-right: 0;
        padding-left: 0;
        overflow: hidden;
      }
    }
  }

  .page-container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }

  .navbar {
    font-size: 13px;
    background-color: #fff;
    position: relative;
    padding-bottom: 15px;
    @media screen and (min-width: $medium) {
      &.homeNavbar {
        position: relative;
        width: 100%;
      }

      padding-bottom: 0;
    }
    .logo-holder {
      .logo {
        padding-top: 5px;
        display: block;
        width: 120px;
        margin-bottom: 5px;
        min-height: 41px;
        @media screen and (max-width: $small_) {
          width: 100px;
          margin: auto;
          img{
            max-width: 100%;
          }
        }
        img {
          display: block;
        }
      }
    }
  }

  .validator-label {
    color: $purple;
    line-height: 15px;
    font-size: 14px;
    padding-top: 5px;
  }
  .bg-dark-blue .validator-label {
    color: #ffc107;
  }

  .link {
    cursor: pointer;
    transition: all 0.2s ease-in;
    color: $blueLink;

    &:hover {
      color: $blueLinkHover;
    }
  }

  .MessageLoading {
    font-size: 22px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }

  .MessageShow {
    color: white;
    text-align: center;

    &.Success {
      background-color: rgba(255, 185, 0, 0.3);
      border: 2px solid $yellow;
    }

    &.Fail {
      background-color: rgba(255, 0, 0, 0.3);
      border: 2px solid $orangeText;
    }

    a {
      display: block;
      color: white;
      text-decoration: none;
      background-color: $yellow;
      padding: 2px;
    }

    border-radius: 4px;
    line-height: 36px;
    font-size: 18px;
    width: 300px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.button.has-arrow {
  position: relative;
  body:not(.ltr) & {
    padding-left: 30px;
  }
  body.ltr & {
    padding-right: 30px;
  }

  &::after {
    content: "";
    display: block;
    top: 50%;
    position: absolute;
    width: 8px;
    height: 8px;
    border-top: 2px solid;
    border-right: 2px solid;
    margin-top: -5px;
    body:not(.ltr) & {
      left: 15px;
      @include transform(rotate(-135deg));
    }
    body.ltr & {
      right: 15px;
      @include transform(rotate(45deg));
    }
  }
}

.link-style-btn {
  body:not(.ltr) & {
    text-align: right;
  }
  body.ltr & {
    text-align: left;
  }
}
.main-content {
  min-height: calc(100vh - 510px);
  // @media screen and (max-width: $medium_) {
  //   overflow: hidden;
  // }
}
.room-guests {
  .room-number {
    display: none;
  }
  @media screen and (max-width: $medium_) {
    .item-number {
      padding-right: 5px;
    }
  }
  @media screen and (max-width: $small_) {
    &.multyroom {
      /*       body:not(.ltr) & {
        padding-right: 50px;
      }
      body.ltr &{
        padding-left: 50px;
      }   */
      position: relative;
      .room-number {
        color: #ffffff;
        display: inline-block;
        position: absolute;
        top: 5px;
        body:not(.ltr) & {
          right: 0;
        }
        body.ltr & {
          left: 0;
        }
      }
      &:first-child .room-number {
        top: 30px;
      }
    }
  }
}
.search-section {
  padding: 35px 0;
  background-color: $blueFooter;
  position: relative;

  @media screen and (max-width: $medium_) {
    .tab-holder {
      display: none;
    }
  }

  @media screen and (max-width: $medium_) {
    padding: 0;
    background-image: none;
  }
  & > .page-container {
    @media screen and (max-width: $medium_) {
      padding: 0;
      //overflow: hidden;
    }
  }
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.2)
    );
  }

  .search-box {
    position: relative;
    z-index: 1;

    @media screen and (min-width: $medium) {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      body.ltr & {
        justify-content: space-between;
      }
    }
  }
}
.body .result-page-search-bar {
  .tab-holder {
    display: none;
  }
  //background-color: #002142;
  padding: 10px 0;
  &::before,
  &::after {
    display: none;
  }
  .search-box-holder {
    margin: 0;
    background-color: transparent;
    box-shadow: none;
  }
  .search-box {
    display: block;
    .adv-banner {
      display: none;
    }
    .tab-holder {
      display: none;
    }
    @media screen and (min-width: $large) {
      .triptype-radio {
        margin-bottom: 0;
      }
    }
  }
}
//tab:
.tab-holder {
  .tab-icon {
    height: 26px;
    display: inline-block;
    vertical-align: middle;
    margin: 0 2px;
    @media screen and (max-width: $small_) {
      display: none;
    }
    filter: brightness(0.6);
  }
  &.flex {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: $medium) {
      flex-direction: row;
      .tab-s{
        flex: 1 1;
      }
    }
  }

  .tab-s {
    color: inherit;
    .itours-icon {
      margin: 0 2px;
    }
    body:not(.ltr) & {
      border-left: 1px solid #bbb;
    }
    body.ltr & {
      border-right: 1px solid #bbb;
    }

    .profileContainer & {
      @media screen and (max-width: $medium_) {
        body:not(.ltr) & {
          border-right: 0 none;
        }
        body.ltr & {
          border-left: 0 none;
        }
      }
    }
    border-bottom: 1px solid #bbb;
    line-height: 45px;
    border-radius: 0;
    padding: 0;
    background-color: transparent;
    background-color: #fff;
    color: #999;
    font-weight: bold;
    font-family: inherit;
    font-size: inherit;
    outline: none;
    text-decoration: none;
    &[disabled] {
      color: #ddd;
      background: #fff !important;
      cursor: no-drop;
      .tab-icon {
        filter: brightness(0.87);
      }
      .itours-icon {
        filter: brightness(1.18);
      }
    }
    &:last-child {
      body:not(.ltr) & {
        border-left: 0 none;
      }
      body.ltr & {
        border-right: 0 none;
      }
    }

    &:hover {
      background-color: #f1f1f1;
    }

    &.active-tab-s {
      background-color: $purple;
      color: #fff;
      .tab-icon {
        filter: brightness(1);
      }
    }
  }
}

.search-box-holder {
  .domestic-hotel-details & {
    background-color: white;
    padding: 15px 15px 5px;
    box-shadow: none;
    border: 1px solid #cccccc;
    border-radius: 0;
    .form-group .form-input {
      height: 45px;
      border-radius: 4px;
    }
    .hotel-search-btn{
      height: 45px;
    }
  }
  .trip-radio {
    margin-left: 20px;
    display: inline-block;
    margin-bottom: 10px;
    cursor: pointer;
  }
  width: 100%;
  .searchbox-inner {
    position: relative;
    //padding: 10px;
    @media screen and (min-width: $medium) {
      // padding: 0;
      .search-result-page & {
        padding: 20px;
      }
    }
  }
  /*   @media screen and (max-width: $large_) {
    .multy-dest-route {      
      body:not(.ltr) &{
        padding-right: 15px;
      }
      body.ltr &{
        padding-left: 15px;
      }
      margin-bottom: 10px;
      position: relative;
      &::before{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 10px;
        body:not(.ltr) &{
          right: 0;
        }
        body.ltr &{
          left: 0;
        }                
        width: 6px;
        background: #fff;
        @include border-radius(5px);
      }
    }
  } */
  label {
    color: #fff;
    color: inherit;
  }
  @media screen and (min-width: $medium) {
    flex: 1 1 850px;
    line-height: 25px;
    @include box-shadow($boxshadow1);
  }

  .form-group .form-control,
  .form-group .itours-select {
    @include box-shadow(none);
  }
  .form-group .form-label {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
  }
}

.section-vertical-padding {
  padding-top: 35px;
  padding-bottom: 35px;
  @media screen and (max-width: $medium_) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.cities-section {
  padding-top: 50px;
  padding-bottom: 50px;
}

.hotelResult {
  .sidebar {
    margin-top: 10px;
  }
}
// .sidebar {
//   padding: 0 10px;
// }
.children-ages {
  @media screen and (max-width: $medium_) {
    margin-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
    .form-select {
      width: auto;
    }
    .children-ages-label {
      display: block;
    }
  }
}
.children-ages-block {
  display: flex;
  .itours-select {
    border-radius: 0;
    &:first-child {
      body:not(.ltr) & {
        border-radius: 0 4px 4px 0;
      }
      body.ltr & {
        border-radius: 4px 0 0 4px;
      }
    }
    &:last-child {
      body:not(.ltr) & {
        border-radius: 4px 0 0 4px;
      }
      body.ltr & {
        border-radius: 0 4px 4px 0;
      }
    }
    &:last-child:first-child {
      .main-content & {
        border-radius: 4px;
      }
    }
  }
}
.datepicker-row {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

.small-title {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
}
.normal-title {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
}
.content-normal-title {
  color: white;
  font-weight: 400;
}
.contact img.icon {
  height: 25px;
  vertical-align: middle;
  margin-left: 10px;
  display: inline-block;
  &.fliped {
    @include transform(scaleX(-1));
  }
}
//rating component
.rateContainer {
  width: 110px;
  .rate {
    background-image: url("../image/star.svg");
    height: 18px;
    background-repeat: repeat-x;
  }
}
//select rooms ticks
.checkmark-holder {
  display: inline-block;
  vertical-align: middle;
  height: 50px;
  width: 50px;
  position: relative;
}
.success-checkmark {
  width: 80px;
  height: 80px;
  transform: scale(0.6);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
  .check-icon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid #4caf50;

    &::before {
      top: 3px;
      left: -2px;
      width: 30px;
      transform-origin: 100% 50%;
      border-radius: 100px 0 0 100px;
    }

    &::after {
      top: 0;
      left: 30px;
      width: 60px;
      transform-origin: 0 50%;
      border-radius: 0 100px 100px 0;
      animation: rotate-circle 4.25s ease-in;
    }

    &::before,
    &::after {
      content: "";
      height: 100px;
      position: absolute;
      background: #ffffff;
      transform: rotate(-45deg);
    }

    .icon-line {
      height: 5px;
      background-color: #4caf50;
      display: block;
      border-radius: 2px;
      position: absolute;
      z-index: 10;

      &.line-tip {
        top: 46px;
        left: 14px;
        width: 25px;
        transform: rotate(45deg);
        animation: icon-line-tip 0.75s;
      }

      &.line-long {
        top: 38px;
        right: 8px;
        width: 47px;
        transform: rotate(-45deg);
        animation: icon-line-long 0.75s;
      }
    }

    .icon-circle {
      top: -4px;
      left: -4px;
      z-index: 10;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      position: absolute;
      box-sizing: content-box;
      border: 4px solid rgba(76, 175, 80, 0.5);
    }

    .icon-fix {
      top: 8px;
      width: 5px;
      left: 26px;
      z-index: 1;
      height: 85px;
      position: absolute;
      transform: rotate(-45deg);
      background-color: #ffffff;
    }
  }
}
@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}
@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

//footer
.footer-logo {
  width: 150px;
}
@media screen and (min-width: $small) and (max-width: $medium_) {
  .quick-access-block {
    body.ltr & {
      border-right: 1px solid #ddd;
    }
    body:not(.ltr) & {
      border-left: 1px solid #ddd;
    }
    text-align: center;
  }
}

.footer-language {
  margin-top: 30px;
  .dropdown-title {
    line-height: 20px;
    margin-bottom: 10px;
  }
  .hover-dropdown-holder:hover .dropdown-title {
    color: #fff;
  }
  .max-height {
    max-height: 130px;
    overflow-y: auto;
  }
}
.footerblock-link {
  display: block;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
}
.footer {
  border-top: 2px solid #f1f1f1;
  background: #fff;
  color: #777;

  a {
    color: #777;
    text-decoration: none;

    &:hover {
      color: $blueLink;
    }
  }

  .newsletter-holder {
    border-bottom: 2px solid rgba(255, 255, 255, 0.3);
    padding: 30px 0;
  }
}
.top-footer {
  a {
    color: #777;
  }
}
.main-footer {
  padding: 30px 0;
}
.bottom-footer {
  padding: 20px 0;
  background: #f1f1f1;
}
.footer-title {
  font-size: 18px;
  font-weight: 600;
  color: #777;
  margin-top: 10px;
  margin-bottom: 20px;
}

.quick-access-block {
  @media screen and (max-width: $small_) {
    text-align: center;
    margin-bottom: 35px;
  }
}

.social-link {
  width: 24px;
  display: inline-block;
  cursor: pointer;
  &.disabled {
    cursor: no-drop;
  }
  img {
    width: 100%;
    vertical-align: middle;
  }
}
.social-links-holder {
  height: 24px;
}
.footer-bottom {
  background-color: $blueFooter;
  color: #fff;
}
.footer-menu-link {
  margin-left: 25px;
}
.footer-main .inline-icon {
  margin-right: 5px;
}
.namad-item {
  max-width: 100%;
  padding: 5px;
  height: 70px;
  display: inline-block;
  margin: 5px;
  background: #fff;
  border-radius: 4px;
  max-width: 140px;
  object-fit: contain;
  cursor: pointer;
}
//slider
.bannerInfo {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media screen and (max-width: $small_) {
    flex-direction: column;
    align-items: center;
    padding: 15px;
    padding-top: 15px;
  }

  .banner-item {
    width: 33.3%;
    position: relative;
    &:hover {
      .banner {
        background-size: 110% 110%;
      }

      strong {
        left: 30%;
      }
    }
    @media screen and (max-width: $small_) {
      margin: 0 0 25px;
      width: 100%;
    }
    div,
    strong {
      position: absolute;
      font-family: inherit;
      top: 60px;
      left: 20%;
      font-size: 38px;
      color: #ffffffd9;
      text-shadow: 0 0 8px black;
      transition: all 0.5s ease-out;
    }
    strong {
      top: 80px;
      font-size: 55px;
    }
    .banner {
      width: 100%;
      height: calc(50vh - 0px);
      background-color: black;
      display: block;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transition: all 0.3s ease-in;
      @media screen and (max-width: $small_) {
        width: 100%;
        height: 110vw;
        background-size: cover;
        background-position: center;
      }
    }
  }
}
.icon-list {
  padding: 0;
  list-style-type: none;
  margin: 0;
  > li {
    color: #70817c;
    position: relative;
    padding-right: 35px;
    margin-bottom: 10px;
    line-height: 35px;
    .item-icon {
      display: inline-block;
      width: 35px;
      position: absolute;
      right: 0;
      top: 0;
      & + span {
        line-height: 20px;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  &.reverse,
  &[dir="ltr"] {
    > li {
      padding-right: 0;
      padding-left: 45px;
      .item-icon {
        left: 0;
        right: auto;
      }
    }
  }
}
.no-list-style {
  padding: 0;
  li {
    list-style-type: none;
  }
}
.simple-bullet-list {
  padding: 0;
  list-style-position: inside;
  margin: 0;
  li.has-list-icon {
    list-style-type: none;
  }
}
.bullet-list {
  padding: 0;
  list-style-type: none;
  margin: 0;
  &.horizontal > li {
    display: inline-block;
    margin-inline-end: 15px;
    margin-bottom: 0;
  }
  > li {
    color: #70817c;
    position: relative;
    padding-right: 20px;
    margin-bottom: 15px;
    line-height: 20px;
    &::before {
      content: "";
      display: inline-block;
      background-color: $purple;
      width: 7px;
      height: 7px;
      @include border-radius(50%);
      position: absolute;
      right: 0;
      top: 5px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &.reverse,
  &[dir="ltr"],
  body.ltr & {
    > li {
      padding-right: 0;
      padding-left: 20px;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: auto;
      }
    }
  }
}
// end List

//about us
.aboutUS {
  background-color: #e9ecec;
  .containerText {
    @media screen and (max-width: 768px) {
      display: block;
      max-width: 100vw;
      margin: 0 0;
      text-align: center;
    }
    display: flex;
    max-width: 80vw;
    margin: 0 auto;
  }
  .image {
    padding-top: 5vh;
  }
  .content {
    padding: 6vh 30px;
    .text {
      text-align: justify;
      font-size: 18px;
    }
  }
}
//commercials
.commercial {
  padding: 20px 0;
  img.icon {
    height: 50px;
    display: inline-block;
  }
  .icon-label {
    font-weight: 600;
    margin-top: 6px;
    color: #bbb;
    font-size: 14px;
  }
}
.commercial-inner {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background: #fff;
}

//newsletter:
.newsletter-form {
  max-width: 400px;
  display: inline-block;
  width: 100%;
  margin-top: 10px;
  vertical-align: top;
}

.newsletter-section {
  padding: 20px 0 60px;
  .card {
    padding: 50px;
    border-radius: 7px;
  }
}

.inline-newsletter-form {
  text-align: center;
  display: flex;
  margin-bottom: 0;
  align-items: flex-start;
  .ant-form-item {
    flex: 1 1 100px;
  }
  .button {
    flex: 0 0 180px;
    margin-top: 0;
    height: 40px;
    @media screen and (max-width: $small_) {
      flex: 0 0 80px;
    }
    body:not(.ltr) & {
      margin-right: 15px;
    }
    body.ltr & {
      margin-left: 15px;
    }
  }
  .form-control {
    @include border-radius(3px);
  }
}
//dropdown
.dropdown-title {
  position: relative;
  z-index: 1;
}
.hover-dropdown-content {
  position: absolute;
  right: 0;
  min-width: 100px;
  top: 0;
  margin-top: 10px;
  z-index: 2;
  padding-top: 20px;
  display: none;
  cursor: pointer;
  .hover-dropdown-holder:hover & {
    display: block;
  }
  .dropdown-inner {
    cursor: default;
    padding-top: 5px;
    padding-bottom: 5px;
    @include border-radius(5px);
    background: #fff;
    position: relative;
    @include box-shadow($boxshadow1);
    &::before {
      content: "";
      display: block;
      width: 9px;
      height: 9px;
      background: #fff;
      @include transform(rotate(45deg));
      top: -6px;
      position: absolute;
      right: 10px;
      border-top: 1px solid #bbb;
      border-left: 1px solid #bbb;
    }
  }
}
.has-dropdown-icon {
  display: inline-block;
  &::after {
    content: "";
    display: inline-block;
    width: 5px;
    height: 5px;
    border-bottom: 1px solid;
    border-right: 1px solid;
    margin-right: 5px;
    @include transform(rotate(45deg));
    position: relative;
    top: -2px;
  }
}

.currency-link,
.lang-link {
  display: block;
  text-decoration: none !important;
  color: $blueLink;
  padding: 5px 10px;
  line-height: 1;
  position: relative;
  &:hover {
    background-color: $blueLink;
    color: #fff;
  }
}

.banner-slogan {
  color: $blueFooter;
  text-align: left;
  position: relative;
  font-weight: 600;
  text-shadow: 0 0 6px rgb(208, 169, 128);
  font-size: 30px;
  @media screen and (max-width: $medium_) {
    text-shadow: none;
    font-size: 20px;
  }
  .sub-slogan {
    margin-top: 25px;
    display: inline-block;
    font-size: 22px;
    @media screen and (max-width: $medium_) {
      font-size: 16px;
    }
  }
}
@media screen and (min-width: $medium) {
  .responsive-botton-holder,
  .page-container .filter-responsive-btn.button,
  .page-container .filter-close-btn.button {
    display: none;
  }
}
.body .filter-responsive-btn {
  position: fixed;
  bottom: 15px;
  right: 50%;
  width: 110px;
  margin-right: -50px;
  background: #fff;
  border: 1px solid #bbb;
  @include box-shadow(0 3px 10px 0 rgba(0, 0, 0, 0.2));
  @include border-radius(50px);
  color: rgb(2, 100, 122);
  font-weight: bold;
  @media screen and (min-width: $medium) {
    display: none;
  }
}
.filter-close-btn {
  display: none;
}
.ant-collapse-borderless {
  background-color: #fcfcfc;
}
.filter-header {
  font-size: 15px;
  color: #3a3a3a;
  display: block;
  border-bottom: 1px solid #dfdfdf;
  padding: 20px 10px 19px;
  .hotelResult & {
    padding-top: 2px;
  }
}
body:not(.ltr) .flight-avails-row .shamsi-date {
  line-height: 15px;
  margin-bottom: -5px;
  margin-top: 3px;
}
@media screen and (max-width: $medium_) {
  .filters-holder {
    position: fixed;
    top: 10px;
    right: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    background: #fff;
    z-index: 10000;
    padding: 50px 15px 15px;
    visibility: hidden;
    opacity: 0;
    transition: all .2s linear;
    &.showfilter {
      visibility: visible;
      opacity: 1;
      top: 0;
    }
  }
  .filter-close-btn {
    display: inline-block;
    position: fixed;
    z-index: 1;
    body:not(.ltr) & {
      left: 15px;
    }
    body.ltr & {
      right: 15px;
    }
    top: 15px;
  }
}
.time-filters {
  .custom-checkbox {
    &:checked {
      + .checkbox-label {
        border: 1px solid #02647a;
      }
    }
  }
  .itours-icon {
    display: inline-block;
    margin-bottom: 8px;
  }
}
.baggage-filter-wrapper{
  line-height: 22px;
  .checkbox-label::before{
    border-radius: 50%;
    width: 21px;
    height: 21px;
  }
  .ant-checkbox-wrapper-checked {
    .checkbox-label::before{
      background-image: url("../image/icons.svg");
      background-size: 3200% 600%;
      background-position: 54.8% 0;
    }
  }
  background-color: #ffb711;
  padding-top: 5px;
  margin-bottom: 10px;
  border-radius: 5px;

  .ant-checkbox-group{
    padding: 0 15px 15px;
  }
}

.baggage-filter-item-inner {
  background: #fff;
  display: block;
  border-radius: 5px;
  position: relative;
  font-size: 12px;
  padding: 10px;
  .ant-checkbox{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    + span{
      display: block;
      padding: 0;
    }
  }
}

// Checkbox
//
// Add .checkbox-holder class to checkbox container
// Add .ltr class to .checkbox-holder for ltr items
// Add .custom-checkbox class to input[type='checkbox']
// Add .checkbox-label class to checkbox label
//
// Markup:
// <div className="checkbox-holder ltr">
//    <input className="custom-checkbox"  type="checkbox" />
//    <label className="checkbox-label"> ... </label>
// </div>
//
// Styleguide Components.Checkbox
.checkbox-holder {
  position: relative;
  font-size: 12px;
  .custom-checkbox {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1;
    opacity: 0;
    margin: 0;
    cursor: pointer;
    &:checked + .checkbox-label {
      /*       background-color: #45c5d0;
      color: #fff; */
      &::before {
        background-image: url("../image/icons.svg");
        background-size: 3200% 600%;
        background-position: 54.8% 0;
      }
    }
  }

  .form-group .form-label {
    font-size: 13px;
  }
  &.has-link .custom-checkbox {
    width: 35px;
    body:not(.ltr) & {
      left: auto;
      right: 0;
    }
    body.ltr & {
      left: 0;
      right: auto;
    }
  }
}
.containerFlex {
  display: flex;
  margin-left: -5px;
  margin-right: -5px;
  .checkbox-holder {
    width: 33.3%;
    text-align: center;
    padding: 0 5px;
  }
  .checkbox-label {
    line-height: 12px;
    padding: 10px 0;
    color: #02647a;
    &::before {
      display: none;
    }
    img {
      width: 15px;
      padding-bottom: 4px;
      margin-bottom: 6px;
    }
    div {
      display: block;
    }
    &::before {
      background-image: none;
    }
  }
}
.checkbox-label {
  position: relative;
  margin: 0;
  line-height: 30px;
  padding: 0;
  display: block;
  margin-bottom: 2px;
  > div {
    display: inline-block;
    vertical-align: middle;
  }
  &::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 18px;
    height: 18px;
    border: 1px solid rgb(189, 189, 189);
    border-radius: 2px;
    body:not(.ltr) & {
      margin-left: 5px;
    }
    body.ltr & {
      margin-right: 5px;
    }
  }
}
.ltr.checkbox-holder {
  direction: ltr;
  .checkbox-label::before {
    margin-left: 0;
    margin-right: 5px;
  }
}

// Styleguide Components.Checkbox
.radio-item {
  position: relative;
  &.separated{
    &:not(:last-child){
      border-bottom:1px solid #ddd;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
  }
  .inline-radio & {
    display: inline-block;
    body:not(.ltr) & {
      margin-left: 20px;
    }
    body.ltr & {
      margin-right: 20px;
    }

    @media screen and (max-width: $small_) {
      body:not(.ltr) & {
        margin-left: 10px;
      }
      body.ltr & {
        margin-right: 10px;
      }
    }
  }

  .custom-radio {
    position: absolute;
    top: 0;
    margin: 0;
    body:not(.ltr) & {
      right: 0;
    }
    body.ltr & {
      left: 0;
    }
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    z-index: 1;

    &:checked {
      & + .radio-label .radio-mark:before {
        background-color: #02647a;
      }
    }
  }

  .radio-label {
    line-height: 30px;
    font-weight: normal;
    .radio-mark {
      border: 2px solid #02647a;
      @include border-radius(50%);
      position: relative;
      width: 16px;
      height: 16px;
      display: inline-block;

      body:not(.ltr) & {
        margin-left: 5px;
      }
      body.ltr & {
        margin-right: 5px;
      }
      vertical-align: middle;

      &::before {
        content: "";
        display: block;
        position: absolute;
        background: transparent;
        right: 2px;
        left: 2px;
        top: 2px;
        bottom: 2px;
        border-radius: 50%;
      }
    }
  }
}

//badge
.yellow-badge {
  display: inline-block;
  font-size: 13px;
  margin: 0 5px;
  background-color: $yellow;
  color: #fff;
  padding: 1px 7px;
  line-height: 16px;
  vertical-align: middle;
}

//flight search results:
.included-baggage-mark {
  white-space: nowrap;
  line-height: 15px;
  border-radius: 30px;
  padding: 1px 5px;
  color: #4caf50;
  font-size: 13px;
  background: #ffffff;
  display: inline-block;
  margin: 0 10px;
  height: 22px;
  border: 1px solid;
  position: absolute;
  top: -11px;
  
  .body:not(.ltr) & {
    left: 15px;
  }
  .body.ltr & {
    right: 15px;
  }
  box-shadow: 1px 1px 3px 0 #00000045;

  span {
    display: inline-block;
    vertical-align: middle;
  }
}

.flight-result-item {
  transition: all 3s linear;
  background-color: #fff;
  .selected-package &{
    background-color: transparent;
  }
  color: #999;
  //  padding: 6px;
  margin-bottom: 15px;
  line-height: 20px;
  // @media screen and (min-width: $medium) {
  //   display: table;
  // }
  width: 100%;
  border: 1px solid $border-color;
  @media screen and (max-width: $medium_) {
    .item-footer {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .flight-price-block {
        float: none !important;
        .price {
          .body:not(.ltr) & {
            text-align: right;
          }
          .body.ltr & {
            text-align: left;
          }
        }
      }
    }
  }
  @include transition(all 0.2s);
  .item-main {
    // @media screen and (min-width: $medium) {
    //   display: table-cell;
    // }
    .content {
      position: relative;
    }
    vertical-align: middle;
    @media screen and (max-width: $medium_) {
      .content {
        .body & {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
      .detail-btn-holder {
        margin-bottom: 10px;
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
      }
      .air-line-info {
        width: 60px;
        .airlineInfo {
          display: none;
        }
      }
      .route-item .router .time {
        font-size: 14px;
      }
    }
    @media screen and (max-width: $small_){
      .content {
        .body & {
          padding-left: 5px;
          padding-right: 5px;
        }
      }
      .air-line-info {
        width: 40px;
      }
    }
    .content {
      width: 100%;
      padding: 10px 15px;
    }
    .details {
      text-align: center;
      width: 15%;
      button.hotelbook {
        margin-top: 62px;
        background-color: transparent;
        color: $blueLink;
      }
    }
  }
  .item-footer {
    padding-top: 10px;
    padding: 10px 15px;
    border-top: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .flight-price-block {
      text-align: center;
      /*       &+.button{
        padding-left: 5px;
        padding-right: 15px;
      } */
      .price {
        // display: flex;
        // flex-direction: column-reverse;

        // body:not(.ltr) & {
        //   text-align: left;
        // }
        // body.ltr & {
        //   text-align: right;
        // }
        .currency {
          font-size: 14px;
          font-weight: normal;
        }
      }
    }

    
    @media screen and (min-width: $medium) {
      .select-flight-btn {
        min-width: 122px;
      }
      // width: 130px;
      // display: table-cell;
      // vertical-align: bottom;

      // body:not(.ltr) & {
      //   border-right: 1px dashed #ddd;
      //   padding-left: 10px;
      // }
      // body.ltr & {
      //   border-left: 1px dashed #ddd;
      //   padding-right: 10px;
      // }
      .flight-rules-block {
        text-align: center;
      }
    }
    @media screen and (max-width: $medium_) {
      border-top: 8px solid #eaeaea;
      .flight-price-block {
        body:not(.ltr) & {
          float: left;
          text-align: left;
        }
        body.ltr & {
          float: right;
          text-align: right;
        }
      }
    }
    .button {
      max-width: 100%;
    }
    @media screen and (max-width: $small_) {
      &.multiple_rows{
        display: block;
      }
    }
  }
}
.light-clock-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background-position: -100px -100px;
}
.airlinename {
  line-height: 1.1;
  margin: 3px 0;
  &.operating-airline{
    color: #bbb;
  }
}
.route-item {
  .included-baggage-mark + &{
    margin-top: 10px;
  }
  color: #3a3a3a;
  .route-item-flex {
    &:not(:first-child){
      margin-top: 5px;
    }
    min-height: 70px;
    display: table;
    width: 100%;
    & > div {
      display: table-cell;
      vertical-align: middle;
    }
  }
  .baggage-column{
    text-align: center;   
    font-size: 14px;
    margin-bottom: 1rem;
    // body:not(.ltr) & {
    //   padding-right:15px;
    // }
    // body.ltr & {
    //   padding-left:15px;
    // }
  }
  .route-and-baggage-row {
    @media screen and (min-width: $medium){
      display: flex;
      align-items: center;
      .baggage-column{
        margin-bottom: 0;
        width: 160px;
        .green-baggage{
          display: none;
        }
        .red-no-baggage{
          width: 26px;
          height: 26px;
        }
      }
    }
  }
  .air-line-info {
    font-size: 13px;
    width: 130px;
    .air-line-info-inner{
      display: flex;
    }
    @media screen and (min-width: $large) {
      width: 195px;
    }
    // .airlineInfo,
    // .airline-logo-holder {
    //   display: inline-block;
    //   vertical-align: middle;
    // }

    .flightAside & {
      width: 60px;
    }
    .airline-logo-holder {
      body:not(.ltr) & {
        margin-left: 10px;
        @media screen and (max-width: $small_) {
          margin-left: 5px;
        }
      }
      body.ltr & {
        margin-right: 10px;
        @media screen and (max-width: $small_) {
          margin-right: 5px;
        }
      }
      .airline-logo {
        height: 50px;
        width: 50px;
        @media screen and (max-width: $small_) {
          height: 35px;
          width: 35px;
        }
        object-fit: contain;
        line-height: 1;
        color: #bbb;
      }
    }
  }
  .summery-airline-logo {
    flex: 0 0 70px;
    @media screen and (max-width: $small_){
      flex: 0 0 50px;
    }
    margin-left: 10px;
    .airline-logo {
      width: 60px;
      line-height: 1;
      font-size: 10px;
      color: #bbb;
      @media screen and (max-width: $small_){
        width: 40px;
      }
    }
  }
  .router {
    display: table;
    width: 100%;
    padding: 5px;
    & > div {
      display: table-cell;
      vertical-align: middle;
    }
    .time {
      font-size: 18px;
      margin-bottom: 2px;
    }
    .date {
      margin-bottom: 2px;
    }
    .codeCity {
      font-size: 14px;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid #9e9e9e;
  }

  .destinationInfo,
  .originInfo {
    min-width: 50px;
  }
  .stop-info {
    flex: 1 1 250px;
    font-size: 12px;
    .duration {
      color: #777;
      font-size: 13px;
      body:not(.ltr) & {
        padding-left: 15px;
      }
      body.ltr & {
        padding-right: 15px;
      }
    }
    .stop {
      font-weight: 600;

      body:not(.ltr) & {
        padding-left: 15px;
      }
      body.ltr & {
        padding-right: 15px;
      }
      color: #777;
    }
    .dashed-line {
      position: relative;

      body:not(.ltr) & {
        padding-left: 30px;
      }
      body.ltr & {
        padding-right: 30px;
      }
      margin: 5px 0;
      &::before {
        content: "";
        display: block;
        border-bottom: 1px dashed $border-color;
      }
      img {
        width: 20px;
        position: absolute;

        top: -10px;
        opacity: 0.4;
        body:not(.ltr) & {
          left: 0;
          @include transform(rotate(180deg));
        }
        body.ltr & {
          right: 0;
        }
      }
    }
  }
}
.flight-detail-btn {
  font-size: 12px;
  font-weight: 600;
  span {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    @include transition(all 0.3s linear);
    vertical-align: middle;
    @include transform(rotate(45deg));

    body:not(.ltr) & {
      margin-right: 5px;
    }
    body.ltr & {
      margin-left: 5px;
    }
    margin-top: -2px;
    &.rot {
      @include transform(rotate(225deg));
      margin-top: 2px;
    }
  }
}
.flight-avails-row {
  padding-bottom: 50px;
  padding-top: 15px;
}
.has-airport-change {
  margin-top: 5px;
}
.container .flightDetails {
  @media screen and (min-width: $medium){
  .detail-flight .detail-flight-item {
      &:not(:last-child) {
        border-bottom: 5px solid #bbb;
        margin-bottom: 15px;
      }
    }
  }

  @media screen and (max-width: $medium_) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 101;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0 !important;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .flight-detail-footer {
      .button {
        margin: 0 7px;
      }
    }
    .detail-flight .detail-flight-item {
      margin-bottom: 0;
      &:not(:last-child) {
        border-bottom: 10px solid #ddd;
        padding-bottom: 20px;
      }
    }
    .detail-route-item {
      flex-direction: column;

      body:not(.ltr) & {
        padding: 15px 70px 0 0;
      }
      body.ltr & {
        padding: 15px 0 0 70px;
      }
      margin: 0;
      position: relative;
      .origin-info {
        position: relative;
        order: 1;
        -webkit-order: 1;
        display: flex;
        flex-direction: column;
        margin-bottom: 0;

        body:not(.ltr) & {
          padding-right: 15px;
        }
        body.ltr & {
          padding-left: 15px;
        }

        padding-bottom: 15px;
        &::before {
          content: "";
          display: block;
          position: absolute;
          width: 1px;

          body:not(.ltr) & {
            right: 2px;
          }
          body.ltr & {
            left: 2px;
          }
          bottom: 0;
          top: 22px;
          background: #bbb;
        }
        > * {
          order: 2;
          -webkit-order: 2;
          &.airport-name {
            order: 1;
            -webkit-order: 1;
            font-size: 14px;
            color: #111;
          }
        }
      }
      .duration-info {
        position: absolute;
        body:not(.ltr) & {
          right: 0;
        }
        body.ltr & {
          left: 0;
        }
        width: 60px;
        margin: -12px 0 0 0;
        padding: 0;
        body:not(.ltr) & {
          text-align: left;
        }
        body.ltr & {
          text-align: right;
        }
        color: #aaa;
        top: 50%;
        b {
          font-weight: normal;
        }
        & > span {
          display: none;
        }
      }
      .airline-info {
        order: 2;
        -webkit-order: 2;
        position: relative;
        margin-bottom: 0;
        padding-bottom: 20px;
        &::before {
          content: "";
          display: block;
          position: absolute;
          width: 1px;

          body:not(.ltr) & {
            right: 2px;
          }
          body.ltr & {
            left: 2px;
          }
          top: 0;
          bottom: 3px;
          background: #bbb;
        }
      }
      .destination-info {
        position: relative;
        order: 3;
        -webkit-order: 3;

        body:not(.ltr) & {
          padding-right: 15px;
        }
        body.ltr & {
          padding-left: 15px;
        }
        flex-direction: column;
        margin-bottom: 0;
        > * {
          order: 2;
          -webkit-order: 2;
          &.airport-name {
            order: 1;
            -webkit-order: 1;
            font-size: 14px;
            color: #111;
          }
        }
      }
      .takeoff-time,
      .landing-time {
        position: absolute;

        body:not(.ltr) & {
          right: -70px;
          text-align: left;
        }
        body.ltr & {
          left: -70px;
          text-align: right;
        }
        top: 0;
        width: 60px;
        &::after {
          content: "";
          width: 15px;
          height: 15px;
          border: 2px solid #bbb;
          display: block;
          position: absolute;
          border-radius: 50%;
          top: 3px;

          body:not(.ltr) & {
            left: -20px;
          }
          body.ltr & {
            right: -20px;
          }
        }
      }
      .landing-date,
      .takeoff-date {
        position: absolute;

        body:not(.ltr) & {
          right: -70px;
          text-align: left;
        }
        body.ltr & {
          left: -70px;
          text-align: right;
        }
        width: 60px;
        font-size: 11px;
        top: 20px;
        color: #aaa;
        font-weight: normal;
      }
      .duration-info {
        flex: 0 0;
      }
      & > div {
        margin-bottom: 10px;
        line-height: 22px;
      }
      .terminal-id {
        display: block;
        margin: 0;
      }
    }
    .route-header {
      & > div {
        float: none;
      }
    }
    .stop-info {
      body:not(.ltr) & {
        text-align: right;
        margin-right: 85px;
      }
      body.ltr & {
        text-align: left;
        margin-left: 85px;
      }
      margin-top: 20px;
      margin-bottom: 20px;
      position: relative;
      font-size: 12px;
      padding: 10px;
      .stop-top-value {
        position: absolute;

        body:not(.ltr) & {
          right: -80px;
          text-align: left;
        }
        body.ltr & {
          left: -80px;
          text-align: right;
        }

        width: 60px;
        font-weight: normal;
        color: #aaa;
        font-size: 12px;
      }
      &::after {
        content: "";
        display: block;
        position: absolute;
        body:not(.ltr) & {
          right: -13px;
          border-right: 1px dashed #bbb;
        }
        body.ltr & {
          left: -13px;
          border-left: 1px dashed #bbb;
        }

        top: -25px;
        bottom: -25px;
      }
    }
  }

  // margin-top: -11px;
  //  border-top: 1px solid #ccc;
  .flight-detail-main {
    .itours-tab-holder {
      border: 0 none;
      .itours-tab-list {
        background: #696969;
        margin: 0;
        padding: 0 15px;
        @media screen and (max-width: $medium_) {
          padding: 0;
          white-space: nowrap;
          overflow: auto;
        }
        .itours-tab-btn{
          margin: 0;
          border-radius: 0;
          box-shadow: none;
          border: 0 none;
          outline: none;
          background-color: transparent;
          color: #fff;
          padding: 15px;
          font-size: 14px;
          @media screen and (max-width: $medium_) {
            padding: 8px 5px;
            font-size: 13px;
          }
          &:hover{
            background-color: #ffffff3d;
          }
          &.active{
            background-color:#ffffffb0;
            color: #000;
          }
        }
      }  
    }  
    @media screen and (max-width: $medium_) {
      padding-bottom: 90px;
    }
  }
  .flight-detail-footer {
    @media screen and (max-width: $medium_) {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      border-right: 0 none;
      border-left: 0 none;
      border-bottom: 0 none;
    }
    .button {
      flex: 0 1 209px;
    }
    .border-btn {
      border: 1px solid $blueLink;
      color: $blueLink;
    }
    padding: 15px;
    display: flex;
    justify-content: space-between;
    background: #fff;
    z-index: 1;
    position: relative;
    border: 1px solid $border-color;
    border-top-color: rgb(228, 232, 237);
    margin-top: -8px;
  }
  .ant-tabs-tabpane {
    padding: 15px;
    background-color: #fff;
    border: 1px solid $border-color;
    @media (max-width: $small_) {
      border: 0 none;
    }
    border-top: 0 none;
  }
  .ant-tabs {
    font-size: 15px;
  }
  .baggegesInformation b {
    font-size: 16px;
  }
}
.route-info {
  display: inline-block;
  width: calc(100% - 120px);
  vertical-align: top;
  @media screen and (max-width: $medium_) {
    width: calc(100% - 70px);
  }
}
.sidebar-item {
  padding: 15px 0;
  &:not(:last-of-type){
    border-bottom: 1px solid #dfdfdf;
  }
}
@media screen and (min-width: $medium) {
  .flight-time-group {
    display: flex;
  }
}
.flight-time-item {
  position: relative;
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
  @media screen and (min-width: $medium) {
    flex: 1 1 90px;
    margin-bottom: 0;
    &.time {
      flex: 1 1 150px;
    }
    &:not(:first-child) {
      margin-right: 5px;
    }
    &:not(:last-child) {
      margin-left: 5px;
    }

    padding-left: 25px;
  }
  .icon {
    position: absolute;
    left: 0;
    top: 0;
    @media screen and (max-width: $medium_) {
      display: none;
    }
  }
}

.results-number-holder {
  line-height: 34px;
}

.sort-next-prev-block {
  display: flex;
  justify-content: space-between;
  padding: 12px 2px 10px;
  flex-wrap: wrap;
}

.results-sorting-block {
  margin-bottom: 2px;
  flex-direction: row-reverse;
  padding: 12px 2px 5px 2px;
  @media screen and (max-width: $small_) {
    padding: 0 10px 5px;
  }
}
.body select.results-sort-select {
  height: 30px;
  display: inline-block;
  width: auto;
  outline: none;
  border: 1px solid #22a6bb;
  color: #22a6bb;
  font-weight: 600;
  cursor: pointer;
}

.button.results-sort-btn {
  .hotelResult & {
    border: 1px solid rgb(35, 165, 185);
    background-color: rgb(223, 233, 235);
    box-shadow: rgba(2, 100, 122, 0.1) 0px 1px 8px 0px,
      rgba(0, 0, 0, 0.1) 0px 1px 8px 0px;
    height: 38px;
    border-radius: 2px;
    min-width: 193px;
    &.domestic-hotel-sort-btn{
      min-width: 110px;
    }
    @media screen and (max-width: $medium_) {
      min-width: 0;
    }
    &.disable {
      background-color: transparent;
      border: 1px solid transparent;
    }
    &::after {
      body:not(.ltr) & {
        left: 10px;
      }
      body.ltr & {
        right: 10px;
      }
      top: 12px;
    }
    &.low::after {
      top: 15px;
    }
    body:not(.ltr) & {
      margin-left: 5px;
      margin-right: 0;
      padding: 0 10px 0 25px;
      &:last-child {
        margin-left: 0;
      }
    }
    body.ltr & {
      margin-right: 5px;
      margin-left: 0;
      padding: 0 25px 0 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  background-color: transparent;
  font-weight: 600;
  border-radius: 0;
  color: #22a6bb;
  font-size: 14px;
  line-height: 27px;
  position: relative;
  margin-bottom: 5px;
  min-width: 0;
  body:not(.ltr) & {
    margin-left: 15px;
    margin-right: 0;
    padding: 0 0 0 15px;
    &:last-child {
      margin-left: 0;
    }
  }
  body.ltr & {
    margin-right: 15px;
    margin-left: 0;
    padding: 0 15px 0 0;
    &:last-child {
      margin-right: 0;
    }
  }
  /*   &::before{
    content: "";
    display: block;
    position: absolute;
    body:not(.ltr) &{
      left: 0;
    }
    body.ltr &{
      right: 0;
    }
    top: 0;
    background-color: transparent;
    width: 25px;
    height: 25px;
  } */
  &::after {
    content: "";
    display: block;
    position: absolute;
    body:not(.ltr) & {
      left: 0;
    }
    body.ltr & {
      right: 0;
    }
    top: 7px;
    width: 8px;
    height: 8px;
    border-top: 2px solid;
    border-right: 2px solid;
    @include transform(rotate(135deg));
  }
  &.low::after {
    top: 10px;
    @include transform(rotate(-45deg));
  }
}

//hotel avail styles:
.hotel-image {
  display: block;
  flex: 1 0 200px;
  @media (max-width: $medium_) {
    flex: 1 0 100px;
  }
  background-size: cover;
  &.default-image {
    background-size: 100px;
    background-repeat: no-repeat;
  }
  background-position: center;
}
.hotel-result-item {
  .hotel-image {
    margin: 3px;
  }
  .price-for-night {
    line-height: 20px;
  }
  .price {
    color: rgb(58, 58, 58);
    font-size: 18px;
    font-weight: 600;
  }
  .old-price {
    text-decoration: none;
    .price span {
      color: rgb(119, 119, 119);
      font-size: 14px;
    }
  }
  .hotel-name {
    color: rgb(58, 58, 58);
    font-weight: 600;
    font-size: 16px;
    vertical-align: middle;
  }
  background-color: #fff;
  @include transition(all 0.2s);
  @extend .clearfix;
  display: flex;
  @media (max-width: $small_) {
    display: block;
    margin: 0;
    border: 0 none;
    border-top: 20px solid #22222b;
    .hotel-image {
      height: 150px;
    }
  }
  margin-bottom: 15px;
  border: 1px solid #e5e5e5;
}
.hotel-content {
  width: 100%;
  padding: 15px;
  min-height: 160px;
  display: flex;
  @media (max-width: $small_) {
    display: block;
    min-height: 0;
  }

  .hotel-footer {
    flex: 1 0 190px;
    @media (max-width: $medium_) {
      flex: 1 0 140px;
    }
    @media (max-width: $small_) {
      border-top: 8px solid #eaeaea;
      padding-top: 10px;
      margin-top: 15px;
    }
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @media (min-width: $small) {
      body:not(.ltr) & {
        border-right: 1px solid #ddd;
        margin-right: 15px;
        padding-right: 15px;
      }
      body.ltr & {
        border-left: 1px solid #ddd;
        margin-left: 15px;
        padding-left: 15px;
      }
    }
  }
  .hotel-details {
    width: 100%;
  }
  .location-icon {
    vertical-align: middle;
  }
  .rating {
    vertical-align: middle;
  }
  .feature-list {
    margin-top: 5px;
    li {
      margin-bottom: 5px;
    }
  }
}

//results grid
$resultGridGap: 15px;
.result-page-search-bar .search-btn-holder {
  .hotelResult & {
    margin-top: 25px;
  }
  @media (min-width: $medium) {
    margin-top: 25px;
  }
  .button {
    @media (min-width: $small) {
      max-width: 100%;
    }
  }
}
.search-btn-holder {
  margin-top: 5px;
  .button {
    @media (min-width: $small) {
      max-width: 41.667%;
    }
    @media (min-width: $medium) {
      max-width: calc(33.33% - 8px);
    }
  }
}
.hotel-detail-searchholder
  .form-group
  .autocompleteFeild
  .autocompleteText
  input {
  background-image: none;
  padding: 0 5px;
}
.result-page-search-bar {
  .ant-calendar-picker .anticon.ant-calendar-picker-icon,
  .ant-calendar-picker .anticon.ant-calendar-picker-clear {
    display: none;
  }
  .form-group .ant-input {
    padding: 0 5px;
  }
  .itours-select {
    padding-left: 10px;
    padding-right: 10px;
  }
  .avail-row-grid {
    &::after,
    &::before {
      content: "";
      clear: both;
      display: block;
    }
    margin-left: -$resultGridGap/2;
    margin-right: -$resultGridGap/2;
    &.no-gap {
      margin-left: 0;
      margin-right: 0;
      [class*="avail-column-"] {
        padding-right: 0;
        padding-left: 0;
      }
    }
    [class*="avail-column-"] {
      body:not(.ltr) & {
        float: right;
      }
      body.ltr & {
        float: left;
      }
      box-sizing: border-box;
      padding-right: $resultGridGap/2;
      padding-left: $resultGridGap/2;
    }
  }
  @for $i from 1 through 20 {
    .avail-column-small-#{$i} {
      width: 100% * $i / 20;
    }
  }
  @media (min-width: $medium) {
    @for $i from 1 through 20 {
      .avail-column-medium-#{$i} {
        width: 100% * $i / 20;
      }
    }
  }
  @media (min-width: $large) {
    @for $i from 1 through 20 {
      .avail-column-large-#{$i} {
        width: 100% * $i / 20;
      }
    }
  }
  @media (max-width: $small_) {
    @for $i from 1 through 20 {
      .avail-column-xsmall-#{$i} {
        width: 100% * $i / 20;
      }
    }
  }
}

.flex-ends {
  display: flex;
  justify-content: space-between;
  &.middle{
    align-items: center;
  }
}
.flex-ends-medium{
  @media  (min-width:$medium) {
    display: flex;
    justify-content: space-between;
    &.middle{
      align-items: center;
    }
  }
}
.margin-end {
  body:not(.ltr) & {
    margin-left: 20px;
  }
  body.ltr & {
    margin-right: 20px;
  }
}
.margin-end-half {
  body:not(.ltr) & {
    margin-left: 10px;
  }
  body.ltr & {
    margin-right: 10px;
  }
}
.margin-end-light {
  body:not(.ltr) & {
    margin-left: 5px;
  }
  body.ltr & {
    margin-right: 5px;
  }
}

//flight details:
.detail-flight {
  img.airline {
    width: 50px;
    margin-top: 4px;

    body:not(.ltr) & {
      margin-left: 10px;
    }
    body.ltr & {
      margin-right: 10px;
    }
  }
  .direction-arrow {
    margin: 0 5px;
    background-position: -576px -127px;
    width: 25px;
    height: 25px;
    display: inline-block;
    vertical-align: middle;
  }
  .detail-flight-item:not(:first-child) {
    @media (max-width: $medium_) {
      margin-top: 20px;
    }
  }

  .route-header {
    border-bottom: 1px solid #e4e8ed;
    font-size: 16px;
    padding-bottom: 15px;
    b {
      font-size: 16px;
    }
  }
  .detail-route-item {
    line-height: 25px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 16px;
    padding-bottom: 25px;
    padding-top: 20px;
    margin-left: -5px;
    margin-right: -5px;
    & > div {
      flex: 1 1;
      padding-left: 5px;
      padding-right: 5px;
    }
    .duration-info {
      flex: 0 0 70px;
    }
    .airline-info {
      display: flex;
      align-items: center;
    }
    .semi-bold {
      font-weight: 600;
    }
  }
  .stop-info {
    background-color: #fff5e3;
    padding: 10px 15px;
    font-size: 14px;
    line-height: 15px;
    b {
      font-weight: 600;
    }
    &.change-airport {
      background-color: $purple;
      color: #fff;
    }
  }
  .card-title {
    padding: 8px 15px;
    background-color: #002142;
    display: block;
    color: #fff;
  }
}
.rules-item {
  line-height: 22px;
  text-transform: lowercase;
  direction: ltr;
  text-align: justify;
  padding: 10px 0;
  .rule-body {
    padding-left: 20px;
    b:first-child:last-child {
      color: $blueLink;
      margin-bottom: 5px;
      display: block;
    }
  }
  .rule-title {
    color: $purple;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
  }
}

//flight getpassenger style:

.adult-icon {
  width: 35px;
  height: 35px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  background-position: 0px -175px;
}
.child-icon {
  width: 35px;
  height: 35px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  background-position: -35px -175px;
}
.infant-icon {
  width: 35px;
  height: 35px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  background-position: -70px -175px;
}
.passengers-informations {
  .login-invite {
    padding: 8px 20px;
    border-bottom: 1px solid $border-color;
    strong {
      line-height: 24px;
      font-weight: 600;
      display: block;
      font-size: 16px;
      color: rgb(31, 37, 41);
    }
    .content {
      line-height: 21px;
    }
  }
  margin-bottom: 20px;
}
.get-passenger-form-holder {
  .RoomItem {
    border-top: 1px solid $border-color;
  }
  &.package .RoomItem:first-child{
    border-top: 0 none;
  }
  .title {
    font-size: 20px;
    line-height: 30px;
    color: rgb(58, 58, 58);
    font-weight: 600;
    margin-bottom: 20px;
  }
  .passenger-item {
    .passenger-title {
      font-size: 16px;
      font-weight: bold;
      color: rgb(58, 58, 58);
    }
    &:not(:first-child) {
      border-top: 1px solid #bbb;
      padding-top: 20px;
    }
  }
  .form-label {
    font-size: 14px;
    font-weight: 600;
    color: rgb(58, 58, 58);
    margin-bottom: 8px;
    line-height: 21px;
  }
}
.icon-hint-holder {
  margin-bottom: 20px;
  .icon {
    vertical-align: middle;
    display: inline-block;

    body:not(.ltr) & {
      margin-left: 6px;
    }
    body.ltr & {
      margin-right: 6px;
    }
    width: 29px;
  }
}
.hint-inner {
  color: rgb(236, 152, 68);
  width: calc(100% - 35px);
  background: #fff9f0;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  display: inline-block;
  vertical-align: middle;
}
.main-content,.accountWindow {
  .select-input-pair-holder {
    display: flex;
    .form-select {
      flex: 1 0 33.3%;
      &.currancy-select{
        flex: 0 0 100px;
      }

      body:not(.ltr) & {
        border-left: 0 none;
        @include border-radius(0 2px 2px 0);
        &:not([dir="ltr"]){
          padding-left: 25px;
        }
      }
      body.ltr & {
        border-right: 0 none;
        @include border-radius(2px 0 0 2px);
        padding-right: 25px;
      }
    }
    .form-input {
      flex: 1 1 250px;

      body:not(.ltr) & {
        @include border-radius(2px 0 0 2px);
      }
      body.ltr & {
        @include border-radius(0 2px 2px 0);
      }
    }
    &[dir="ltr"] {
      .form-select {
        .body & {
          border-right: 0 none;
          @include border-radius(2px 0 0 2px);
          border-left: 1px solid $border-color;
        }
      }
      .form-input {
        .body & {
          @include border-radius(0 2px 2px 0);
        }
      }
    }
  }
}
@media screen and (max-width: $small_) {
  .flight.getpassengers .box-border {
    border: 0 none;
    margin: 0 !important;
  }
  .ticket-style-holder {
    .flight.getpassengers & {
      margin: 10px;
      .hr_padding {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}

.DatePickerDrop {
  select {
    outline: none !important;
    max-width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    height: 40px;
    background-color: #fff;
    &::-ms-expand {
      display: none;
    }
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url("../image/down-caret.svg");
    background-repeat: no-repeat;
    background-size: 15px;
    @media screen and (max-width: $small_) {
      background-size: 12px;
    }
    body:not(.ltr) & {
      background-position: left 0 center;
      padding-left: 15px;
      padding-right: 10px;
      @media screen and (max-width: $small_) {
        padding-left: 12px;
        padding-right: 8px;
      }
    }
    body.ltr & {
      background-position: right 0 center;
      padding-right: 15px;
      padding-left: 10px;
      @media screen and (max-width: $small_) {
        padding-left: 8px;
        padding-right: 12px;
      }
    }
    body.ltr &:first-child,
    body:not(.ltr) &:last-of-type {
      @include border-radius(2px 0 0 2px);
      border-right: 0 none;
    }
    body.ltr &:last-of-type,
    body:not(.ltr) &:first-child {
      @include border-radius(0 2px 2px 0);
      border-left: 0 none;
    }
  }
}
.selected-flights-info {
  .flights-row {
    display: flex;
    justify-content: space-between;
    background: #fff;
    align-items: center;
    padding: 5px 15px;
    .origins .icon {
      body:not(.ltr) & {
        @include transform(scale(-1, 1));
      }
    }
    @media (max-width: $medium_) {
      display: block;
      .origins {
        & > div {
          display: inline-block;
          &:first-child {
            body:not(.ltr) & {
              margin-left: 20px;
            }
            body.ltr & {
              margin-right: 20px;
            }
          }
        }
      }
      .dates {
        & > div {
          display: inline-block;
          &:first-child {
            &::after {
              content: "";
              display: inline-block;
              width: 20px;
            }
            body:not(.ltr) & {
              margin-left: 20px;
            }
            body.ltr & {
              margin-right: 20px;
            }
          }
        }
      }
      .airline-name {
        display: inline-block;
        vertical-align: middle;
      }
      .airline-logo {
        display: inline-block;
        vertical-align: middle;
        height: 35px;
      }
    }
    .icon {
      width: 22px;
    }
    &:not(:last-child) {
      border-bottom: 1px solid #ddd;
    }
    .title {
      flex: 1 0 80px;
    }
    .origins {
      flex: 1 0 200px;
    }
    .dates {
      flex: 1 0 140px;
    }
    .airline-name {
      flex: 1 0 150px;
    }
    .class-cabin {
      flex: 1 0 100px;
    }
  }
}
.accordion-content {
  padding: 5px 15px;
  background-color: #fff;
  border-top: 1px solid $border-color;
}
.accordion-toggle {
  padding: 5px 15px;
  position: relative;
  background-color: #fff;
  cursor: pointer;
  .close-icon {
    position: absolute;
    body:not(.ltr) & {
      left: 0;
    }
    body.ltr & {
      right: 0;
    }
    top: 0;
    width: 40px;
    height: 40px;
    @include transition(all 0.3s);
    @include transform(rotate(180deg));
    background-image: url("../image/down-caret.svg");
    background-position: center;
    background-size: 30px;
    background-repeat: no-repeat;
  }
  &.collapsed .close-icon {
    @include transform(rotate(0));
  }
}
.itours-table-holder.responsive-table {
  overflow-x: auto;
}
.itours-table {
  color: initial;
  border-collapse: collapse;
  min-width: 100%;
  &:not(.not-striped){
    tbody {
      background-color: #fff;
      tr:nth-child(even) td {
        background: #f1f1f1;
      }
    }
  }
  &.bordered{
    &,td,th{
      border: 1px solid #d6d6d6;
    }
    th{
      background-color:#f1f1f1;
    }
    td{
      .body &{
        background-color: #fff;
      }
    }
  }
  &.text-center {
    th,
    td {
      .body & {
        text-align: center;
      }
    }
  }
  th,
  td {
    padding: 7px 10px;
    line-height: 30px;
    body:not(.ltr) & {
      text-align: right;
    }
    body.ltr & {
      text-align: left;
    }
  }
  th {
    background: $greenLight;
    font-weight: 400;
  }
  tbody {
    background-color: #fff;
  }
  .button {
    min-width: 40px;
  }
}
//end table

.peyment-method-section {
  .radio-item {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    .radio-label {
      display: block;
      border: 2px solid #bbb;
      line-height: 40px;
      padding: 0 10px;
    }
    .custom-radio:checked + .radio-label {
      border-color: #3696d8;
    }
    .bank-icon {
      body:not(.ltr) & {
        float: left;
      }
      body.ltr & {
        float: right;
      }

      height: 40px;
      padding: 0 5px;
      max-width: 160px;
      object-fit: contain;
      object-position: center;
    }
  }
}

.bankSelect {
  .bankItem {
    &:not(:first-child) {
      border-top: 0 none;
    }
  }
  .imageContainer img {
    height: 40px;
  }
  .button {
    min-width: 120px;
  }
}
.image-and-name .hotel-image {
  width: 70px;
  height: 60px;
}
/* .hotel-details-nav{
  padding-bottom: 30px;
  .nav-btn{
    background-color: transparent;
    padding: 15px 40px;
    border-bottom: 2px solid transparent;
    border-radius: 0;
    line-height:23px;
    outline: none;
    color: inherit;
    &:hover{
      color: $blueLink;
    }
    &.active{
      border-color: $blueLink;
      color: $blueLink;
      font-weight: 600;
    }   
  }
} */
.hotel-details {
  &:not(.domestic-hotel-details){
    font-size: 13px;
    line-height: 20px;
  }

  .safarmarket-welcome-message {
    font-size: 16px;
    text-align: center;
    border-radius: 4px;
    background-color: #feefe8;
    color: #f36d2f;
    border: 1px solid #f79d71;
    padding: 17px 20px;
    margin: 0 15px 15px;
    @media screen and (min-width: 501px) {
      margin: 0 0 15px;
    }
    @media screen and (min-width: $medium) {
      margin: 0;
    }
  }
  .normal-title:not(.safarmarket-welcome) {
    font-size: 21px;
    font-weight: normal;
    margin-bottom: 12px;
    line-height: 22px;
  }
  .title {
    font-size: 18px;
    font-weight: normal;
    margin: 10px 0;
    display: block;
  }
}
.hotel-head-info {
  .price {
    font-size: 28px;
    line-height: 1;
    white-space: nowrap;
    font-weight: 600;
    display: block;
  }
  .button {
    margin-top: 5px;
    font-size: 13px;
  }
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: $medium_) {
    display: block;
    padding: 0 10px;
    .button {
      width: 100%;
    }
    .price {
      font-size: 22px;
    }
  }
  .hotel-title {
    font-size: 28px;
    font-weight: 600;
    line-height: 1;
    color: inherit;
    margin-bottom: 10px;
    @media screen and (max-width: $medium_) {
      font-size: 22px;
    }
  }
  .hotel-address {
    font-size: 17px;
    margin-bottom: 5px;
    @media screen and (max-width: $medium_) {
      font-size: 14px;
    }
  }
}
@media screen and (max-width: $small_) {
  .detail-section {
    margin: 0;
    border: 0 none;
    border-bottom: 20px solid #222;
  }
}

.hotel-detail-top {
  display: flex;
  @media screen and (max-width: $medium_) {
    display: block;
  }
  .hotel-card-info-side {
    margin-bottom: 15px;
    @media screen and (min-width: $medium_) {
      body:not(.ltr) & {
        margin-left: 20px;
      }
      body.ltr & {
        margin-right: 20px;
      }
    }
    .selected-room-item {
      margin-bottom: 20px;
      @media screen and (min-width: $medium) {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .hotel-card-info {
    flex: 1 0 400px;
    background-color: #fff;
    .hotel-address {
      margin: 0;
    }
  }
  .galery-wrapper {
    position: relative;
    .hotel-detail-default-picture {
      background-image: url("../image/default-hotel-image.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100px;
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: #fff;
      @media screen and (max-width: $medium_) {
        position: relative;
        height: 150px;
        display: none;
      }
    }
  }
}
.hotel-get-passenger .get-passenger-form-holder {
  .title {
    line-height: 24px;
    font-weight: 600;
    display: block;
    font-size: 16px;
    color: #1f2529;
    margin-bottom: 0;
    padding: 10px 0;
  }
}
.border-top {
  border-top: 1px solid $border-color;
}
.card {
  &.border-bottom {
    border-bottom: 1px solid $border-color;
  }
  .border-bottom {
    border-bottom: 1px solid $border-color;
  }
  &.bg-green {
    background-color: $greenLight;
  }
  &.green-card{
    background-color: #f6ffec;
    border-color: $green;
  }
  &.red-card{
    border-color: $purple;
    background-color: #ffe7e5;
  }
  &.yellow-card{
    border-color: $yellow;
    background-color: #fff9e5;
  }
}
.card-header {
  background-color: #002144;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4;
}
.blue-border-right {
  @media screen and (min-width: $small) {
    border-right: 2px solid $blueLink;
  }
}
.card-padding {
  padding: 15px;
  @media screen and (max-width: $small_) {
    padding: 10px;
  }
}
.card-hr-padding {
  padding-right: 15px;
  padding-left: 15px;
  @media screen and (max-width: $small_) {
    padding-right: 10px;
    padding-left: 10px;
  }
}
.card-title {
  font-weight: 600;
  margin: 0;
  font-size: 20px;
  .icon {
    margin-inline-end: 10px;
    display: inline-block;
    vertical-align: middle;
    width: 25px;
    & + span {
      display: inline-block;
      vertical-align: middle;
    }
  }
}
.inline-small-icon {
  display: inline-block;
  vertical-align: middle;
  height: 20px;
}
@media screen and (max-width: $small_) {
  #hotel_details > .box-border {
    border-right: 0 none;
    border-left: 0 none;
  }
  .detail-table-cell b {
    font-weight: normal;
    font-size: 14px;
  }
  .room-select-card > .normal-title {
    padding: 0 10px;
  }
}
.select-room-item {
  .room-item {
    background: #fff;
    border: 1px solid rgb(211, 211, 211);
    margin-top: 20px;
    .room-title {
      font-size: 18px;
      line-height: 27px;
      padding: 18px 15px;
      border-bottom: 1px solid rgb(211, 211, 211);
      font-size: 18px;
      b {
        font-weight: 600;
      }
    }
    .room-detail {
      .price {
        font-weight: 600;
      }
      .room-table-title {
        font-weight: 600;
        font-size: 12px;
      }
      padding: 15px;
      .detail-table-cell {
        padding-bottom: 5px;
        .button {
          font-size: 14px;
        }
      }
    }
    .detail-table {
      margin-bottom: 15px;
    }
    @media screen and (max-width: $small_) {
      border-right: 0 none;
      border-left: 0 none;
      .room-title {
        padding: 10px;
        b {
          font-size: 16px;
          font-weight: normal;
          text-transform: uppercase;
        }
      }
      .button {
        width: 100%;
        margin-top: 5px;
      }
    }
    .detail-table-tbody .guest-dark {
      position: relative;
      top: -2px;
    }
    @media screen and (min-width: $medium_) {
      .cancelationContainer {
        background-color: #fff;
        border: 1px solid rgb(211, 211, 211);
        border-top: 0 none;
      }
      .detail-table {
        display: table;
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 0;
      }
      .detail-table-tbody {
        display: table-row-group;
        width: 100%;

        .detail-table-row {
          display: table-row;
          &.bordered-row .detail-table-cell {
            border: 1px solid rgb(224, 224, 224);
            padding: 10px 10px;
            &:last-child {
              width: 15%;
            }
            &:nth-child(2) {
              width: 20%;
            }
            &:nth-child(3) {
              width: 20%;
            }
            .button {
              min-width: 148px;
            }
          }
          .detail-table-cell {
            display: table-cell;
          }
        }
      }
    }

    /*     @media screen and (max-width: $small_){
      .flex-ends{
        display: block;
      }
      .room-price {
        display: block;
        text-align: left;
      }
      .button{
        width: 100%;
        margin-left: 0;
        max-width: 100%;
      }
    } */
  }
  .selected-room {
    padding: 10px 15px;
    border: 1px solid $border-color;
  }
}
.cancelationContainer {
  background: #f1f1f1;
  padding: 15px;
}
.hotel-top-info {
  display: flex;

  @media screen and (max-width: $small_) {
    display: block;
  }

  .hotel-image {
    background-position: center;
    background-repeat: no-repeat;
    flex: 0 0 180px;

    @media screen and (max-width: $small_) {
      height: 150px;
    }

    &.default-hotel-image {
      background-size: 70px;
      background-position: center;
    }

    &.main-hotel-image {
      background-size: cover;
      background-position: center;
      background-color: $blueFooter;
      &.default-hotel-image {
        background-size: 100px;
      }
    }
  }

  .hotel-content {
    width: 100%;
    position: relative;

    &::after {
      content: "";
      display: block;
      background-color: #bbb;
      height: 1px;
      right: 20px;
      left: 20px;
      position: absolute;
      bottom: 0;
    }
  }
}
.map-holder {
  position: relative;
  z-index: 1;
}
.room-image-holder {
  background: #f1f1f1;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
  .room-image {
    margin: auto;
    width: 120px;
    max-width: 100%;
  }
}

// Styleguide Components.Counter
.counter-circle {
  width: 30px;
  height: 30px;
  display: inline-block;
  background: #fff;
  @include box-shadow($boxshadow1);
  font-size: 20px;
  @include border-radius(50%);
  line-height: 35px;
  text-align: center;
  body:not(.ltr) & {
    margin-left: 10px;
    line-height: 40px;
  }
  body.ltr & {
    margin-right: 10px;
    line-height: 35px;
  }

  font-weight: normal;
}
.counter-holder {
  counter-reset: section;
}
.counter-circle::before {
  counter-increment: section;
  content: counter(section);
  position: relative;
  top: -3px;
}
//end Counter
.payment-icon {
  width: 40px;
  display: inline-block;
  vertical-align: middle;
  margin-inline-end: 10px;
}
.reverse-dir {
  body:not(.ltr) & {
    direction: ltr;
  }
  body.ltr & {
    direction: rtl;
  }
}
.body-direction {
  body:not(.ltr) & {
    direction: rtl;
  }
  body.ltr & {
    direction: ltr;
  }
}

//datepicker styles:
body.body {
  .ant-calendar-prev-year-btn,
  .ant-calendar-next-year-btn {
    display: none;
  }
  .selectToday {
    display: none;
  }
}
.ant-calendar-picker {
  width: 100%;
}
//hotel avail style
.searched-information {
  color: #fff;
  //padding-top: 4px;
  .caption {
    .jalali,
    .gregorian {
      display: inline-block;
      direction: ltr;
    }
  }
}
.ant-calendar-picker {
  min-width: 0 !important;
}
.ant-calendar-picker-input {
  height: 35px;
  body:not(.ltr) & {
    padding-right: 35px;
  }
  body.ltr & {
    padding-left: 35px;
  }
}
@media screen and (max-width: $small_){
  .rmdp-container .ep-arrow+div {
    position: fixed !important;
    left: 0 !important;
    transform: none !important;
    right: 0 !important;
    width: 100% !important;
    background: #fff;
    height: 100vh;
    z-index: 102!important;
    .rmdp-wrapper.multi-datepicker {
      box-shadow: none;
      width: 100%;
      height: 100vh;
      border-radius: 0;
    }
    .rmdp-day, .rmdp-week-day {
      height: 40px;
      width: 40px;
    }
  }
}

.ant-calendar-picker-container {
  @media screen and (max-width: $small_) {
    .ant-calendar-input-wrap {
      display: none;
    }
    position: fixed;
    background: #fff;
    top: 0 !important;
    left: 0 !important;
    z-index: 10;
    width: 100%;
    height: 100vh;
    .ant-calendar {
      margin: auto;
      @include box-shadow(none);
      width: 320px;
      .ant-calendar-body {
        padding: 10px;
      }
      .ant-calendar-cell {
        height: auto;
        .ant-calendar-date {
          height: 43px;
          line-height: 40px;
          padding: 2px;
        }
      }
      &::before {
        display: none;
      }
    }
    .ant-calendar-prev-month-btn {
      left: 10px;
      &::before {
        width: 15px;
        height: 15px;
        border-width: 3px 0 0 3px;
      }
    }
    .ant-calendar-next-month-btn {
      right: 10px;
      &::before {
        width: 15px;
        height: 15px;
        border-width: 3px 0 0 3px;
      }
    }
  }
}
.ant-calendar-time-picker-select {
  height: 210px;
}
.ant-calendar {
  &.ant-calendar-range {
    top: 0;
  }
  top: 50px;
  @include box-shadow(0 0 4px grey);
  &::before {
    position: absolute;
    z-index: -1;
    content: "";
    top: -7px;
    width: 11px;
    height: 11px;
    border-top: 1px solid #aaa;
    border-left: 1px solid #aaa;
    background: #fff;
    z-index: 1;
    @include transform(rotate(45deg) skew(10deg, 10deg));
    left: 10px;
    /*     body:not(.ltr) &{
      right:10px;
    }
    body.ltr &{
      left:10px;
    } */
  }
}
.ant-calendar-picker {
  position: relative;
  .anticon {
    width: 33px;
    height: 33px;
    background-color: #fff;
    background-position: center;
    background-size: 24px;
    background-repeat: no-repeat;
    top: 1px;
    margin: 0;
    body:not(.ltr) & {
      right: 1px;
    }
    body.ltr & {
      left: 1px;
      top: 5px;
    }

    @include border-radius(3px);
    &.ant-calendar-picker-icon {
      .departure-date &,
      .checkin-column & {
        body:not(.ltr) & {
          background-image: url("../image/left-date.svg");
        }
        body.ltr & {
          background-image: url("../image/right-date.svg");
        }
      }
      .return-date &,
      .checkout-column & {
        body:not(.ltr) & {
          background-image: url("../image/right-date.svg");
        }
        body.ltr & {
          background-image: url("../image/left-date.svg");
        }
      }
    }
    svg {
      display: none;
    }
  }
}
.ErrorShow .image img {
  max-width: 60px;
}
.ant-calendar-next-month-btn-day,
.ant-calendar-last-month-cell {
  visibility: hidden;
}
.ant-calendar-body {
  padding: 10px 25px 20px;
  .ant-calendar-cell {
    padding: 0;
  }
}
div.ant-calendar-date {
  padding: 1px;
  background-clip: content-box !important;
  min-width: 30px;
  width: auto;
  line-height: 28px;
  height: 30px;
  background-color: #f3f5f5;
  border: 0 none;
  margin: 0;
  &::before {
    display: none;
  }
  &:hover {
    background-color: $border-color;
    color: inherit;
  }
  .body .ant-calendar-selected-day & {
    background-color: #0077a3;
    color: #fff;
  }
  .ant-calendar-today & {
    background-color: $yellow;
    color: #fff;
    body.body & {
      padding-right: 1px;
      padding-left: 1px;
    }
  }
}
.ant-calendar-column-header-inner {
  color: #bbb;
}
.body .ant-calendar-footer-btn {
  .ant-calendar-ok-btn {
    background-color: $purple;
    border-color: $purple;
    outline: none;
    &:hover {
      background-color: $purpleLight;
    }
    &.ant-calendar-ok-btn-disabled {
      background-color: #ddd;
      border-color: #ddd;
      color: #fff;
    }
  }
}

//account:
.accountWindow {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  background-image: url("../image/pixels.png");
  z-index: 1001;
  padding: 20px;
  @media screen and (max-width: $small_) {
    padding:0;
    &>.page-container{
      padding: 0;
    }
    .Form div.register {
      body.body &{
        border-radius: 0 ;
      }
      height: calc(100vh - 40px);
      overflow: auto;
    }
    .Form .login-tabs-holder button.tab-btn{
      .body &{
        border-radius: 0;
        margin: 0;
        bottom: 0;
      }
    }
  }
  .Form {
    .register {
      background-color: #fff;
      padding: 15px;
      body:not(.ltr) & {
        border-radius: 15px 0 15px 15px;
      }
      body.ltr & {
        border-radius: 0 15px 15px 15px;
      }
      
    }
    .login-tabs-holder {
      .tab-btn {
        border-radius: 0;
        background-color: #23a5b9;
        color: #fff;
        outline: none;
        border: none;
        padding: 5px 15px;
        font-weight: 600;
        line-height: 30px;
        border-radius: 10px 10px 0 0;
        +.tab-btn{
          margin-right: 3px;
          margin-left: 3px;
        }
        &.active {
          background-color: #fff;
          color: #23a5b9;
          position: relative;
          bottom: -1px;
        }
      }
    }
    @media screen and (min-width: $small) {
      max-width: 400px;
      top: 50%;
      left: 50%;
      position: absolute;
      width: 100%;
      transform: translate(-50%, -50%);
      padding: 15px;
    }
    .close-btn {
      position: absolute;
      width: auto;
      height: auto;
      text-align: center;
      cursor: pointer;
      background-color: transparent;
      border-radius: 10px 10px 0 0;
      cursor: pointer;
      top: 15px;
      @media screen and (max-width: $small_){
        top:6px;
      }
      body:not(.ltr) & {
        left: 15px;
      }
      body.ltr & {
        right: 15px;
      }
    }
    .success-icon {
      width: 40px;
      display: block;
      margin: 0 auto 10px;
    }
  }
}

.country-phone-holder {
  direction: ltr;
  .react-phone-number-input {
    .react-phone-number-input__country {
      width: 55px;
    }
    .react-phone-number-input__icon {
      width: 100%;
      height: 35px;
      box-sizing: border-box;
      border: 0 none;
    }
    .react-phone-number-input__country-select {
      border-radius: 3px;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      background-position: left 0 center;
      background-repeat: no-repeat;
      background-size: 15px;
      padding-left: 25px;
      padding-right: 10px;
      border: 1px solid $border-color;
    }
    .react-phone-number-input__input {
      border: 1px solid $border-color;
      width: 100%;
      @include border-radius(3px);
      outline: none;
      height: 35px;
      display: block;
      box-sizing: border-box;
      padding: 0 10px;
      font-size: 15px;
      background: #fff;
      line-height: 35px;
      direction: ltr;
      text-align: left;
      &::placeholder {
        text-align: right;
      }

      &:-ms-input-placeholder {
        text-align: right;
      }

      &::-ms-input-placeholder {
        text-align: right;
      }
    }
  }
}
.success-icon {
  width: 40px;
}
.hotel-no-picture {
  background-image: url("../image/no-image-jpg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 20px;
  min-height: 300px;
  color: #fff;
  border: 0 none;
  font-size: 18px;
  position: relative;
  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .no-image-hint {
    margin: 50px 0;
    max-width: 300px;
    display: inline-block;
    position: relative;
  }
}
.hotel-detail-default-picture {
  background-image: url("../image/default-hotel-image.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 200px;
}

//user profile:
.profileNavbar .link-style-btn {
  display: block;
  &.active {
    font-weight: bold;
    color: $purple;
  }
}
.profile-tab-icon {
  display: block;
  width: 50px;
}

.ErrorShow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10000;
  background: #0000008a;
  backdrop-filter: blur(3px);
  .toolbar {
    max-width: 400px;
    &.large{
      max-width: 650px;
    }
    top: 50%;
    left: 50%;
    position: absolute;
    width: calc(100% - 20px);
    @include transform(translate(-50%, -50%));
    @include border-radius(4px);
  }
}

//404:
.pageNotFound {
  margin: 60px 0;
  img.image {
    background: #bbb;
    width: 500px;
    height: 253px;
    max-width: 100%;
    background-image: url("../image/404.jpg");
    background-size: cover;
    background-position: center;
  }
}

.reserve-status-icon {
  width: 70px;
  height: 70px;
  display: inline-block;
  vertical-align: middle;
  &.peyment-error-icon {
    background-position: 350px 0;
  }
  &.peyment-warning-icon {
    background-position: 280px 0;
  }
  &.peyment-ok-icon {
    background-position: 140px 0;
  }
  &.peyment-exchange-icon {
    background-position: 210px 0;
  }
}

.button.show-close-btn {
  padding: 0;
  background: none;
  line-height: inherit;
  min-width: 0;
  .close-icon {
    display: block;
  }
}

//tab icons:
.tab-icon-span {
  display: inline-block;
  width: 26px;
  height: 26px;
  vertical-align: middle;
  margin: 0 2px;
  &.flight {
    background-position: 0 -26px;
    .active-tab-s & {
      background-position: 0 0;
    }
  }
  &.hotel {
    background-position: -26px -26px;
    .active-tab-s & {
      background-position: -26px 0;
    }
  }
}

//social links:
.social-icon {
  width: 24px;
  height: 24px;
  display: inline-block;
  &.instagram {
    background-position: 96px -24px;
  }
  &.twitter {
    background-position: 48px -24px;
  }
  &.telegram {
    background-position: 72px -24px;
  }
}

//location
.location-blue-icon {
  width: 22px;
  height: 22px;
  display: inline-block;
  background-position: -176px -66px;
  vertical-align: middle;
}

.takeoff-yellow {
  width: 22px;
  height: 22px;
  display: inline-block;
  background-position: -88px -88px;
  vertical-align: middle;
}
.landing-yellow {
  width: 22px;
  height: 22px;
  display: inline-block;
  background-position: -66px -88px;
  vertical-align: middle;
}
.clock-yellow {
  width: 22px;
  height: 22px;
  display: inline-block;
  background-position: -111px -88px;
  vertical-align: middle;
}

.user-profile-icons {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  &.user-sprite-icon {
    background-position: -800px -151px;
  }
  &.password-sprite-icon {
    background-position: -850px -151px;
  }
  &.ticket-sprite-icon {
    background-position: -900px -151px;
  }
  &.flight-ticket-sprite-icon {
    background-position: -1350px -151px;
  }
  &.hotel-ticket-sprite-icon {
    background-position: -1550px -151px;
  }
}

.direction-arrow {
  width: 20px;
  height: 20px;
  margin: 5px;
  background-position: -460px -139px;
  &.dark-arrow {
    background-position: -460px -100px;
  }
}

//contact page
.label-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  &.phone-red-icon {
    background-position: -240px -48px;
  }
  &.mail-red-icon {
    background-position: -168px -48px;
  }
  &.location-red-icon {
    background-position: -192px -48px;
  }
}

.country-code-group {
  @extend .clearfix;
  .itours-select {
    width: calc(100% - 135px);
    float: left;
  }
  .form-control {
    width: 120px;
    float: right;
  }
}

.phone-icon {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  background-position: -240px 120px;
}

//flight reserve:
.sticky-row span.price {
  &:not([dir="rtl"]){
    direction: ltr;
  }
  display: inline-block;
}
body.body span.price.rtl{
  direction: rtl;
}
.item-label {
  display: inline-block;
  font-weight: 600;
}

.message-block {
  &.success-message {
    background-color: #f9fff8;
    color: rgb(29, 172, 8);
    border-color: rgb(29, 172, 8);
  }
  &.error-message {
    background-color: #fff;
    color: $purple;
    border-color: $purple;
  }
}

//profile-table:
@media screen and (min-width: $medium) {
  .no-scroll-responsive-table {
    .mobile-label {
      display: none;
    }
  }
}
@media screen and (max-width: $medium_) {
  .no-scroll-responsive-table {
    thead {
      display: none;
    }
    tr {
      display: block;
      border: 1px solid #bbb;
      margin-bottom: 15px;
      td {
        display: flex;
        justify-content: space-between;
        line-height: 18px;
        align-items: center;
        &:not(:first-child) {
          border-top: 1px dotted #ddd;
        }
        .mobile-label {
          display: inline-block;

          body:not(.ltr) & {
            margin-left: 10px;
          }
          body.ltr & {
            margin-right: 10px;
          }
          & + span {
            display: inline-block;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $small_) {
  .mobile-full-width-btn {
    width: 100%;
    margin: 0;
    margin-top: 10px;
  }
}

.dropdown-hover-holder {
  position: relative;
  .dropdown-hover-content {
    position: absolute;
    background: white;
    border: 1px solid #bbb;

    body:not(.ltr) & {
      right: 0;
    }
    body.ltr & {
      left: 0;
    }
    visibility: hidden;
    opacity: 0;
    top: 100%;
    margin-top: -3px;
    z-index: 100;
    @include transition(all 0.1s linear);
    .language-link {
      display: block;
      padding: 0 10px;
    }
    &::before {
      position: absolute;
      z-index: -1;
      content: "";
      top: -4px;
      width: 7px;
      height: 7px;
      border-top: 1px solid #aaa;
      background: #fff;
      z-index: 1;
      @include transform(rotate(45deg) skew(10deg, 10deg));
      body:not(.ltr) & {
        right: 5px;
      }
      body.ltr & {
        left: 5px;
      }
      border-left: 1px solid #bbb;
    }
  }
  &:hover .dropdown-hover-content {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
  }
}
//overstyle
.ant-popover-inner {
  background-color: $purple;
}
.ant-popover-inner-content {
  color: white;
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  border-right-color: $purple;
  border-bottom-color: $purple;
}
.searchShow {
  line-height: 23px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 62px;
  .package.search-result-page &{
    border: 0 none;
  }
  b {
    font-size: 16px;
  }
  .searched-value {
    font-size: 14px;
    font-weight: 600;
  }
  .gray-label {
    font-size: 12px;
    color: rgb(169, 169, 169);
  }
  .typeShow img {
    width: 20px;
  }
  .seperator {
    height: 40px;
    width: 1px;
    background: #e4e4e4;
  }
  @media (max-width: $medium_) {
    flex-wrap: wrap;
    padding-bottom: 15px;
    .package.search-result-page &{
      padding-top: 10px;
    }
  
    .originShow {
      flex: 0 0 40%;
    }
    .typeShow {
      flex: 0 0 20%;
      text-align: center;
    }
    .destinationShow {
      flex: 0 0 40%;
    }
    .seperator {
      display: none;
    }
    .depShow {
      flex: 0 0 33.3%;
      margin: 10px 0;
    }
    .retShow {
      flex: 0 0 33.3%;
      margin: 10px 0;
      .body & {
        text-align: center;
      }
    }
    .passShow {
      flex: 0 0 33.3%;
      margin: 10px 0;
      body:not(.ltr) & {
        text-align: left;
        padding-left: 10px;
      }
      body.ltr & {
        text-align: right;
        padding-right: 10px;
      }
    }
  }
}
.fixed-search-overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(70, 69, 69, 0.5);
  z-index: 10;
}
.fixed-search-holder {
  border-radius: 10px;
  position: fixed;
  width: 100%;
  max-width: 1170px;
  right: 50%;
  top: 136px;
  -webkit-transform: translate(50%, 0);
  transform: translate(50%, 0);
  z-index: 11;
  background-color: rgba(0, 0, 0, 0.7);
  label {
    color: inherit;
  }
}
.full-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(70, 69, 69, 0.5);
  left: 0;
  top: 0;
  z-index: 999;
  .search-box-holder {
    background-color: rgba(0, 0, 0, 0.7);
    @media (max-width: $medium_) {
      height: 100vh;
      overflow: auto;
      padding: 10px;
      background-color: #fff;
      .searchbox-inner {
        padding-top: 50px;
      }
    }
  }
  .search-container {
    position: absolute;
    @media (min-width: $medium) {
      left: 50%;
      top: 113px;
      transform: translate(-50%, 0);
      width: calc(100% - 30px);
      max-width: 1185px;
      bottom: 50px;
    }
    @media (max-width: $medium_) {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      .searchbox-inner.bg-dark-blue {
        height: 100vh;
        padding-top: 60px;
        overflow: auto;
      }
    }
  }
}
.hidden {
  display: none !important;
}
.cancelShow {
  position: absolute;
  top: 10px;

  body:not(.ltr) & {
    left: 8px;
  }
  body.ltr & {
    right: 8px;
  }
  input {
    border: 1px solid white;
    background-color: transparent;
    color: white;
    cursor: pointer;
  }
}
.sort-container {
  img {
    height: 18px;
    margin: 4px;
  }
  label {
    font-size: 14px;
  }
}
.search-box-holder.inline {
  &.float-window {
    position: fixed;
    background-color: rgba(2, 2, 2, 0.49);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9999;
    .bg-dark-blue {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1170px;
      max-width: 100%;
    }
    .close-research {
      text-align: right;
      .closeEdit {
        background-color: transparent;
        border: 1px solid white;
        color: white;
        padding: 2px;
        border-radius: 2px;
        margin-right: 8px;
      }
    }
  }
  .headerInformation {
    .hotelResult & {
      border: 1px solid rgb(223, 223, 223);
      background-color: rgb(250, 250, 250);
      .button {
        font-size: 12px;
        line-height: 25px;
        padding-top: 0;
        padding-bottom: 0;
        min-width: 120px;
      }
      > div {
        line-height: 25px;
      }
    }
    display: flex;
    border: 1px solid #c4c4c4;
    border-bottom: 0 none;
    > div {
      font-weight: 600;
      width: 50%;
      background-color: #f4f4f4;
      padding: 10px;
      line-height: 32px;
      border-bottom: 0;
      margin: 0;
    }
  }
  .searched-information {
    .hotelResult & {
      background-color: rgb(250, 250, 250);
      .textbox {
        font-weight: 600;
        font-size: 14px;
      }
    }

    background-color: #dfdfdf;
    //padding:10px;
    .fakeForm {
      display: flex;
      .textbox {
        background-color: white;
        color: #2d2d2d;
        padding: 10px 6px;
        border: 1px solid #dfdfdf;
        border-top: none;
        width: 20%;
        .domestic-hotel-search-result &{
          width: 25%;
        }
        margin: 0 2px;
        body:not(.ltr) & {
          &:first-child {
            margin-right: 0;
          }
          &:last-child {
            margin-left: 0;
          }
        }
        body.ltr & {
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      @media (max-width: $medium_) {
        display: none;
      }
    }
  }
}

.sortPoint {
  width: 15px;
  transform: rotate(90deg);
}
.time-icon {
  width: 19px;
}
.page-container .filters-holder {

  .normal-title {
    font-size: 15px;
  }
  .checkbox-holder {
    margin: 0 10px;
  }
  .title-head {
    font-weight: 600;
    font-size: 18px;
    border-bottom: 1px solid #464545;
  }
  .ant-collapse-item {
    .ant-collapse-header {
      font-size: 15px;
      padding: 10px 0;
      body:not(.ltr) & {
        padding-left: 30px;
      }
      body.ltr & {
        padding-right: 30px;
      }

      position: relative;
      i {
        display: none !important;
      }
      &::after {
        content: "";
        display: block;
        border-top: 2px solid rgb(2, 100, 122);
        border-left: 2px solid rgb(2, 100, 122);
        @include transform(rotate(-135deg));
        @include transition(all 0.2s linear);
        width: 8px;
        height: 8px;
        top: 50%;
        position: absolute;

        body:not(.ltr) & {
          left: 0;
        }
        body.ltr & {
          right: 0;
        }
        margin-top: -5px;
      }
    }
    &.ant-collapse-item-active {
      .ant-collapse-header {
        &::after {
          @include transform(rotate(45deg));
          margin-top: -2px;
        }
      }
    }
  }

  .ant-collapse-content-box {
    padding: 10px 0;
  }
}
.sidebar {
  > .sidebar-item {
    background-color: #b9e2ff;
    border-radius: 4px;
    padding: 4px 12px;
    margin-bottom: 8px;
  }
}
.result-footer-title {
  & > div {
    padding-bottom: 2px;
  }
  .purple.small-margin-bottom.text-center {
    font-weight: 600;
    font-size: 22px;
    color: #7a7a7a;
  }
}
.baggegesInformation {
  @media (max-width: $small_) {
    font-size: 13px;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  > div {
    flex: 0 0 25%;
    line-height: 20px;
    &.airplaneIcon {
      width: 10%;
      flex: 0 0 10%;
      img {
        width: 20px;
      }
    }
    &:last-child {
      border-left: 1px dotted gray;
      padding-left: 6px;
      font-weight: 600;
      flex: 0 0 40%;
    }
  }
}
.ant-tabs-tabpane {
  padding: 2px;
  margin-bottom: 6px;
}
.body .page-container .rules-container {
  padding: 15px;
  margin: 0 -15px;
  .ant-collapse-header,
  .ant-collapse-content {
    padding: 5px 15px;
  }
  @media (min-width: $medium) {
    max-height: 450px;
  }

  overflow-y: auto;
  background-color: white;
}
.ant-tabs-bar {
  background-color: #f3f3f3;
  padding: 0;
  border: 0;
  margin-bottom: 0;
  border-right: 1px solid $border-color;
  border-left: 1px solid $border-color;
}
.ant-tabs-nav-scroll {
  @extend .body-direction;
  padding: 0 5px;
}
.body .ant-tabs-nav {
  .ant-tabs-tab {
    margin: 17px 7px 15px 7px;
    @media (max-width: $small_) {
      margin: 17px 5px 15px 5px;
    }
    box-shadow: rgba(2, 100, 122, 0.1) 0px 1px 8px 0px,
      rgba(0, 0, 0, 0.1) 0px 1px 8px 0px;
    background-color: #fff;
    border: 2px solid #fff;
    color: rgb(2, 100, 122) !important;
    font-size: 12px;
    font-weight: 600;
    padding: 10px 12px;
    border-radius: 2px;
    &:active {
      color: rgba(0, 0, 0, 0.56);
    }
  }
  .ant-tabs-tab-active {
    border: 2px solid rgb(129, 178, 189);
    font-weight: 600;
    color: #078792;
    background-color: rgba(69, 197, 208, 0.09);
    &:hover {
      color: #078792;
    }
  }
}
.fareContainer {
  line-height: 32px;
  background-color: white;
  .title {
    font-weight: bold;
    font-size: 16px;
    padding-bottom: 22px;
  }
}
.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  display: none !important;
}

.hotel-card-info {
  .flex-mode {
    display: flex;
    .hotel-image {
      display: block;
      flex: 0 1 120px;
      background-size: cover;
      background-position: center;
      min-height: 100px;
      margin-right: 14px;
    }
  }
  .hotel-address {
    padding: 8px 0;
  }
  .purple {
    color: #b52555;
    font-size: 18px;
    padding-top: 12px;
  }
}
.feature-list .feature-items {
  display: flex;
  flex-wrap: wrap;
  .package-hotel-item &{
    @media (min-width: $medium) {
      height: 25px;
      overflow: hidden;
    }
  }
  div {
    color: $green;
    padding: 0 4px;
    margin: 2px;
    border: 1px solid $green;
    font-size: 12px;
    font-weight: 600;
    line-height: 19px;
    border-radius: 2px;
    &.is-important {
      font-weight: bold;
      border-width: 2px;
      font-size: 16px;
    }
  }
}
.confirmButton {
  padding: 6px;
  .package-summary-card &{
    padding: 0;
  }
  button {
    width: 100%;
    height: 42px;
  }
}
.Home {
  background: #f1f1f1;
  @media (max-width: $medium_) {
    background: #fff;
  }
}
//overide
.anticon.anticon-close-circle.ant-calendar-picker-clear {
  display: none;
}

.ant-steps {
  margin-bottom: 20px;
  .ant-steps-item {
    .body:not(.ltr) & {
      margin-left: 16px;
      margin-right: 0;
    }
    .body.ltr & {
      margin-right: 16px;
      margin-left: 0;
    }
  }
}

@media (max-width: $medium_) {
  .body .ant-steps {
    display: none;
  }
}
.ant-steps-item-icon {
  .body:not(.ltr) & {
    margin-left: 8px;
    margin-right: 0;
  }
  .body.ltr & {
    margin-right: 8px;
    margin-left: 0;
  }
  width: 25px;
  height: 25px;
  line-height: 23px;
  vertical-align: middle;
  display: inline-block;
  font-weight: 600;
  .ant-steps-item-process & {
    background: rgb(35, 165, 185);
    border-color: rgb(35, 165, 185);
  }
  .ant-steps-item-finish & {
    background-color: transparent;
    border: 0 none !important;
    @extend .itours-icon;
    background-position: 78px -104px;
    .anticon {
      display: none;
    }
  }
}
.ant-steps-item-content {
  display: inline-block;
  vertical-align: middle;
  .ant-steps-item-title {
    .body:not(.ltr) & {
      padding-right: 0;
      padding-left: 16px;
    }
    .body.ltr & {
      padding-left: 0;
      padding-right: 16px;
    }
    font-weight: bold;
    font-size: 16px;
    color: rgb(58, 58, 58);
    &::after {
      body:not(.ltr) & {
        right: 100%;
        left: auto;
      }
    }
  }
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  border-bottom: 1px dashed #02647a;
  background-color: transparent;
}
.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  border-bottom: 1px dashed $border-color;
  background-color: transparent;
}
.ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  .ant-steps-item-finish > & {
    color: rgb(151, 161, 166);
    font-size: 14px;
  }
  .ant-steps-item-wait > & {
    color: rgb(151, 161, 166);
    font-size: 14px;
  }
}

.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #97a1a6;
  border: 0 none !important;
  color: #fff;
  .ant-steps-icon {
    color: inherit;
  }
}

.body .main-content {
  .ant-collapse-borderless > .ant-collapse-item {
    border: 0 none;
    b {
      font-weight: 600;
    }
    &:not(:first-child) {
      margin-top: 5px;
    }
    font-size: 13px;
  }
  .ant-collapse-borderless {
    background-color: transparent;
  }
  .ant-collapse-item.ant-collapse-item-active {
    .ant-collapse-header {
      .passenger-type-title {
        &::after {
          transform: rotate(-45deg);
          top: 2px;
        }
      }
    }
  }
  .ant-collapse-header {
    padding: 0;
    .anticon {
      display: none;
    }
    .passenger-type-title {
      color: rgb(35, 165, 185);
      font-weight: 600;
      &::after {
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        border-right: 2px solid;
        border-top: 2px solid;
        margin: 0 8px;
        transform: rotate(135deg);
        position: relative;
        top: -1px;
        @include transition(all 0.2s linear);
      }
      & + .price {
        font-weight: 600;
      }
    }
  }
  .ant-collapse-content-box {
    padding: 0;
  }
}

.page-title {
  font-size: 24px;
  font-weight: 600;
  border-bottom: 1px solid rgb(223, 223, 223);
  padding-bottom: 10px;
}

.hotel-summary-card,.package-summary-card {
  line-height: 21px;
  .hotel-image {
    flex: 0 0 70px;
    margin-right: 15px;
    &.right2left{
      margin-right: 0;
      margin-left: 15px;
    }
    &.default-hotel-image {
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  .hotel-title {
    line-height: 20px;
    font-size: 16px;
    margin-bottom: 5px;
  }
  .card-title {
    font-size: 16px;
    padding: 10px 0;
    border-bottom: 1px solid #bbb;
    line-height: 24px;
  }
  .hotel-head-info {
    padding-top: 10px;
  }
  .card-footer {
    border-top: 1px solid #bbb;
    margin-top: 15px;
    padding: 10px 0 20px;
    .confirmButton {
      padding: 0;
    }
  }
}
.top-color-border {
  padding: 15px 15px;
  border-top-width: 2px;
  &.orange {
    border-top-color: rgb(255, 141, 25);
    .colored {
      color: rgb(255, 141, 25);
    }
  }
}

.itours-responsive-table {
  .table-head {
    display: none;
  }
  .table-row {
    &.total-row {
      display: flex;
      justify-content: space-between;
      & > div {
        .body & {
          background-color: transparent;
        }
      }
    }
    margin-bottom: 15px;
    border: 1px solid #bbb;
    & > div {
      display: flex;
      justify-content: space-between;
      padding: 7px 10px;
      &:nth-child(even) {
        background-color: #f1f1f1;
      }
      &:nth-child(odd) {
        background-color: #fff;
      }
    }
  }
  &.large-table {
    @media (min-width: $large) {
      display: table;
      border: 1px solid $border-color;
      margin-bottom: 15px;
      width: 100%;
      .responsive-label {
        display: none;
      }
      .table-row-group {
        display: table-row-group;
        .table-row {
          display: table-row;
          margin: 0;
          border: 0 none;
          &:nth-child(odd) {
            background-color: #f1f1f1;
          }
          &.table-head {
            background-color: $greenLight;
          }
          & > div {
            display: table-cell;
            background-color: transparent;
          }
        }
      }
    }
  }
  &.medium-table {
    @media (min-width: $medium) {
      display: table;
      border: 1px solid $border-color;
      margin-bottom: 15px;
      width: 100%;
      .responsive-label {
        display: none;
      }
      .table-row-group {
        display: table-row-group;
        .table-row {
          display: table-row;
          margin: 0;
          border: 0 none;
          &:nth-child(odd) {
            background-color: #f1f1f1;
          }
          &.table-head {
            background-color: $greenLight;
          }
          & > div {
            display: table-cell;
            background-color: transparent;
          }
        }
      }
    }
  }
}
.body .hotel-detail-searchholder {
  .search-form-header {
    border: 1px solid #d9d9d9;
    padding: 20px;
    font-size: 22px;
    background-color: #fff;
    .button {
      min-width: 120px;
      font-size: 13px;
    }
  }
  .bg-dark-blue {
    background: #35353f;
    padding: 15px;
    label {
      color: #fff;
    }
    .PDatePicker-input {
      background-image: none;
    }
    .children-ages {
      margin: 0;
    }
    @media (max-width: $small_) {
      .room-guests {
        &.multyroom {
          padding-right: 50px;
        }
      }
    }
  }
}
@media (max-width: $large_) {
  .search-form-header .button.full-width-mobile {
    margin: 10px 0 0;
    display: block;
  }
}

.has-fake-magnifirer-icon {
  input.form-input {
    body:not(.ltr) & {
      padding-left: 55px;
    }
    body.ltr & {
      padding-right: 55px;
    }
  }
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    background-color: #23a5b9;
    top: 1px;

    width: 48px;
    height: 38px;
    body:not(.ltr) & {
      left: 1px;
    }
    body.ltr & {
      right: 1px;
    }
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 9px;

    body:not(.ltr) & {
      left: 13px;
    }
    body.ltr & {
      right: 13px;
    }
    width: 22px;
    height: 22px;
    background-position: -528px 22px;
    @extend .itours-icon;
  }
}

.map-btn-holder {
  width: 100%;
  height: 93px;
  background-image: url("../image/map.jpg");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &::before{
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #112a4d21;
  }
  .map-view-btn {
    position:relative;
    background: #fff;
    color: rgb(2, 100, 122);
    font-weight: 600;
    line-height: 21px;
    border: 1px solid;
    border-radius: 2px;
    outline: 0 none;
    min-width: 110px;
  }
}

.map-wrapper {
  z-index: 0;
  height: 450px;
  max-height: 80vh;
  .contact-us &{
    height: 350px;
    border-radius: 4px;
    border: 1px solid #bbb;
  }
}
.rotate-rtl {
  .body:not(.ltr) & {
    @include transform(rotate(180deg));
  }
}
.mirror-rtl {
  .body:not(.ltr) & {
    @include transform(scale(-1, 1));
  }
}
.mirror-ltr {
  .body.ltr & {
    @include transform(scale(-1, 1));
  }
}
.menu-overlay {
  background: #0000008a;
  backdrop-filter: blur(3px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  z-index: 101;
  @media (min-width: $medium) {
    display: none;
  }
}

//resurs bank style:
.payment-radio {
  border-bottom: 1px solid $border-color;
  padding-bottom: 15px;
  position: relative;
  //z-index: -1;
}
.gateway-item {
  .item-main{
    display: flex;
    justify-content: space-between;
  }
  .button.green-btn {
    max-height: 38px;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #f1f1f1;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
}
.resurs-form {
  @extend .clearfix;
  .button {
    //margin-top: 20px;
    float: right;
  }
  form {
    .form-label {
      width: 100%;
      display: block;
    }
    @media (min-width: $medium_) {
      margin-left: -10px;
      margin-right: -10px;
      .form-group {
        &::after,
        &::before {
          content: "";
          display: table;
          clear: both;
        }
        padding-left: 10px;
        padding-right: 10px;
        width: 50%;
        float: left;
        &:nth-child(odd) {
          clear: left;
        }
        &:last-child {
          width: 100%;
        }
        .country-code {
          width: 35%;
          float: left;
          & + .form_control {
            float: right;
            width: 65%;
          }
        }
      }
    }
  }
}
.bankItemlogo {
  .bankImageContainer {
    min-width: 120px;
    img {
      height: 38px;
      &.small-logo{
        height: 30px;
      }

      body:not(.ltr) & {
        margin-left: 15px;
      }
      body.ltr & {
        margin-right: 15px;
      }
    }
  }
  .bankDesc {
    padding-top: 6px;
    font-size: 14px;
  }
}

span.terminal-id {
  font-size: 12px;
  margin: 0 5px;
  color: $blueLink;
}
.no-padding.ant-btn-link {
  padding: 0;
}

//modal:
.itours-modal {
  //display: none;
  visibility: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  &.visible {
    //display: block;
    visibility: visible;
    .modal-inner {
      visibility: visible;
      opacity: 1;
    }
  }

  .modal-overlay {
    background: #0000008a;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    backdrop-filter: blur(3px);
  }
  .modal-inner {
    position: absolute;
    @media (min-width: $medium) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 1100px;
      border-radius: 3px;
      &.modal-rounded{
        border-radius: 15px;
      }
    }
    background: #fff;
    width: 100%;
    transition: all 0.2s;
    visibility: hidden;
    opacity: 0;
    &.xsmall-width{
      width: 400px;
      max-width: 100%;
    }
    &.small-width{
      width: 500px;
      max-width: 100%;
    }
    &.medium-width{
      width: 800px;
      max-width: 100%;
    }
    @media (max-width: $medium_) {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: translate(0);
    }
  }
  .modal-header {
    border-bottom: 1px solid #bbb;
    padding: 10px 15px;
    font-size: 17px;
    &.bg-yellow{
      background-color: $naminTravelYellow;
      border-bottom: 0 none;
      font-weight: 600;
    }
  }
  .modal-footer {
    border-top: 1px solid #bbb;
    padding: 10px 15px;
    text-align: right;
  }
  .modal-main {
    padding: 15px;
    &.no-padding{
      padding: 0;
    }
    &.large-padding{
      padding: 25px;
    }
    max-height: calc(100vh - 140px);
    min-height: 200px;
    overflow: auto;
    @media (max-width: $medium_) {
      max-height: 100vh;
      height: calc(100vh - 99px);
      .flightDetails {
        position: static;
        max-height: 100vh;
        overflow: unset;
      }
    }
  }
}
//tracing block style:
.tracking {
  padding: 15px;
  z-index: 10;
  position: absolute;
  background: #fff;
  min-width: 300px;
  margin-top: 10px;
  body:not(.ltr) & {
    left: 0;
  }
  body.ltr & {
    right: 0;
  }
  box-shadow: 1px 1px 5px 0 $border-color;
  @media (min-width: $medium) {
    margin-top: 5px;
    width: 300px;
  }
  border-radius: 3px;
  border: 1px solid #ddd;
  top: 100%;
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -5px;
    width: 9px;
    height: 10px;
    border-top: 1px solid $border-color;
    z-index: 11;
    border-left: 1px solid $border-color;
    transform: rotate(45deg);
    clip-path: polygon(0 0, 100% 0, 0 100%);

    body:not(.ltr) & {
      left: 10px;
    }
    body.ltr & {
      right: 10px;
    }
    background: #fff;
  }
}
.tracking-order-holder {
  @media (min-width: $medium) {
    position: relative;
  }
  .button {
    @media (max-width: $medium_) {
      position: relative;
      top: 5px;
    }
  }
}
.triptype-radio-group {
  display: flex;
  gap: 10px;
}
.triptype-radio-item {
  margin-bottom: 15px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  @media (max-width: $medium_) {
    margin-bottom: 10px;
    width: 33.33%;
    text-align: center;
    .triptype-radio-label {
      border: 2px solid #fff;
      .body & {
        display: flex;
      }
      align-items: center;
      justify-content: center;
      font-size: 12px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 8px 0px;
      .body & {
        padding: 5px;
      }
    }
    .triptype-radio:checked + .triptype-radio-label {
      border-color: rgb(54, 101, 172);
      .body & {
        background-color: #ebeff0;
      }
    }
  }


  .triptype-radio {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    cursor: pointer;
    display: block;
    z-index: 1;
    opacity: 0;
    font-size: 16px;
    &:checked + .triptype-radio-label {
      background-color: rgba(255, 255, 255, 0.15);
    }
  }
  .triptype-radio-label {
    display: block;
    padding: 10px 12px;
    border-radius: 5px;
    line-height: 24px;
  }
}
.body .container {
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #999;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #999;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: #999;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: #999;
  }
  .ant-calendar-picker-input {
    border-color: $border-color;
  }
}

//tab style:

.itours-tab-holder {
  @media (min-width: $medium) {
    border: 1px solid #ccc;
    border-top: 0 none;
  }
  .itours-tab-contents {
    padding: 15px;
    background: #fff;
    border-top: 0;
  }
  .itours-tab-list {
    margin: 0 -7px;
    padding: 0 15px 15px;
    .itours-tab-btn {
      line-height: 18px;
      background-color: #fff;
      border-radius: 2px;
      display: inline-block;
      padding: 10px 12px;
      border: 2px solid #fff;
      margin: 17px 7px 0;
      box-shadow: rgba(2, 100, 122, 0.1) 0px 1px 8px 0px,
        rgba(0, 0, 0, 0.1) 0px 1px 8px 0px;
      color: #02647a;
      font-weight: 600;
      font-size: 12px;
      outline: none;
      @media (max-width: $small_) {
        padding: 5px;
        margin: 15px 3px 0;
        min-width: 0;
      }
      &.active {
        border: 2px solid #81b2bd;
        font-weight: 600;
        background-color: rgba(69, 197, 208, 0.09);
      }
    }
  }
}

.hotel-room-table {
  width: 100%;
  td {
    border: 1px solid #e0e0e0;
    padding: 15px;
    vertical-align: top;
    .domestic-hotel-availibility-item & {
      @media screen and (min-width: $small){
        width: 25%;
      }
    }
  }
  th {
    padding-bottom: 5px;
  }
  .old-price .price {
    font-size: 14px;
    span{
      text-decoration: line-through;
    }
  }
  .new-price .price {
    font-size: 18px;
  }
}
.multy-rooms-titles {
  .items {
    display: inline-block;
    margin-bottom: 15px;
    body:not(.ltr) & {
      margin-left: 30px;
    }
    body.ltr & {
      margin-right: 30px;
    }
  }
  .room-image {
    width: 50px;
    body:not(.ltr) & {
      margin-left: 10px;
    }
    body.ltr & {
      margin-right: 10px;
    }
  }
  strong {
    margin-bottom: 10px;
    display: block;
    color: #333;
  }
}
.hotel-room-table {
  @media (max-width: $small_) {
    display: block;
    .option-item {
      display: block;
      margin-bottom: 20px;
    }
    tbody,
    tr,
    td {
      display: block;
      &:not(:last-child) {
        border-bottom: 0 none;
      }
    }
  }
}
.hotel-main-1photo {
  max-height: 400px;
  object-fit: cover;
}
.pair-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  align-items: center;
}

//theme default
.color-1-btn {
  @extend .blue-btn;
}
.color-1 {
  @extend .blue;
}
@media (min-width: $medium){
  .selected-package {
    border: 10px solid #4caf50;
  }
  .mobile-tab-hotel-flight .home-tabs-holder .home-tab{
    body:not(.ltr) & {
      margin-left: 1px;
    }
    body.ltr & {
      margin-right: 1px;
    }
  }
}
@media (max-width: $medium_) {
  .triptype-radio-group {
    .triptype-radio-item{
      white-space: nowrap;
      width: auto;
      flex: 1 1;
      display: flex;
      flex-direction: column;
      .triptype-radio-label{
        flex: 1 1;
      }
    }
  }
  .mobile-tab-hotel-flight {
    background: #004ba8;
    .body &{
      padding: 0;
    }
    .home-tabs-holder {
      text-align: center;
      display: flex;
      justify-content: space-between;
  
      .home-tab{
        padding: 7px;
        border-radius: 0;
        font-size: 13px;
        background-color: transparent;
        display: block;
        flex: 1 1;
        color: #fff;
        .itours-icon{
          margin: 0 auto 5px;
          display: block;
        }
        &.home-active-tab {
          background-color: #fff;
          color:#034fa6;
        } 
      }
    }
}
}

.under-construction-image {
  width: 150px;
  margin-bottom: 20px;
  margin-top: 35px;
}
.large-title {
  font-size: 30px;
  font-weight: 600;
  @media screen and (max-width: $medium){
    font-size: 22px;
  }
  @media screen and (max-width: $small){
    font-size: 18px;
  }
}
.medium-title{
  font-size: 19px;
  font-weight: 600;
  @media screen and (min-width: $small){
    font-size: 20px;
  }
  @media screen and (min-width: $medium){
    font-size: 22px;
  }
}
.captchaHolder {
  border: 1px solid #bcc7c4;
  height: 40px;
  text-align: center;
  background-image: url('../image/captha-bg.jpg');
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-tag.itours-tag {
  padding: 0 4px;
  margin: 0 0 4px;
  line-height: 17px;
  body:not(.ltr) & {
    margin-left: 5px;
  }
  body.ltr & {
    margin-right: 5px;
  }
}
.timer-modal-text{
  line-height: 22px;
  font-size: 13px;
  margin-bottom: 20px;
}

.namads-holder {
  & > a, &> span,&>img {
    width: 33.3%;
    display: inline-block;
    vertical-align: middle;
    padding: 0 5px;
    img{
      max-width: 100%;
    }
  }
}

.multi-datepicker-input{
  border: 1px solid #bcc7c4;
  border-radius: 3px;
  padding: 0 10px;
  outline: none;
//    font-size: 15px;
  body:not(.ltr):not(.style-2):not(.style-3) &{
    padding-right: 35px;
    background-position: right 7px top 10px;  
  }  
  body.ltr:not(.style-2):not(.style-3) &{  
    padding-left: 35px;
    background-position: left 7px top 10px;  
  }
  body.body .detail-search-holder &{
    padding: 0 4px;
  }
  .body .flight-date-item &{
    @media screen and (min-width: $medium_){
      padding:10px;
      text-align:center;
    }
  }
  .departure-date &,.checkin-column &{
    
    body:not(.ltr) &{
      background-image: url("../image/left-date.svg");
    }  
    body.ltr &{  
      background-image: url("../image/right-date.svg");
    }   
  }
  .return-date &,.checkout-column &{
    body:not(.ltr) &{
      background-image: url("../image/right-date.svg");
    }  
    body.ltr &{  
      background-image: url("../image/left-date.svg");
    }  
  }
  background-size: 22px;
  background-repeat: no-repeat;
   


}
.rmdp-week, .rmdp-ym {
  justify-content: space-between;
}
.rmdp-day{
  .rmdp-week &{
    width: 38px;
    height: 38px;
  }
  .sd{
    padding: 1px;
    border-radius: 0;
    background-color: #e5e5e5;
    border: 0;
    margin: 0;
    transition: all .1s linear;
  }
  &.rmdp-today .sd{
    background-color: #e5e5e5;
    color: inherit;
  }
  &.rmdp-disabled .sd {
    color: rgba(0,0,0,.25) ;
    background: #f5f5f5 ;
    border: 1px solid transparent;
    border-radius: 0;
    cursor: not-allowed;
  }
  &.rmdp-selected .sd{
    background-color: #0077a3 !important;
    color: #fff;
  }
  &:not(.rmdp-disabled):not(.rmdp-day-hidden):not(.rmdp-selected) .sd:hover{
    background-color: #bcc7c4;
    color: inherit;
  }
}
.rmdp-week:first-child {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  .rmdp-week-day{
    color: #bbb;
  }
}
.rmdp-header .rmdp-arrow-container{
  background: transparent;
  box-shadow: none;
  .rmdp-arrow{
    border-color: #777;
    height: 10px;
    width: 10px;
  }
  &.rmdp-arrow-container.disabled .rmdp-arrow{
    border-color: #ddd;
  }
}
.arabic-multi-datepicker-input{
  font-family: sans-serif !important;
}
.rmdp-container{
  position: relative;
  width: 100%;
}

.styled-date-holder {
  background: #fff;
  border: 1px solid #bcc7c4;
  height: 92px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 0;
  align-items: center;
  .departure-date &,.checkin-column &{      
    body:not(.ltr) &{
      background-image: url("../image/left-date.svg");
      border-radius: 0 4px 4px 0;
    }  
    body.ltr &{  
      background-image: url("../image/right-date.svg");
      border-radius: 4px 0 0 4px;
    } 
    .multy-dest-route &{
      body.body &{
        border-radius: 4px;
      }  
    }      
  }
  .return-date &,.checkout-column &{
    body:not(.ltr) &{
      background-image: url("../image/right-date.svg");
      border-radius: 4px 0 0 4px;
    }  
    body.ltr &{  
      background-image: url("../image/left-date.svg");
      border-radius: 0 4px 4px 0;
    }  
  }
  .ant-calendar-picker .anticon{
    display: none;
  }
  background-size: 22px;
  background-repeat: no-repeat;
  body:not(.ltr) &{
    background-position: right 7px top 10px;  
  }  
  body.ltr &{  
    background-position: left 7px top 10px;  
  } 
  .PDatePicker-input,.ant-calendar-picker-input,.multi-datepicker-input {
    position: relative;
    opacity: 0;
    right: 0;
    top: 0;
    cursor: pointer;
    z-index: 1;
  }
  .styled-date-inner-wrapper {
    position: absolute;
    z-index: 0;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .styled-date {
    font-size: 12px;
    padding: 0 10px;
    &.styled-date-date {
      font-size: 40px;
      line-height: 25px;
      margin-top: 5px;
    }
  }
}


.flight-details-btns-inner button {
  padding: 0 10px;
  line-height: 37px;
  min-width: 0;
  margin-top: 3px;
  margin-bottom: 3px;
}
.flight-details-holder {
  padding: 10px 15px;
  border-top: 1px solid #ddd;
}

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #00000066;
    z-index: 2;
    &+ .flight-result-item{
      position: relative;
      z-index: 3;
      .package.search-result-page &{
        position: static;
      }
    }
}
.body .table-itinery-details{
  .itinerary-header{
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
  }
  .itours-table {
    font-size: 14px;
    th{
      color: #fff;
      font-weight: 600;
      border: 1px solid;
      background: #565656;
    }
    th,td {
      padding: 7px 10px;
      border: 1px solid #ddd;
      line-height: 1.3;
      text-align: center;
    }
  }
  .stop-info {
    background-color: #fff5e3;
    padding: 10px 15px;
    font-size: 14px;
    line-height: 15px;
    b {
      font-weight: 600;
    }
    &.change-airport td {
      background-color: $purple;
      color: #fff;
    }
  }
}
.no-wrap {
  white-space: nowrap;
}
.responsive-table-holder{
  overflow: auto;
}
.loading-full-page {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000061;
  z-index: 1000;
  @media screen and (max-width: $small_){
    background-color: #fff;
  }
  .loading-section {
    background-color: #fff;
    padding: 50px;
    border-radius: 10px;
    min-width: 350px;
  }
  &.style2{
    background: #ffffffd9;
    .loading-section{
      background-color: transparent;
    }
  }
}

.logo-loading {
  position: relative;
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    background-image: url('../image/loading.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-animation: rotation 0.9s linear infinite;
    animation: rotation 0.9s linear infinite;
  }
  img {
    margin-bottom: 0;
    position: absolute;
    left: 50%;
    margin-left: -25px;
    top: 50%;
    margin-top: -25px;
    width: 50px;
    height: 50px;
  }
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.baggage-cell {
  line-height: 20px;
  font-size: 14px;
  div{
    line-height: 23px;
    font-size: 13px;
  }
}
.diet-passenger-item-inner{
  padding: 10px 35px;
  @media screen and (max-width: $small_){
    padding: 10px 0;
  }
  .passenger-title{
    font-size: 17px;
    font-weight: 600;
  }
}
.special-diet-passenger-item-col{
  @media (min-width: $large){
    &:nth-child(3n+1){
      body:not(.ltr) &{
        clear: right;
      }  
      body.ltr &{  
        clear: left;
      } 
    }
  }
  @media (min-width: $small) and (max-width:$large_){
    &:nth-child(2n+1){
      body:not(.ltr) &{
        clear: right;
      }  
      body.ltr &{  
        clear: left;
      } 
    }
  }
}

.wheelchair-block-title {
  background: #9400d4;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  margin: 10px 0 0;
}
.wheelchair-block label.checkbox-label {
  color: #9400d4;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  &::before{
    width: 15px;
    height: 15px;
    border: 1px solid #9400d4;
    body:not(.ltr) &{
      margin-right: 5px;
    }  
    body.ltr &{  
      margin-left: 5px;
    } 
  }
}
.wheelchair-block .checkbox-holder .custom-checkbox:checked + .checkbox-label::before {
  background-image: url("../image/icons.svg");
  background-size: 3200% 600%;
  background-repeat: no-repeat;
  background-position: 54.8% 20%;
}

.package-searchbox-holder.search-box-holder .form-group .form-select.room-option-toggle-select {
  height: 92px;
}
.package-avails-row , .selected-package {
  .route-item .router .date {
    white-space: nowrap;
  }
  .route-item .route-item-flex .air-line-info {
    width: auto;
  }
}
.package-hotel-item {
  background-color: #fff;
  .selected-package &{
    background-color: transparent;
  }
  color: #999;
  margin-bottom: 15px;
  line-height: 20px;
  width: 100%;
  border: 1px solid #bcc7c4;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  .hotel-item-header {
    @extend .clearfix;
    padding: 10px;
  }
  .package-hotel-name {
    text-align: left;
    direction: ltr;
    float: right;
    width: calc(100% - 90px);
  }
  .package-hotel-image {
    background-size: cover;
    width: 80px;
    float: left;
    height: 80px;
    margin-right: 10px;
  }
  .package-hotel-footer {
    border-top: 1px solid #dddddd;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .package-hotel-details {
    padding: 0 15px 10px;
  }
  .hotel-name {
    font-weight: 600;
    color: #333;
    display: block;
    margin-bottom: 0;
    font-size: 18px;
    @media (min-width: $large){
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .price-for-night {
    line-height: 1;
  }
  span.price {
    color: #333;
  }
  .old-price {
    font-size: 13px;
    span.price {
      color: #ccc;
    }
  }
}
.package-avails-row .flight-result-item .item-footer ,.package-hotel-item {
  span.price {
    color: #555;
  }
}
.package-hotel-main {
  body:not(.ltr) &{
    min-height: 169px;
  }  
  body.ltr &{  
    min-height: 171px;
  } 
}
.selected-package {
  padding: 15px;
  .package-hotel-item,.flight-result-item {
    border: 0 none;
    margin: 0;
  }
  .flight-result-item {
    position: relative;
    &::before,&::after{
      content:"";
        position: absolute;
      display: block;
      width: 1px;
      top: 0;
      bottom: 0;
      background-color: $red;
    }
    &::before{
      left: -15px;
    }
    
    &::after{
      right: -15px;
    }
  }  
  .item-footer,.package-hotel-footer{
    padding: 10px 0 0;
  }
  .package-hotel-details{
    padding: 0;
  }
  .hotel-item-header{
    padding: 0 0 10px;
  }
  .flight-result-item .item-main .content{
    padding: 0;
  }
  .package-hotel-main {
    
    body.body &{
      min-height: 141px;
    }  
  }
  .route-item .router {
    padding: 0;
  }
  span.price {
    color: #333;
  }
  .old-price {
    font-size: 13px;
    span.price {
      color: #ccc;
    }
  }
  .selected-package-footer {
    @media screen and (min-width: $medium){
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 182px;
      align-items: center;
      position: relative;
      padding-bottom: 47px;
      .button{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
      }
    }
  }
}
.package-total-price .price{
  font-size: 22px;
}
@media screen and (max-width: 370px){
  .stop-info.text-center{
    .duration, .stop ,.dashed-line::before {
      display: none;
    }
  }
}
@media screen and (max-width: $medium_){
  .only-flight-filter.filters-holder .package-hotel-filter {
    display: none;
  }
  .only-hotel-filter.filters-holder .package-flight-filter {
    display: none;
  }  
  .body .top-circle-close-btn {
    min-width: 0;
    position: fixed;
    z-index: 1;
    top: 10px;
    left: 50%;
    margin-left: -21px;
    outline: none !important;
    background: #fff;
    border: 1px solid #bbb;
    @include box-shadow(0 3px 10px 0 rgba(0, 0, 0, 0.2));
    border-radius: 50px;
  } 
  .package-avails-row .filters-holder{
    padding: 0;
  } 
  .package-flight-list,.package-hotel-list {
    position: fixed;
    background: #fff;
    top: 20px;
    left: 0;
    right: 0;
    z-index: 100;
    padding: 65px 10px 70px;
    visibility: hidden;
    opacity: 0;
    transition: all .2s linear;
    &.isOpen{
      visibility: visible;
      opacity: 1;
      top: 0;
    }
    overflow-y: auto;
    height: 100vh;
  }
  .package.search-result-page .route-item .air-line-info .airline-logo-holder {
    body:not(.ltr) &{
      margin-left: 5px;
    }  
    body.ltr &{  
      margin-right: 5px;
    } 
    .airline-logo{
      width: 35px;
      height: 35px;
    }
  }
  .selected-package .stop-info.text-center{
    .duration, .stop ,.dashed-line::before {
      display: none;
    }
  }
  .selected-package-mobile-action-footer {
    display: flex;
    justify-content: space-between;
  }
  .package-total-price.text-center.bold {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .price{
      font-size: 17px;
    }
  }
  .package-hotel-main {
    min-height: 0 !important;
    padding-bottom: 10px;
  }
  .selected-package {
    .package-hotel-footer,.flight-result-item .item-footer {
      display: none;
    }
    .flight-result-item{
      border-top: 5px solid #e0e0e0;
      border-bottom: 5px solid #e0e0e0;
      &::after,&::before{
        display: none;
      }
    }
  }
  
}
@media screen and (max-width: $small_){
  .flight-result-item .item-footer button.button {
    padding: 3px 10px;
    min-width: 0;
    &.link-style-btn{
      padding: 3px 0;
    }
    &:after{
      display: none;
    }
  }
  .flight-detail-btn {
      white-space: nowrap;
  }
  .router .date strong {
    font-size: 13px;
  }
  .selected-package-mobile-action-footer .button {
    padding: 2px 5px;
  }
  .selected-package{
    border-right: 0 none;
    border-left: 0 none;
    margin-left: -10px;
    margin-right: -10px;
}  
.package.search-result-page .searchShow{
    display: block;
    padding-bottom: 0;
    .originShow, .typeShow, .destinationShow{
      display: inline-block;
      body:not(.ltr) &{
        margin-left: 10px;
      }  
      body.ltr &{  
        margin-right: 10px;
      } 
    }
    .passShow, .retShow, .depShow {
      display: flex;
      text-align: left;
      justify-content: space-between;
      margin: 0;
      line-height: 1.5;
    }
    .button {
      margin-top: 5px;
      height: 25px;
      line-height: 25px;
      padding: 0 15px;
    }
  }

}
body.body .package.search-result-page .page-container .filters-holder {
  margin: 0;
}
.package.search-result-page .filters-holder {
  .filter-header {
    margin: 0;
    padding: 10px;
    background: #eaa200;
    color: #fff;
    @media screen and (max-width: $medium_) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      z-index: 1;
      .package-filter-close-btn {
        background: transparent;
        min-width: 0;
      }
      &+div{
        padding-top: 65px;
      }
    }
  }
}
.package.search-result-page .route-item .stop-info .duration > span:first-child {
  display: none;
}
.blue-border{
  border: 2px solid #5bb4c8;
}
.capitalized{
  text-transform: uppercase;
}
.no-margin-bottom{
  margin-bottom: 0 !important;
}
.package.search-result-page .overlay{
  background-color: rgba(0, 0, 0, 0.85);
  background-image: url("../image/pixels.png");
}
.package.search-result-page .flightDetails {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 1000px;
  z-index: 10;
  .itours-tab-contents{
    border-radius: 0 0 10px 10px;  
    max-height: 500px;
    max-height: calc(100vh - 100px);
    overflow: auto;
  }
  .itours-tab-holder .itours-tab-list{
    padding-left: 0;
    padding-right: 0;
    background: transparent;
    .itours-tab-btn{
      background-color: #23a5b9;
      color: #fff;
      outline: none;
      border: none;
      padding: 5px 15px;
      font-weight: 600;
      line-height: 30px;
      border-radius: 10px 10px 0 0;  
      &:not(:first-child){
        margin: 0 2px;
      }
      &:hover{
        background-color:#1892a5;
      }    
      &.active {
        background-color: #fff;
        color: #23a5b9;
        position: relative;
        bottom: -1px;
        &:hover{
          background-color:#fff;
        }    
      }
    }
  }
  @media screen and (max-width: $medium_){
    transform: translate(0,0);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    min-width: 0;
    width: auto;
  }
}

.filter-antd-checkbox-item .ant-checkbox-wrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  line-height: 1.25;
  &> span {
    display: block;
  }
}
.package-avails-row .ant-checkbox-group {
  width: 100%;
}
.time-filters-group{
  display: flex;
  span.ant-checkbox {
    display: none;
    &+span{
      display: block;
      line-height: 12px;
      color: #02647a;
      border: 1px solid #fff;
      border-color:transparent;
      border-radius: 3px;
      padding: 2px;
      width: 100%;
      font-size: 12px;      
    }
    
  }
  .ant-checkbox-wrapper-checked span.ant-checkbox+span{
    border-color:#02647a ;
  }
  label.ant-checkbox-wrapper {
    display: block;
    padding: 0 5px;
  }
  span.itours-icon {
    margin-bottom: 8px;
  }
  .time-antd-filter-item {
    display: block;
    flex: 1 1;
    text-align: center;
  }
}
.package-avails-row .check-green {
    width: 37px;
    height: 37px;
}
.package-selected-hotel-holder,.package-selected-flight-holder{
  &.updatingFlight,&.updatingHotel{
    animation: selectPackageItemAnimation .8s cubic-bezier(0.71, 0.28, 0.43, 1.45);
  }
}
@keyframes selectPackageItemAnimation {
  0%   {transform:scale(.5) rotate(8deg);opacity: 0; }
  35%   {transform:scale(.5) rotate(8deg);opacity: 0; }
  100%  {transform:scale(1) rotate(0);opacity: 1; }
}
.package-passengers-form{
  .package-passenger-item{
    border-bottom:1px solid #bbb;
  }
  .RoomItem:last-child .package-passenger-item:last-child{
    border-bottom:0 none;
  }
  .RoomItem:nth-child(even) {
    background: #f1f1f1;
  }
  input[type="email"], input[type="password"], input[type="text"], select{
    height: 40px;
    outline: none !important;
  }
  .ant-col.ant-form-item-label {
    line-height: 20px;
  }
  .genderNameGroup .ant-input-group-addon {
    padding: 0;
    border: 0 none;
    select{
      outline: 0 none;  
      width: 100px;    
      body:not(.ltr) & {
        border-left:0 none;
      }
      body.ltr & {
        border-right:0 none;
      }
    }
  }
  .countryCodeGroup {
    .ant-input-group{
      direction: ltr;
    }
    .ant-input-group-addon {
      padding: 0;
      border: 0 none;
      select{
        outline: 0 none;  
        width: 160px;
        font-size: 13px;    
        border-right:0 none;
      }
    }    
  }
}
.flight-detail-main .itours-tab-contents {
  min-height: 250px;
}
.price .value {
  direction: ltr;
  display: inline-block;
}
form.package-passengers-form textarea {
  height: 100px;
}
a.itours-no-card-link {
  display: block;
  background:url("../image/logo-white.png"), linear-gradient(45deg,#cc281c, #9c27b0);
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  background-repeat: no-repeat;
  background-position: top 10px right 20px,center;
  background-size: 90px,100%;
  outline: none;
  border: 0 none !important;
  margin-bottom: 20px;
}
.login-to-book-modal{
  .user-login{
    display: block;
    margin: 20px auto 35px;
  }
  .normal-title{
    color: #333;
    text-align: center;
    font-size: 21px;
    font-weight: bold;
  }
}
.naminTravelEthnicAirlineFilter {
  margin-top: 3px 0;
  padding: 5px 10px;
  line-height: 1.1;
  position: relative;
  color: #fff;
  background-color: #b25067;
  ul{
    position: relative;
    padding: 0;
    margin: 0 0 5px;
    font-size: 14px;
    list-style-position: inside;
  }
  @media screen and (min-width: $medium_) {
    float: left;
    height: 100px;
    // &::before{
    //   content: "";
    //   display: block;
    //   position: absolute;
    //   right: 0;
    //   left: auto;
    //   top:0;
    //   bottom: 0;
    //   background-color: #b25067;
    //   width: 100vw;
    // }
  }
}
.bank-group-item .normal-title {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #bbb;
  width: 100%;
  line-height: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  .bank-group-icon{
    height: 30px;
    max-width: 100px;
    object-fit: contain;
  }
  p{
    color: #bbb;
    font-size: 15px;
    margin-bottom: 0;
    font-weight: normal;
  }
}
.gateway-form {
  margin: 15px -15px 0;
  &::after,&::before{
    content: "";
    display: table;
    clear: both;
  }
  .submit-container{
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    clear: both;
  }
  .form-group{
    width: 50%;
    padding-left: 15px;
    padding-right: 15px;
    body:not(.ltr) & {
      float: right;
    }
    body.ltr & {
      float: left;
    }
  }
}
.ant-form-explain {
  text-align: justify;
  margin-top: 5px;
}

.price-calendar.itours-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #bbb;
  background: #fff;
  text-align: center;
  font-size: 14px;
  @media screen and (max-width: $medium_) {
    font-size: 12px;
  }
  th,
  td {
    line-height: 20px;
    border: 1px solid #bbb;
    padding: 5px;
    height: 50px;
    transition: all 0.1s linear;
    .itours-table-holder &{
      text-align: center;
    }
    img {
      height: 40px;
      display: block;
      margin: auto;
      background: #fff;
      padding: 3px;
      border-radius: 4px;
      margin-bottom: 5px;
      @media screen and (max-width: $medium_) {
        height: 30px;
      }
    }
  }
}
.price-calendar-th {
  position: relative;
  .h-label {
    position: absolute;    
    body:not(.ltr) & {
      left: 8px;
    }
    body.ltr & {
      right: 8px;
    }
    top: 5px;
    white-space: nowrap;
  }
  .v-label {
    position: absolute;    
    body:not(.ltr) & {
      right: 8px;
    }
    body.ltr & {
      left: 8px;
    }
    bottom: 5px;
    white-space: nowrap;
  }
  .diagonal-divider {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: #bbb;
    body:not(.ltr) & {
      clip-path: polygon(100% 0, 0 100%, 0 calc(100% - 1px), 100% -1px);
    }
    body.ltr & {
      clip-path: polygon(0 -1px, 100% calc(100% - 1px), 100% 100%, 0 0);
    }
    
  }
}
.price-calendar-hover,
.price-calendar-td:hover {
  background-color: #e9e9e9;
}
.price-calendar {
  .price-calendar-cheapest {
    background-color: #4caf5045;
  }
  .price-calendar-td.price-calendar-selected {
    background-color: #0d8ddc;
    color: #fff;
  }
}
.page-container .get-passenger-form-holder .multi-datepicker-input {
  padding-left: 10px;
  padding-right: 10px;
}
.extraBaggageBadge {
  background: #9c27b0;
  background: linear-gradient(45deg, #9c27b0, #e91e63);
  padding: 3px 12px 6px;
  border-radius: 19px;
  color: #fff;
  display: inline-block;
  white-space: nowrap;
  font-size: 15px;
  font-weight: 600;
}
.package-hotel-footer-end{
  display: flex;
}
.summary-flight-type-row {
  border-top: 1px solid #ccc;
  margin-top: 1.5rem;
  padding-top: 1rem;
}
img[src*="safarmarket"]:not(.bank-item-logo):not(.bank-group-icon) {
  position: fixed;
  z-index: -10;
}
img.bank-item-logo {
  max-width: 100px;
  object-fit: contain;
}
.remained-time-wrapper{
  display: flex;
  align-items: center;
  font-size: 20px;
  justify-content: space-between;
  letter-spacing: 2px;
  color: $purple;  
  flex-direction: row-reverse;
}
.itours-phoneNumber-row-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    flex-wrap: wrap;
    .footer &{
      display: block;
      margin-bottom: 0;
      margin-top: 15px;
      font-size: 14px;
      line-height: 25px;
    }
}
.terms-collapse .ant-collapse-item {
  margin-bottom: 10px;
  .body .main-content &{
    @media screen and (min-width:$small){
      font-size: 16px;
    }
  }
  .ant-collapse-header {
    background: #f1f1f1;
    padding: 5px 10px;
    @media screen and (min-width:$small) {    
      font-size: 18px;
      padding: 10px 15px;
    }
    border-radius: 5px;
  }
}
.red-bullet-list > li {
  @media screen and (min-width:$small) {    
    font-size: 16px;
    line-height: 27px;
    margin-bottom: 10px;
  }
  &::marker {
    color: #ed4151;
    font-size: 18px;
  }
}
.terms-collapse {
  .ant-collapse-item .ant-collapse-header {
    padding-left: 40px;
    .body &{
      border-radius: 5px;
    }
    border: 1px solid #f1f1f1 ;
    transition: all .2s linear;
    .anticon{
        display: initial;
    }
    .custam-css-arrow {
      display: block;
      width: 8px;
      height: 8px;
      border-bottom: 2px solid #034fa5;
      border-right: 2px solid #034fa5;
      transform: rotate(45deg);
      top: 50%;
      margin-top: -5px;
      transition: all .2s linear;
    }
  }
  .ant-collapse-item.ant-collapse-item-active .ant-collapse-header {
    border: 1px solid #034fa5 ;
    .custam-css-arrow{
      transform: rotate(225deg);
      margin-top: -2px;
    }
  }
  .airline-item {
    font-weight: bold;
    padding: 0 10px;
    white-space: nowrap;
    &:only-child{
      padding: 0;
      .airline-title{
        @media screen and (max-width:$small_){
          display: block;
          &+span{
            margin: 5px 0 0;
          }
        }
      }
    }
    .airline-logo {
      display: block;
      width: 22px;
      height: 22px;
      &.large{
        width: 40px;
        height: 40px;
        margin: auto;
      }
      object-fit: contain;
      font-size: 10px;
      font-weight: normal;
      color: #bbb;
    }
  }
  .table-2 {
    font-size: 14px;
    td.bg-gray,tr.bg-gray>td{
      background-color: #f6f6f6;
    }
  } 
  .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 20px;
    @media screen and (min-width:$small){
      padding-left: 15px;
      padding-right:15px;
    }
} 
}
.table-2 {
  width: 100%;
  .width-400{
    width: 400px;
    min-width: 130px;
  }
  .airline-col {
    min-width: 60px;
    @media screen and (min-width:$small){
      min-width: 130px;
    }
  }
  th,td{
    border: 1px solid #ccc;
    padding: 8px 10px;
    line-height: 22px;
  }
  th,.th{
    background-color: #13406b !important;
    color: #fff;
    font-weight: 600;
  }
}
.bold-frame {
  border: 2px solid #13406b !important;
}
.frequent-dest-heading {
  padding: 10px 15px;
  text-align: center;
  border-bottom: 2px dotted #ccc;
  font-weight: 600;
  font-size: 15px;
  margin: 0;
  background-color: #f6f6f6;
}
.rmdp-calendar-container-mobile {
  width: 100%;
  background: #fff;
  top: 0;
  bottom: 0;
  padding-top: 45px;
  .rmdp-wrapper{
    box-shadow: none;
    margin: auto;
  }
  .rmdp-action-buttons .rmdp-action-button{
    display: none;
  }
  .date-picker-title {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 10px 0;
    background: #e5e5e5;
  }
}
.layout-holder.no-scroll{
  overflow: hidden;
  height: 100vh;
}
.body .verification-inputs-wrapper{
  input {
    font-size: 30px;
    text-align: center;
    height: 50px;
    border-radius: 5px;
    transition: all .1s linear;
    box-shadow: 0 0 3px 0 #00000025;
    &:focus-visible{
      outline: none;
      border-color: #bbb;
      box-shadow: 0 1px 6px 1px #00000044;
    }
  }
  .has-error input{
    border-color: red;
  }
  .ant-form-explain{
    margin: 0;
    min-height: 0;
  }
}
.body button.close-ir-login-modal {
  background: transparent;
  min-width: 0;
  width: 30px;
  height: 30px;
  padding: 0;
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 1;
}
.margin-bottom-large-mobile{
  @media screen and (max-width:$small_){
    margin-bottom: 30px;
  }
}
.date-picker-drop {
    direction: ltr;
}

.clear-medium-2:nth-child(2n+1){
  @media screen and (min-width:$medium){
    body:not(.ltr) & {
      clear: right;
    }
    body.ltr & {
      clear:left;
    }
  }
}
.clear-medium-3:nth-child(3n+1){
  @media screen and (min-width:$medium){
    body:not(.ltr) & {
      clear: right;
    }
    body.ltr & {
      clear:left;
    }
  }
}
.clear-large-3:nth-child(3n+1){
  @media screen and (min-width:$large){
    body:not(.ltr) & {
      clear: right;
    }
    body.ltr & {
      clear:left;
    }
  }
}
.clear-medium-4:nth-child(4n+1){
  @media screen and (min-width:$medium){
    body:not(.ltr) & {
      clear: right;
    }
    body.ltr & {
      clear:left;
    }
  }
}

.partners-logos img {
  height: 55px;
  margin: 10px 15px 20px;
  @media screen and (max-width:$medium_){
    height: 40px;
    margin: 10px 5px 20px;
  }
}
.partners-footer {
  text-align: center;
  background: #f1f1f1;
  padding: 10px 0 0;
}
.pipe {
  border-left: 1px solid #ddd;
  height: 24px;
}
.sm-reserve-room-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .message{
    margin-bottom: 0;
  }
  .button{
    margin: 0;
  }
  @media screen and (min-width:$medium){
    display: block;
    text-align: center;
    border-top: 1px solid #ddd;
    margin-top: 1rem;
    padding-top: 1rem;
    .button{
      width: 100%;
    }
    .message{
      margin-bottom: 10px;
    }
  }
}
.sm-fix-bottom-mobile {
  @media screen and (max-width:$medium_){
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    padding: 15px;
    z-index: 10;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.09);
  }
}
.sm-check-in-out-wrapper {
  display: flex;
  justify-content: space-between;

  &>div{
    @media screen and (min-width:$medium_){
      display: flex;
      justify-content: space-between;
      flex: 1;
      align-items: center;
    }
  }
}
.night-on-gallery {
  position: absolute;
  top: 20px;
  left: 20px;
  background: #333;
  color: #fff;
  padding: 0 20px;
  border-radius: 40px;
  height: 44px;
  display: flex;
  align-items: center;
  pointer-events: none;
}
.text-on-gallery {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  color: #fff;
  background: linear-gradient(0deg, #000000bd, transparent);
  padding-top: 50px;
  pointer-events: none;
}
.sm-gallery-wrapper {
  .image{
    border: 0 none;
  }
  @media screen and (min-width:$medium_){
  border-radius: 10px;
  overflow: hidden;
  }
}
.rounded{
  border-radius: 10px;
}
@media screen and (min-width:$medium_){
  .rounded-medium{
    border-radius: 10px;
  }
  .inline-medium {
    display: inline-block;
  }
}
.room-item-footer {
  border-top: 1px solid #efefef;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.gray-tag{
  background: #f5f5f5;
  display: inline-block;
  padding: 8px 10px;
  &.small-tag{
    padding: 2px 7px;
  }
}
.cancellation-collapse-block{
  overflow: hidden;
  &.visible{
    animation: openRules .3s linear forwards;
  }
  &.exiting{
    animation: closeRules .3s linear forwards;
  }
}
@keyframes openRules {
  from{
    max-height: 0;
  }
  to{
    max-height: 400px;
  }
}
@keyframes closeRules {
  from{
    max-height: 400px;
  }
  to{
    max-height: 0;
  }
}
.css-icon {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  position: relative;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  &.down-caret-css-icon::after{
    content: "";
    display: inline-block;
    border: 2px solid;
    width: 10px;
    height: 10px;
    border-top: 0 none;
    border-left: 0 none;
    transform: rotate(45deg);
    position: relative;
    top: -2px;
    transition: all .3s linear;
  }
  &.reverse::after{
    top: 2px;
    transform: rotate(225deg);
  }
}
.selected-room-message {
  line-height: 37px;
}
.selected-room-details {
  @media screen and (min-width:$medium ){
    border-top: 1px solid #ddd;
    margin-top: 15px;
  }
  border-bottom: 1px solid #ddd;
  padding: 15px 0;
  margin-bottom: 15px;
}
.no-select::selection{
  background-color: inherit;
  color: inherit;
}
.pointer{
  cursor: pointer;
}
.selected-room-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (min-width:$medium ){
    display: block;
    text-align: center;
    button{
      width: 100%;
      margin-top: 15px;
    }
  }
}
.z-index-1{
  z-index: 1;
}

.z-index-2{
  z-index: 2;
}
@media screen and (max-width:$medium_ ){
  .mobile-accardion {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all .4s ease-in-out;
    &.show{
      max-height: 350px;
      opacity: 1;
    }
  }
}

.sm-room-item-content {
  display: flex;
  justify-content: space-between;
}
.sm-board-cancellation {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.sm-room-item-rooms {
  .sm-room-item:not(:last-child) {
    margin-bottom: 5px;
    @media screen and (min-width:$medium ){
      margin-bottom: 15px;
    }
  }
  .room-name {
    font-weight: 600;
    display: block;
    margin-bottom: 5px;
    margin-top: 10px;
    @media screen and (min-width:$medium ){
      margin-bottom: 15px;
    }
  }
}
.block{
  display: block;
}
.medium-inline-block{
  @media screen and (min-width:$medium ){
    display: inline-block;
    &.middle{
      vertical-align: middle;
    }
  }
}
.hotel-search-btn.medium-align-label {
  margin-top: 15px;
  @media screen and (min-width:$medium ){
    line-height: 24px;
    margin-top: 25px;
  }
}
.form-label.block+.form-input.nights-fake-input {
  border: 1px solid #bcc7c4;
  width: 100%;
  display: block;
  line-height: 33px;
  padding: 0 7px;
}
.sm-search-room {
  @media screen and (max-width:$medium_ ){
    padding-top: 20px;
  }
  .children-ages-block {
    display: block;
    .form-select{
      display: inline-block;
      width: 50px;
    }
  }
  .form-group .form-select{
    height: 35px;
  }
  .remove-room-btn {
    min-width: 0;
    padding: 0;
    height: 35px;
    width: 35px;
    @media screen and (max-width:$medium_){
      position: absolute;
      top: 8px;
      left: 0;
      height: 25px;
      width: 25px;
    }
  }
  .children-ages{
    display: inline-block;
  }
  .room-guests.multyroom {
    @media screen and (max-width:$medium_){
      position: relative;
      padding-top: 10px;
      .item-number {
        margin-bottom: 10px;
        display: block;
      }
      &::before{
        content: "";
        display: block;
        border-top: 1px solid #ddd;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }
    }
  }
}
.safarmarket-header{
  box-shadow: 0px 2px 19px rgba(0, 0, 0, 0.05);
  z-index: 10;
  .menu-overlay {
    display: block;
  }
}
.safarmarket-header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  .menu-toggle-btn{
    display: block;
    .menu-btn-bar{
      background-color: #000;
    }
  }
  .logo {
    width: 100px;
  }
  .main-menu-holder {
    z-index: 102;
    position: fixed;
    right: 0;
    width: 450px;
    background-color: #fff;
    top: 0;
    bottom: 0;
    box-shadow: 0 0 15px 0 #000000b3;
    transition: all .15s ease-in-out;
    transform: translateX(500px);
    &.expanded{
      transform: translateX(0);
    }
    .menu-toggle-btn{
      width: 25px;
      height: 25px;
      float: left;
      margin: 15px 20px;
      &+.main-menu{
        clear: both;
      }
    }
    .main-menu .menu-item {
      display: block;
      margin: 0;
      a{
        padding: 5px 15px;
        border-top: 1px solid #e5e5e5;
        border-bottom: 0 none;
      }
    }
  }  
}
.safarmarket-footer .namads-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin-bottom: 25px;
  @media screen and (min-width:$large){
    margin-bottom: 0;
  }
}
.safarmarket-footer-inner {
  padding-bottom: 100px;
  @media screen and (min-width:$large){
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
  }
  @media screen and (max-width:$large_){
    .logo-copyright{
      text-align: center;
    }
    .footer-logo {
      margin-bottom: 15px;
    }
  }
  .footer-namad-item {
    border: 2px solid #ccc;
    border-radius: 5px;
    margin: 0 5px;
    height: 70px;
    width: 70px;
    padding: 0;
    img{
      width: 66px;
      height: 66px;
      margin: 0;
      font-size: 10px;
    }
  }
}
.safarmarket-header + main.main-content {
  @media screen and (min-width:$large){
    min-height: calc(100vh - 252px);
  }
}
@media screen and (max-width:$medium_){
  .sm-change-room-btn {
    position: absolute;
    bottom: 15px;
    left: 15px;
  }
  .padding-h-mobile-tablet{
    padding-left: 15px;
    padding-right: 15px;
  }
}

.ant-collapse.header-with-extra {
  .ant-collapse-header {
    display: flex;
    justify-content: space-between;
    cursor: default;
    .ant-collapse-extra{
      float: none;
      flex: 1;
    }
  }
  &.reverse .ant-collapse-header{
    flex-direction: row-reverse;
  }
}

.header-logo-holder {
  text-align: center;
  .logo {
    display: inline-block;
    img {
      width: 100px;
      @media screen and (min-width:$medium) {
        width: 120px;
        padding: 10px 0;
      }
    }
  }
}

.header-main-section {
  @media screen and (min-width:$medium){
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: flex-start;
  }
  .top-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    .itours-select {
      border: 0 none;
      box-shadow: none;
      line-height: inherit;
      height: auto;
      outline: none !important;
      padding: 0 5px !important;
      background-image: none !important;
      cursor: pointer;
    }
  }
}
.bank-group-title {
  display: flex;
  align-items: center;
  img {
    width: 45px;
  }
  .description{
    color: #aaa;
  }
}
.search-form-wrapper .search-box-holder {
  .search-airline-block .form-group .form-input {
    height: 45px;
    padding: 0 12px;
    border-radius: 5px 5px 0 0;
  }
  .cabinClass-and-airline .search-airline-block .form-group .form-input {
    body:not(.ltr) & {
      border-radius: 0 5px 0 0;
    }
    body.ltr & {
      border-radius: 5px 0 0 0;
    }
  }
}


.search-airline-block .autocompleteText .suggestList ul li .link{
  padding-left: 12px;
}

.passengers-table{
  @media (min-width: $small){
    .mobile-label{
      display: none;
    }
  }

  @media (max-width: $small_) {
    table,tr,tbody{
      display: block;
      width: 100%;
    }
    td{
      display: flex;
    }
    thead{
      display: none;
    }
    tr:not(:last-child){
      border-bottom: 1px solid #bbb;
    }
  }
}
.rightPlaceholder {
  body:not(.ltr) & {
    direction: ltr;
    text-align: left;
    &::placeholder {
      text-align: right;
    }

    &:-ms-input-placeholder {
      text-align: right;
    }

    &::-ms-input-placeholder {
      text-align: right;
    }
  }
}
@media (min-width: $medium){
  .hotel-search-form-holder {
    display: flex;
    flex-wrap: wrap;
    .hotel-search-form-main {
      flex: 0 0 80%;
      order: 1;
    }
    .button-holder {
      flex: 0 0 20%;
      order: 2;
      body:not(.ltr) & {
        padding-right: 8px;
      }
      body.ltr & {
        padding-left: 8px;
      }
    }
    .passengers-options-holder {
      flex: 0 0 100%;
      order: 3;
    }
  }
}
.styled-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  &.small{
    li{
      font-size: 13px;
      &::before{
        border-width: 4px 5px;
      }
    }
  }
  li{
    line-height: 1.7;
    &::before{
      content: '';
      display: inline-block;
      border-style:solid;
      border-width: 5px 8px;
      body:not(.ltr) & {
        clip-path: polygon(40% 50%, 100% 0 ,100% 100%);
      }
      body.ltr & {
        clip-path: polygon(0 0%, 60% 50%, 0 100%);
      }
    }
  }
}
.cabinClass-and-airline {
  display: flex;
  & > div {
    flex: 0 0 50%;
    &:first-child{
      body:not(.ltr) & {
        padding-left: 1px;
      }
      body.ltr & {
        padding-right: 1px;
      }
    }
  }
}
.search-form-wrapper .search-box-holder .cabinClass-and-airline .form-group .form-select.class-select{
  body:not(.ltr) & {
    border-radius: 5px 0 0 0;
  }
  body.ltr & {
    border-radius: 0 5px 0 0;
  }
}

.domestic-hotel-item {
  padding: 3px;
  @media (min-width: $large){
    display: flex;
  }
  .gray-tag {
    padding: 5px 10px;
    line-height: 1;
    border: 1px solid #ddd;
  }
  .hotel-image {
    flex: 0 0 200px;
    min-height: 200px;
  }
  .content{
    @media (min-width: $large){
      display: flex;
      justify-content: space-between;
    }
    flex: auto;
    padding: 10px;
  }
  .content-main {
    flex: auto;
  }
  .content-footer {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: space-between;
    @media (max-width: $large_){
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid #ddd;
    }
    @media (min-width: $large){
      flex:  0 0 200px;
      border-right: 1px solid #ddd;
      padding-right: 10px;  
      margin-right: 10px;    
    }
  }
}
.domesticHotelPointHolder {
  display: flex;
  align-items: center;
  align-self: flex-end;
  // &.leftAligned {
  //   .pointNumber {
  //     margin-right: 0;
  //     margin-left: 10px;
  //     .pointCaret {
  //       clip-path: polygon(0 0, 0 100%, 100% 0);
  //     }
  //   }
  // }
  .pointNumber {
    color: #fff;
    padding: 0 5px;
    width: 40px;
    text-align: center;
    line-height: 33px;
    height: 31px;
    margin-right: 10px;
    border-radius: 3px;
    font-size: 20px;
    font-weight: bold;
    position: relative;
    margin-bottom: 5px;
    .pointCaret {
      position: absolute;
      top: 100%;
      width: 8px;
      height: 8px;
      margin-top: -1px;
      clip-path: polygon(0 0%, 100% 0, 100% 100%);
    }
  }
  .pointTitle {
    white-space: nowrap;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: -4px;
  }
  .pointReviews {
    white-space: nowrap;
    font-size: 12px;
    color: #bbb;
  }
}
.bg-light-pink{
  background-color: #ffe7e5;
}
.line-height-22{
  line-height: 22px;
}
.line-height-44{
  line-height: 44px;
}
.guestRatingPlaceHolder{
  height: 56px;
}
.custom-antd-checkbox{
  width: 100%;
  .ant-checkbox-wrapper{
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #02647a;
    border-color: #02647a;
  }
}

.facility-row{
  display: flex;
  align-items: flex-start;
  line-height: 1.7;
}
.facilityIcon {
  width: 50px;
  padding: 15px;
  background: #f1f1f1;
  border: 1px solid #ddd;
  filter: grayscale(1);
  object-fit: contain;
}


.quantity-input-wrapper {
  display: inline-flex;

  .quantity-input {
    border: 0 none;
    width: 40px;
    text-align: center;
    font-size: 17px;
    outline: none;
    cursor: default;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;

    &::selection {
      background-color: transparent;
      color: inherit;
    }
  }
  &.style-b{
    .quantity-input{
      height: 25px;
    }
    .decrease,
    .increase {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: transparent !important;
      border: 1px solid #ff5722;
      transition: all .1s linear;
      path{
        fill: #ff5722;
        transition: all .1s linear;
      }
      &.disabled{
        border-color:#ccc ;
        path{
          fill: #cccccc;
        }
      }
    }
  }
  .decrease,
  .increase {
    width: 35px;
    height: 35px;
  }

  &.small-input {
    .decrease,
    .increase {
      width: 30px;
      height: 30px;
    }
  }
}
svg {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
.decrease,
.increase {
  background: #ddd;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background 0.05s linear;

  &.disabled {
    cursor: no-drop;
    color: #ccc;
    background: #f7f7f7;
  }

  &:not(.disabled):hover {
    background: #bbb;
  }
}
.no-select-highlight{
  ::-moz-selection {
    color: inherit;
    background: transparent;
  }
  
  ::selection {
    color: inherit;
    background: transparent;
  }

  &::-moz-selection {
    color: inherit;
    background: transparent;
  }
  
  &::selection {
    color: inherit;
    background: transparent;
  }

}

.hasPopup .leaflet-popup-content {
  margin: 0;
}

.map-wrapper.leaflet-container {
  font-family: inherit;
}

.map-custom-popup-wrapper{
  .hotel-image {
    height: 130px;
  }
  .popup-content{
    padding: 10px;
  }
}
.leaflet-popup,.leaflet-popup-content-wrapper{
  text-align: inherit;
}

.bar-wrapper {
  background: #eee;
  min-width: 120px;
  height: 12px;
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #ddd;
  position: relative;
  vertical-align: middle;
  &.block{
    display: block;
  }
  .bar-fill {
    display: inline-block;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    border-radius:0 4px 4px 0;
  }
}
.comment-item {
  display: flex;
  align-items: flex-start;
  &:not(:last-child){
    margin-bottom: 1rem;
    border-bottom: 1px dotted#ddd;
    padding-bottom: 1rem;
  }
}
.avatar-holder {
  background: #f5f5f5;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #bbbbbb;
}
.flex-auto {
  flex: auto;
}
.small-image-icon {
  width: 25px;
}
.domestic-hotel-rules-list li{
  margin-bottom: 10px;
}
.ant-radio-inner::after{
  background-color: #02647a;
}
.ant-radio-checked .ant-radio-inner,.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner{
  border-color: #02647a;
}
.domestic-hotel-child-control {
  display: flex;
  align-items: center;
  button{
    min-width: 100px;
  }
}
.getPassenger-form-room-title {
  background: #f1f1f1;
  padding: 5px 10px;
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 16px;
}
// .select-input-pair-holder.ant-input-group-wrapper {
//   border: 0 none;
//   padding: 0;
//   .ant-input-group-addon {
//     padding: 0;
//     color: inherit;
//     border: 0 none;
//   }
// }
.country-code-pair .ant-input-group{
  direction: ltr;
  .ant-input-group-addon{
    padding: 0;
    border: 0;
    color: inherit;
  }
  .ant-input,.form-select{
    border-color: #bbb;
    height: 40px;
  }
}
.timer-wrapper {
  direction: ltr;
  display: inline-block;
  .symbol {
    color: #f44336;
    font-size: 25px;
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px;
  }
  .minutes , .seconds{
    letter-spacing: 1px;
    background-color: #f44336;
    display: inline-block;
    color: #fff;
    font-family: opensans;
    font-weight: 600;
    font-size: 30px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    vertical-align: middle;
    border-radius: 7px;
  }
}
@keyframes communicationAnimation {
  0% {
    opacity: 1;
  }
  25% {
    opacity: .4;
  }
  50% {
    opacity: .2;
  }
  75% {
    opacity: .4;
  }
  100% {
    opacity: 1;
  }
}
.communication-wrapper .itours-icon {
  @media (max-width: $small_) {
    width: 50px;
    height: 50px;
  }
}
.communication {
  display: inline-block;
  .dot{
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #4e555d;
    margin: 0 3px;
    border-radius: 50%;
    animation: communicationAnimation 1s linear infinite;
    &:nth-child(1){
      animation-delay: 0;
    }
    &:nth-child(2){
      animation-delay: .1s;
    }
    &:nth-child(3){
      animation-delay: .2s;
    }
    &:nth-child(4){
      animation-delay: .3s;
    }
    &:nth-child(5){
      animation-delay: .4s;
    }
    &:nth-child(6){
      animation-delay: .5s;
    }
    &:nth-child(7){
      animation-delay: .6s;
    }
    &:nth-child(8){
      animation-delay: .7s;
    }

  }
}
.capicity-track-code {
  background: #f1f1f1;
  padding: 0 10px;
  width: 120px;
  display: inline-block;
  letter-spacing: 2px;
  font-size: 20px;
  line-height: 40px;
  border: 1px solid #bbb;
}
.mobile-hr-padding{
  @media screen and (max-width:$small_){
    padding-left: 10px;
    padding-right: 10px;
  }
}
.domestic-hotel-detail-safarmarket {
  .card {
    border: 0 none;
    border-radius: 10px;
  }
  .search-box-holder{
    background-color: #fff;
    padding: 10px;
    padding-bottom: 0;
    .form-group .form-input{
      height: 45px;
    }
    .hotel-search-btn{
      height: 45px;
    }
  }
}
.routeAllSegments-header {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin: 10px 0 5px;
  color: #aaa;
  background: #f5f5f5;
  padding: 8px 10px;
  .dashed-line{
    flex: auto;
    display: block;
    border-top:1px dashed;
    margin: 0 1rem;
    position: relative;
  }
  .airplane-icon {
    width: 16px;
    opacity: .5;
  }
}
.layout-holder.is-turkish{
  .fake-return-date {
    font-size: 13px ;
  }
}
.ant-skeleton.one-line-skeleton {
  .ant-skeleton-title {
    margin-top: 0;
    margin-bottom: 0;
  }
  .ant-skeleton-paragraph{
    margin: 0;
  }  
}
.sm-wlcm-img{
  @media screen and (min-width:$small){
    margin-bottom: 10px;
  }
}
.domestic-hotel-vertical {
  .cover {
    width: 100%;
    object-fit: cover;
    &.default-cover{
      object-fit: contain;
      padding: 30px;
    }
    display: block;
    height: 200px;
    @media screen and (min-width:$small){
      height: 280px;
    }
    
    @media screen and (min-width:$medium){
      height: 210px;
    }
    
    @media screen and (min-width:$large){
      height: 220px;
    }
  }
  .address {
    min-height: 60px;
    margin-bottom: 0;
  }
  footer{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 108px;
  }
}
.deleted-text{
  text-decoration: line-through;
    color: #bbb;
}

.airline-filter-item .ant-checkbox{
  &.ant-checkbox-checked::after{
    display: none;
  }
  .ant-checkbox-inner{
    background-color: transparent;
    border: 1px solid #bbb;
    outline: none;
  }
  &.ant-checkbox-checked .ant-checkbox-inner{
    background-color: #02647a;
  }
  + span {
    line-height: 1;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.antd-custom-checkbox {
  line-height: 20px;
  padding: 5px 10px;
  color: #464545;
  font-size: 12px;
  .ant-checkbox-inner{
    border: 1px solid #bbb;
  }
  .ant-checkbox-checked {
    &::after{
      border: 0 none;
    }
    .ant-checkbox-inner{
      background-color: #02647a;
    }
  }
  &.hotel-rating-checkbox{
    display: flex;
    align-items: center;
    // &>span{
    //   display: block;
    // }
  }
}
.time-filters {
  padding: 10px;
  .ant-checkbox-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .ant-checkbox-wrapper {
    margin: 0;
    flex: 0 1 50px;
    text-align: center;
    position: relative;
    border: 1px solid transparent;
    &:hover{
      background-color: #fafafa;
    }
    &.ant-checkbox-wrapper-checked{
      border-color: #02647a;
    }
  }
  .ant-checkbox {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    +span{
      line-height: 12px;
    padding: 10px;
    color: #02647a;
    font-size: 12px;
    display: block;
    }
  }
}
.hotel-old-price .price span{
  text-decoration: line-through;
  color: #bbb;
}
.reverse-order-in-mobile {
  @media screen and (max-width:$large_){
    display: flex;
    flex-direction: column-reverse;
  }
}

.flight-avails-row .filters-holder .ant-slider-mark {
  direction: ltr;
}


.flight-alternative-carousel {
  padding: 20px 10px;



  .slick-dots {
    position: static;
  }

}

.flight-alternative-item {
  .list-style-check li{
    @media screen and (max-width:$small_){
      font-size: 13px;
      line-height: 20px;
      padding: 3px 0;
    }
  
  }

  padding: 5px 15px;
  @media screen and (max-width:$small_){
    padding: 5px;
  }

  .list-style-check {
    max-height: calc(100vh - 400px);
    @media screen and (max-width:$small_){
      max-height: calc(100vh - 350px);
    }
    overflow: auto;
    li{
      line-height: 20px;
      margin-bottom: 3px;
      display: flex;
      &::before{
        flex-shrink: 0;
        margin-top: 2px;
      }
    }
  }
  .item-title {
    font-size: 16px;
    line-height: 20px;
    @media screen and (max-width:$small_){
      font-size: 13px;
      padding: 3px 0;
    }
    background: #e1e1e1;
    text-align: center;
    font-weight: 600;
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 15px 15px 0 0;
    .slick-slide:nth-child(4n+1) &{
      background: #add694;
    }
    .slick-slide:nth-child(4n+2) &{
      background: #a5cced;
    }
    .slick-slide:nth-child(4n+3) &{
      background: #f1dbe8;
    }
    .slick-slide:nth-child(4n) &{
      background: #c6b6da;
    }
  }

  .item-main {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width:$small_){
      padding: 5px;
    }
  }

  .item-inner {
    background: #f7f9f8;
    box-shadow: 0 0 6px 0 #0000001c;
    border-radius: 15px;
    transition: all .15s ease;
    &.is-selected{
      box-shadow:1px 1px 7px 1px #0000007d;  
    }
  }
}
.list-style-check{
  padding: 0;
  li{
    list-style-type:none;
    line-height: 25px;
    padding: 5px 0;
    font-size: 14px;
    &::before{
      content: "";
      display: inline-block;
      width: 15px;
      height: 15px;

      background-image: url("./../image/icons.png");
      //as IE dosnt support svg background:
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        background-image: url("./../image/icons.png");
      }

      background-size: 3200% 600%;
      background-position: 64.5% 100%;
      margin-right: 5px;
      vertical-align: middle;
    }
  }
}
.button.select-alternative-btn,
.button.flight-alternative-continue-btn,
.button.flight-alternative-cancel-btn {
  padding: 2px 10px;
  width: 130px;
  max-width: 100%;
}
.slick-dots li button {
  min-width: 0;
  background: transparent;
}
.flight-alternative-prices-modal .modal-inner {
  @media screen and (max-width:$small_){
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.header-modules-menu .link-item {
  color: $yellow;
  margin-bottom: 10px;
  display: inline-block;
  .itours-icon{
    vertical-align: baseline;
  }
}

.home-tab .svgIcon {
  fill: #fff;
  display: block;
  margin: 0 auto 5px;
  width: 25px;
  height: 25px;
  @media screen and (min-width:$medium_){
    display: none;
  }
  &.active{
    fill: #004ba8;
  }
}
.hotel-details .not-fixed-search-holder {
  background: #35353f;
  padding: 15px;
  border-radius: 4px;
  @media screen and (min-width:$medium_){
    padding-top: 25px;
  }
}

.domestic-hotel-detail-safarmarket .search-box-holder.clearfix {
  box-shadow: none;
  padding-bottom: 15px;
  padding-top: 15px;
  @media screen and (min-width:$medium_){
    padding-bottom: 0;
    padding-top: 10px;
  }
}
.show-on-map-btn {
  background-image: url('../image/map.jpg');
  background-size: cover;
  box-shadow: 0 0 2px 0 inset;
  white-space: nowrap;
  padding-left: 2px;
  padding-right: 2px;
}

.body.ltr .next-btn::after,
.body.ltr .prev-btn::before {
  margin-top: -3px;
}

.sort-next-prev{
  @media screen and (min-width:$small) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }  
}
.next-previous-group {
  display: flex;
  align-items: center;
  gap: 3px;
  justify-content: space-between;
  margin-bottom: 10px;
  
  .button{
    line-height: 13px;
    padding: 8px;
  }

  @media screen and (min-width:$small) {
    justify-content: flex-end;
  }
}
.sort-container{
  margin-bottom: 10px;
}
.otp-input-number{
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }
}

.languages-popup {
  background: #fff;
  padding: 10px;
  z-index: 3;
  position: absolute;
  border-radius: 5px;
  box-shadow: 0 1px 7px 0 #00000073;

  opacity: 0;
  visibility: hidden;
  transition: 0;
  margin-top: 25px;
  @media screen and (min-width:$medium) {
    top: 100%;
    left: -35px;
    right: -35px;
  }
  &.open{
    transition: all .2s ease;
    opacity: 1;
    visibility: visible;
    margin-top: 12px;
  }

  &::before{
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    background-color: #fff;
    top: -6px;
    position: absolute;
    clip-path: polygon(0 100%, 100% 0, 0 0);
    transform: rotate(45deg);
    border: 1px solid #ccc;

    @media screen and (max-width:$medium_){
      body:not(.ltr) & {
        right: 10px;
      }
      body.ltr & {
        left: 10px;
      }
    }
    @media screen and (min-width:$medium) {
      left: 50%;
      margin-left: -8px;
    }
  }
  .flag{
    border-radius: 3px;
    border: 1px solid #bbb;
  }
}
.languages-wrapper {
  .flag{
    border-radius: 3px;
  }
  .language-item{
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 600;
    border-radius: 3px;
    &:not(.active){
      cursor: pointer;
      &:hover{
        background-color: #f1f1f1;
      }
    }
    &:not(:last-child){
      margin-bottom: 5px;
    }
  }  
  @media screen and (max-width:$medium_) {
    display: inline-block;
  }
}

.desktop-language-btn,
.desktop-currency-btn{
  @media screen and (max-width:$medium_) {
    display: none;
  }
}

@media screen and (min-width:$medium) {
  .mobile-tablet-only {
    display: none !important;
  }
}
.bg-light-gray{
  background-color: #f9f9f9;
}

.multi-datepicker .rmdp-calendar .rmdp-day-picker {
  min-height: 285px;
}

.custom-select-options {
  position: absolute;
  background: #fff;
  z-index: 1;
  top: 100%;
  min-width: 100%;
  box-shadow: 1px 1px 5px 0 #00000080;
  border-radius: 3px;
  z-index: 100;
  .custom-select-option{
    user-select: none;
    padding: 3px 10px;
    white-space: nowrap;
    cursor: pointer;
    background-color: #fff;
    &:hover{
      background-color: #f1f1f1;
    }
  }
}
.custom-select-input {
  user-select: none;
  cursor: pointer;
  .caret{
    width: 25px;
    height: 25px;
    fill: currentColor;
    vertical-align: middle;
    display: inline-block;
    pointer-events: none;
  }
}

.styled-cabin-select{
  display: block;
  width: 100%;
  @media screen and (min-width : $small){
    display: inline-block;
    vertical-align: middle;
    width: auto;
  }
}

.margin-x--10{
  margin-left: -10px;
  margin-right: -10px;
}
.margin-x--5{
  margin-left: -5px;
  margin-right: -5px;
}

.overflow-hidden{
  overflow: hidden;
}

.currency-wrapper {
  .currency-item{
    display: flex;
    padding: 0 5px;
    align-items: center;
    font-size: 13px;
    font-weight: 600;
    border-radius: 3px;
    &:not(.active){
      cursor: pointer;
      &:hover{
        background-color: #f1f1f1;
      }
    }
    &:not(:last-child){
      margin-bottom: 5px;
    }
  }  
  @media screen and (max-width:$medium_) {
    display: inline-block;
  }
}
.currency-popup {
  right: 0;
  left: 0;
  background: #fff;
  padding: 10px;
  z-index: 3;
  position: absolute;
  border-radius: 5px;
  box-shadow: 0 1px 7px 0 #00000073;
  opacity: 0;
  visibility: hidden;
  transition: 0;
  margin-top: 25px;
  &.open{
    transition: all .2s ease;
    opacity: 1;
    visibility: visible;
    margin-top: 12px;
  }
  
  &::before{
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    background-color: #fff;
    top: -6px;
    position: absolute;
    clip-path: polygon(0 100%, 100% 0, 0 0);
    transform: rotate(45deg);
    border: 1px solid #ccc;

    @media screen and (max-width:$medium_){
      body:not(.ltr) & {
        right: 10px;
      }
      body.ltr & {
        left: 10px;
      }
    }
    @media screen and (min-width:$medium) {
      left: 50%;
      margin-left: -8px;
    }
  }
}

.route-skeleton {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  .airline{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #eee;
    flex-shrink: 0 ;
  }

  .ant-skeleton.origin {
    .ant-skeleton-title{
      margin-top: 0;
      margin-bottom: 5px;
    }
    .ant-skeleton-paragraph {
      margin: 0;
      li{
        margin-top: 5px;
      }
    }
  }

}
.flight-skeleton-footer{
  display: flex;
  justify-content: space-between;
}

.ant-skeleton.button-skeleton {
  width: 120px;
  flex-shrink: 0;
  .ant-skeleton-title {
    height: 35px;
    width: 100% !important;
  }
}

.ant-skeleton.full-width-button-skeleton{
  width: 100%;
  flex-shrink: 0;
  .ant-skeleton-title {
    height: 35px;
    width: 100% !important;
  }
}

.ant-skeleton.text-center .ant-skeleton-title {
  margin: auto;
}

.progressbar-wrapper{
    height: 12px;
    background: #ddd;
    .bar-fill{
      height: 12px;
      background-color: #03a9f4;
      transition: all 1s linear;
      max-width: 100%;
    }
}
.steps-skleton {
  display: flex;
  gap: 10px;
}

.ant-skeleton.text-end .ant-skeleton-content {
  body:not(.ltr) & {
    direction: ltr;
  }
  body.ltr & {
    direction: rtl;
  }
}
.ant-skeleton.text-right .ant-skeleton-content {
  direction: rtl;
}
.ant-skeleton.text-left .ant-skeleton-content {
  direction: ltr;
}

.justify-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .grow{
    flex-grow: 1;
  }
}

.sidebar-map-skeleton-wrapper {
  display: flex;
  justify-content: center;
  background: #ddd;
  padding: 26px 10px;
}

.hotel-item-skeleton {
  background-color: #fff;
  display: grid;
  padding: 5px;
  grid-template-columns: auto auto auto auto;
  .image {
    background: #efefef;
  }
  .large {
    grid-column: 2 / span 2;
  }
}

.hotel-gallery-skeleton-wrapper {
  background-color: #fff;
  display: grid;
  padding: 2px;
  gap: 2px;
  grid-template-columns: auto auto auto auto auto auto auto auto;
  .image-item{
    background-color: #efefef;
    background-image: url('../image/image.svg');
    background-repeat: no-repeat;
    background-position: center;
    min-height: 80px;
    &:nth-child(1){
      grid-column: 1 / span 4;
      grid-row: 1 / span 4; 
      @media screen and (max-width:$small_){
        grid-column: 1 / span 8;
        grid-row: 1 / span 8; 
        height: 140px;
      }
    }
  }
  .facilities{
    grid-column: 7 / span 2;
    grid-row: 1 / span 4; 
  }
}

.room-item-skeleton{
  display: flex;
  gap: 20px;
  .image{
    width: 150px;
    height: 120px;
    background-color: #efefef;
    background-image: url('../image/image.svg');
    background-repeat: no-repeat;
    background-position: center;
  }
  .grow{
    flex-grow: 1;
  }
  table{
    border: 1px solid #bbb;
    border-collapse: collapse;
    td{
      border: 1px solid #ddd;
      padding: 5px 10px;
    }
  }
}
.hotel-detail-skeleton-wrapper{
  @media screen and (max-width:$small_) {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.hotel-summery-image-skeleton{
  display: flex;
  gap: 10px;
  .image{
    width: 100px;
    background-color: #efefef;
    background-image: url('../image/image.svg');
    background-repeat: no-repeat;
    background-position: center;    
  }
  .ant-skeleton-title,.ant-skeleton-paragraph{
    margin: 0 0 5px !important;
  }
}

@media screen and (min-width:$medium) {
  .select-room-td {
    width: 230px;
  }
}

.new-flight-item {
  line-height: 1.1;

  .airlines {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    z-index: 0;
    img {
      width: 50px;
      height: 50px;
      @media screen and (max-width:$medium_){
        width: 40px;
        height: 40px;
      }
      z-index: 1;
      background: #fff;
      box-shadow: 0px 0px 5px #0003;
      border-radius: 50%;
      object-fit: contain;
      padding: 2px;
  
      ~img {
        body:not(.ltr) & {
          margin-left: -25px;
        }
        body.ltr & {
          margin-right: -25px;
        }
        
      }
    }    
  }

  .airline-name{
   font-size: 13px;
   line-height: inherit;
   &.operating-airline-name{
    color: #bbb;
   }
  }
  .flight-number{
    font-size: 13px;
    font-weight: 600;
  }
  .airline-block {
    display: flex;
    align-items: center;
    gap: 10px;
  }  
  .origin-destination-item {
    display: flex;
    @media screen and (max-width:$medium_){
      flex-wrap: wrap;
    }
    padding: 15px 0;
    @media screen and (max-width:$small_){
      padding: 15px 5px;
    }
    align-items: center;
    .airline-block{
      flex: 0 0 30%;
      @media screen and (min-width:$medium) and (max-width:$large_) {
        flex: 0 0 25%;
        width: 25%;
        .first-segment-details.text-start {
          max-width: 90px;
          word-break: break-all;
        }
      }
      @media screen and (max-width:$medium_) {
        flex: 0 0 50%;
        margin-bottom: 20px;
        order: 1;
      }
      
    }
    .route-block{
      padding: 0 20px;
      flex: 0 0 50%;
      @media screen and (min-width:$medium) and (max-width:$large_) {
        flex: 0 0 60%;
        width: 60%;
      }
      @media screen and (max-width:$medium_) {
        flex: 0 0 100%;
        padding: 0;
        order: 3;
      }
      display: flex;
      gap: 15px;
      align-items: center;
      justify-content: space-between;
    }
    .baggage-block{
      flex: 0 0 20%;
      text-align: center;
      @media screen and (min-width:$medium) and (max-width:$large_) {
        flex: 0 0 15%;
        width: 15%;
      }
      @media screen and (max-width:$medium_) {
        flex: 0 0 50%;
        margin-bottom: 20px;
        order: 2;
        body:not(.ltr) & {
          text-align: left;
        }
        body.ltr & {
          text-align: right;
        }
      }
    }
  }
  .first-segment-details {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: start;
    .svg-icon {
      display: inline-block;
      vertical-align: middle;
      fill: currentColor;
    }
  }
  .location-col {
    display: flex;
    flex-direction: column;
    line-height: 17px;
  }  
}
.new-flight-item-footer {
  padding: 10px;
  border-top: 1px solid #ccc;
}

.journey-col {
  flex-grow: 1;

  .dashed-line-wrapper {
    display: flex;
    gap: 3px;
    align-items: center;
  }
  .stop-dots-col {
    flex-grow: 1;
    border-bottom: 1px dashed #ccc;
  }
  .duration {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
  }
  .stop-top {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .stop-dots {
    display: flex;
    justify-content: space-evenly;
  }
  .stop-dot-item {
    display: block;
    padding: 5px 0;
    position: relative;
    &::before{
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      background-color: red;
      position: absolute;
      border-radius: 50%;
      left: 50%;
      margin-left: -3px;
      bottom: -3px;
    }
  }
}

.under-constriction-wrapper {
  background: #fff;
  display: block;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-input.filter-past-passengers {
  height: 40px;
  border: 1px solid #bbb;
  margin-bottom: 10px;
  width: 100%;
  max-width: 400px;
}

.body .min-w-55{
  min-width: 55px;
}
.bg-green-skeleton-wrapper{
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  padding: 10px 15px;
  background-color: #e1e1e1;
  color: #fff;
  text-shadow: 1px 0 4px black;
}
.bg-green-skeleton {
  background: #2b2e7f;
  position: absolute;
  transition: all 1 s linear;
  top: 0;
  bottom: 0;
  overflow: hidden;
  .body.ltr & {
    left: 0;
  }
  .body:not(.ltr) & {
    right: 0;
  }
  &::before{
    content: "";
    display: block;
    background: linear-gradient(90deg, transparent, #595dc973, transparent);
    position: absolute;
    top: 0;
    bottom: 0;
    width: 75%;   
    .body.ltr & {
      -webkit-animation: loadingL2R 1s linear infinite;
      animation: loadingL2R 1s linear infinite;
    }
    .body:not(.ltr) & {
      -webkit-animation: loadingR2L 1s linear infinite;
      animation: loadingR2L 1s linear infinite;
    }
  }
}


@-webkit-keyframes loadingR2L {
  from {
    right:-75%;
  }

  to {
    right:175%;
  }
}
@keyframes loadingR2L {
  from {
    right:-75%;
  }

  to {
    right:175%;
  }
}
@-webkit-keyframes loadingL2R {
  from {
    left:-75%;
  }

  to {
    left:175%;
  }
}
@keyframes loadingL2R {
  from {
    left:-75%;
  }

  to {
    left:175%;
  }
}
